import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { GlobalConstants } from '../global-constant';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

export class OrdersExportExcelService {
  constructor(
    private datePipe: DatePipe,
    private globals: GlobalConstants

  ) { }

  today = new Date();




  //PICK LIST
  exportPickList(ExcelData, reportTitle) {
    //console.log(ExcelData)
    //create new excel work book
    let workbook = new Workbook();

    //add name to sheet
    let worksheet = workbook.addWorksheet("Pick List");

    //Add 1st Row and formatting
    worksheet.mergeCells('A1', 'E1');
    let A1Row = worksheet.getCell('A1');
    A1Row.value = reportTitle;
    A1Row.font = {
      name: 'Arial',
      size: 18,
      // underline: 'single',
      bold: true,
    }
    // A1Row.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //  // fgColor: { argb: '00008B' }
    // }
    A1Row.alignment = { vertical: 'middle', horizontal: 'center' }

    A1Row.border = {
      //top: { style: "thin" },
      //left: { style: "thin" },
      bottom: { style: "thin" },
      // right: { style: "thin" }
    };


    //add column name
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 50;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 20;

    let header = ["#", "SKU", "Product", "Qty", "Order Number(s)"];
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    headerRow.alignment = { vertical: 'middle', horizontal: 'justify' };
    // headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(5).alignment = { vertical: 'middle', horizontal: 'right' };


    let sno = 1;
    let current_row = 3;

    ExcelData.forEach(element => {
      let itemsrow = worksheet.addRow([
        sno++,
        element.sku,
        element.productName,
        element.qty,
        element.orderNumberList[0]
      ]

      );

      itemsrow.font = {
        name: 'Arial',
        size: 9,
        // underline: 'single',
        // bold: true,
        //  color: { argb: '00008B' }
      }
      itemsrow.alignment = { vertical: 'middle', horizontal: 'justify' };

      // itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

      itemsrow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(4).alignment = { vertical: 'middle', horizontal: 'center' };
      itemsrow.getCell(5).alignment = { vertical: 'middle', horizontal: 'right', wrapText : true };
      // itemsrow.getCell(6).font = {
      //   size: 12,
      //   bold: true,
      // }
      current_row++;
      if (element.orderNumberList?.length > 0) {
        let index = 0;
        element.orderNumberList.forEach(orderNo => {
          index++;
          if (index > 1) {
            let orderNumberCol = worksheet.getCell('E' + current_row)
            orderNumberCol.value = orderNo;
            orderNumberCol.alignment = { vertical: 'middle', horizontal: 'right' };
            orderNumberCol.font = {
              name: 'Arial',
              size: 9,
              // underline: 'single',
              // bold: true,
              //  color: { argb: '00008B' }
            }
            current_row++;
          }
        });
      }

    });


    //set downloadable file name
    setInterval(() => {         //replaced function() by ()=>
      this.today = new Date();
    }, 1000);

    let fname = ('pick-list')?.toUpperCase() + '-' + this.datePipe.transform(this.today, "dd-MM-yyyy-HH-mm-ss");
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '.xlsx');
    });



  }

   //PACK LIST
   exportPackList(ExcelData, reportTitle) {
    //console.log(ExcelData)
    //create new excel work book
    let workbook = new Workbook();

    //add name to sheet
    let worksheet = workbook.addWorksheet("Pack List");

    //Add 1st Row and formatting
    worksheet.mergeCells('A1', 'E1');
    let A1Row = worksheet.getCell('A1');
    A1Row.value = reportTitle;
    A1Row.font = {
      name: 'Arial',
      size: 18,
      // underline: 'single',
      bold: true,
    }
    // A1Row.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //  // fgColor: { argb: '00008B' }
    // }
    A1Row.alignment = { vertical: 'middle', horizontal: 'center' }

    A1Row.border = {
      //top: { style: "thin" },
      //left: { style: "thin" },
      bottom: { style: "thin" },
      // right: { style: "thin" }
    };


    //add column name
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 50;
    worksheet.getColumn(5).width = 15;

    let header = ["#",  "Order Number", "SKU", "Product", "Qty"];
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    headerRow.alignment = { vertical: 'middle', horizontal: 'justify' };
    // headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(5).alignment = { vertical: 'middle', horizontal: 'center' };


    let sno = 1;
    let current_row = 3;

    ExcelData.forEach(element => {
      let itemsrow = worksheet.addRow([
        sno++,
        element.orderNumber,
        element.sku,
        element.productName,
        element.qty,
      ]

      );

      itemsrow.font = {
        name: 'Arial',
        size: 9,
        // underline: 'single',
        // bold: true,
        //  color: { argb: '00008B' }
      }
      itemsrow.alignment = { vertical: 'middle', horizontal: 'justify' };

      // itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

      itemsrow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(5).alignment = { vertical: 'middle', horizontal: 'center', wrapText : true };
      // itemsrow.getCell(6).font = {
      //   size: 12,
      //   bold: true,
      // }
      current_row++;
    });


    //set downloadable file name
    setInterval(() => {         //replaced function() by ()=>
      this.today = new Date();
    }, 1000);

    let fname = ('pack-list')?.toUpperCase() + '-' + this.datePipe.transform(this.today, "dd-MM-yyyy-HH-mm-ss");
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '.xlsx');
    });



  }

   //B2B PACK LIST
   exportb2bPackList(ExcelDataList, reportTitle) {
    //console.log(ExcelData)
    //create new excel work book
    let workbook = new Workbook();
    for (let i = 0; i < ExcelDataList.length; i++) {
      const ExcelData = ExcelDataList[i];
      let sheetNo = i + 1;  
    

    //add name to sheet
    let worksheet = workbook.addWorksheet("PACKING LIST - "+sheetNo);

    //Add 1st Row and formatting
    worksheet.mergeCells('A1', 'D1');
    let A1Row = worksheet.getCell('A1');
    A1Row.value = reportTitle;
    A1Row.font = {
      name: 'Arial',
      size: 18,
      // underline: 'single',
      bold: true,
    }
    // A1Row.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //  // fgColor: { argb: '00008B' }
    // }
    A1Row.alignment = { vertical: 'middle', horizontal: 'left' }

    A1Row.border = {
      //top: { style: "thin" },
      //left: { style: "thin" },
      bottom: { style: "thin" },
      // right: { style: "thin" }
    };

    //FIRST HEADER
    worksheet.mergeCells('A2', 'C2');
    let A2Row = worksheet.getCell('A2');
    A2Row.value = "Customer";
    A2Row.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    A2Row.fill = {
      type: 'pattern',
      pattern: 'solid',
     fgColor: { argb: 'DCE6F1' }
    }

    

    let D2Row = worksheet.getCell('D2');
    D2Row.value = "Date";
    D2Row.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    D2Row.fill = {
      type: 'pattern',
      pattern: 'solid',
     fgColor: { argb: 'DCE6F1' }
    }

    worksheet.mergeCells('A3', 'C3');
    let A3Row = worksheet.getCell('A3');
    A3Row.value = ExcelData?.customerName;
    A3Row.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: false,
      //color: { argb: '00008B' }
    }
    A3Row.fill = {
      type: 'pattern',
      pattern: 'solid',
     fgColor: { argb: 'DCE6F1' }
    }

    let D3Row = worksheet.getCell('D3');
    D3Row.value = this.globals.shortUIDate(this.today);
    D3Row.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: false,
      //color: { argb: '00008B' }
    }
    D3Row.fill = {
      type: 'pattern',
      pattern: 'solid',
     fgColor: { argb: 'DCE6F1' }
    }

    //DELIVERY
    let collectionMethod = ExcelData?.collectionMethod == 'Self Collection'? ExcelData?.collectionMethod : 'Delivery';
    let collectionTime = (ExcelData?.collectionMethod == 'Self Collection' && ExcelData?.collectionTime) ? ExcelData?.collectionTime : "";
    let deliveryCutoffTime = (ExcelData?.collectionMethod != 'Self Collection' && ExcelData?.deliveryCutoffTime) ? ExcelData?.deliveryCutoffTime?.substring(0,5) : "";
    let period = (ExcelData?.collectionMethod != 'Self Collection' && ExcelData?.period) ? ExcelData?.period : "";
    let orderTime = ExcelData?.collectionMethod == 'Self Collection' ? collectionTime : deliveryCutoffTime;
    worksheet.mergeCells('A4', 'D4');
    let A4Row = worksheet.getCell('A4');
    A4Row.value = collectionMethod + ' ' + orderTime + ' '+ period;
    A4Row.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: false,
      //color: { argb: '00008B' }
    }
    A4Row.fill = {
      type: 'pattern',
      pattern: 'solid',
     fgColor: { argb: 'DCE6F1' }
    }
    let emptyRow = worksheet.addRow([]);


    //add column name
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 45;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 25;

    let header = ["#", "Product",  "Quantity", "Description"];
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    headerRow.alignment = { vertical: 'middle', horizontal: 'justify' };
    // headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };


    let sno = 1;
    let current_row = 3;

    ExcelData?.pickPackProductsList.forEach(element => {
      let itemsrow = worksheet.addRow([
        sno++,
        //element.orderNumber,
        //element.sku,
        element.productName,
        element.qty,
        element.specialDescription,
      ]

      );

      itemsrow.font = {
        name: 'Arial',
        size: 9,
        // underline: 'single',
        // bold: true,
        //  color: { argb: '00008B' }
      }
      itemsrow.alignment = { vertical: 'middle', horizontal: 'justify' };

      // itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

      itemsrow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(3).alignment = { vertical: 'middle', horizontal: 'center', wrapText:true };
      itemsrow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left', wrapText : true };
      // itemsrow.getCell(6).font = {
      //   size: 12,
      //   bold: true,
      // }
      current_row++;
    });


    //set downloadable file name
    setInterval(() => {         //replaced function() by ()=>
      this.today = new Date();
    }, 1000);
    }
    let fname = ('B2B-pack-list')?.toUpperCase() + '-' + this.datePipe.transform(this.today, "dd-MM-yyyy-HH-mm-ss");
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '.xlsx');
    });

  

  }

  //GOODS MOVEMENTS LIST
  exportGoodsMovement(ExcelData, reportTitle) {
    //console.log(ExcelData)
    //create new excel work book
    let workbook = new Workbook();

    //add name to sheet
    let worksheet = workbook.addWorksheet("Goods Movement");

    //Add 1st Row and formatting
    worksheet.mergeCells('A1', 'I1');
    let A1Row = worksheet.getCell('A1');
    A1Row.value = reportTitle;
    A1Row.font = {
      name: 'Arial',
      size: 18,
      // underline: 'single',
      bold: true,
    }
    // A1Row.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //  // fgColor: { argb: '00008B' }
    // }
    A1Row.alignment = { vertical: 'middle', horizontal: 'center' }

    A1Row.border = {
      //top: { style: "thin" },
      //left: { style: "thin" },
      bottom: { style: "thin" },
      // right: { style: "thin" }
    };


    //add column name
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 11;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 12;

    let header = ["#", "Posting Date", "Entry Type", "Document Type",  "Document No / Order Number", "Warehouse / Location", "Batch Code", "Serial No", "Qty"];
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: 'Arial',
      size: 9,
      // underline: 'single',
      bold: true,
      //color: { argb: '00008B' }
    }
    headerRow.alignment = { vertical: 'middle', horizontal: 'justify' };
    // headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(5).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(6).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(7).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(8).alignment = { vertical: 'middle', horizontal: 'left' };
    headerRow.getCell(9).alignment = { vertical: 'middle', horizontal: 'center' };


    let sno = 1;
    let current_row = 3;

    ExcelData.forEach(element => {
      let itemsrow = worksheet.addRow([
        sno++,
        this.globals.shortUIDate(element.date),
        element.entityType,
        element.documentType,
        element.documentNumber,
        element.storagePoint ? (element.storagePoint + (element.storageLocation? (' / '+ element.storageLocation) :'')) : '',
        element.batchcode,
        element.barcode,
        element.qty,
      ]

      );

      itemsrow.font = {
        name: 'Arial',
        size: 9,
        // underline: 'single',
        // bold: true,
        //  color: { argb: '00008B' }
      }
      itemsrow.alignment = { vertical: 'middle', horizontal: 'justify' };

      // itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      itemsrow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };

      itemsrow.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(3).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(5).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(6).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(7).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(8).alignment = { vertical: 'middle', horizontal: 'left', wrapText:true };
      itemsrow.getCell(9).alignment = { vertical: 'middle', horizontal: 'center', wrapText : true };
      itemsrow.getCell(9).font = {
        //size: 12,
        bold: true,
      }
      current_row++;
    });


    //set downloadable file name
    setInterval(() => {         //replaced function() by ()=>
      this.today = new Date();
    }, 1000);

    let fname = ('goods-movement')?.toUpperCase() + '-' + this.datePipe.transform(this.today, "dd-MM-yyyy-HH-mm-ss");
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '.xlsx');
    });



  }
}