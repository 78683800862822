import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class Qoo10OrderService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getOrders(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetQoo10Orders`, reqObj);
  }
  getShopifyOrders(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopify/GetShopifyOrders`, reqObj);
  }
  getOrdersSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/SyncQoo10Order`, reqObj);
  }
  getPrintShopeeDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/PrintQoo10AirwayBills`, reqObj);
  }
  markAsPrinted(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/MarkAsQoo10Printed`, reqObj);
  }
  getOrderItems(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetQoo10OrderItems`, reqObj);
  }
  getMassOrderItems(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetQoo10MassOrderItems`, reqObj);
  }
  cancelOrder(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/MarkAsCancel`, reqObj);
  }
  readyToShip(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/Qoo10ReadyToShip`, reqObj);
  }
  checkout(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/MarkAsCheckout`, reqObj);
  }
  getOrderDetails(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetInvoiceDetails`, reqObj);
  }
  getShopeeFailureReasons() {
    return this.http.get(`${this.apiBaseURL}/Qoo10/GetShopeeFailureReasons`);
  }
  getLogisticInfo(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ShopeeLogisticInfo`, reqObj);
  }
  getAddresses(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ShopeeGetAddresses`, reqObj);
  }
  getTimeSlots(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ShopeeGetTimeSlots`, reqObj);
  }
  init(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ShopeeInit`, reqObj);
  }

  //SYNC ORDERS UPDATE
  SyncQoo10OrderUpdate(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/SyncQoo10OrderUpdate`, reqObj);
  }

  //CUSTOMER COPY - START - 11-02-2022
  Qoo10CustomerCopy(obj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/Qoo10CustomerCopy`, obj)
  }
}
