import { Component, ElementRef, ViewChild, ViewEncapsulation, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { QuillEditorComponent } from 'ngx-quill'; //QUILL EDITOR LOAD
import { GlobalConstants } from 'src/app/global-constant';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TextEditorComponent implements OnInit {

  public breadcrumb: any;
  backgroundColor: any;

  blured = false;
  focused = false;
  hide = false;
  form: FormGroup;
  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;


  constructor(
    fb: FormBuilder    ) {
    this.form = fb.group({
      editor: ['']
    });
  }


  ngOnInit() {
    this.form
      .controls
      .editor
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((data) => {
      });

    this.editor
      .onContentChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((data) => {
      });

    this.breadcrumb = {
      'mainlabel': 'Text-Editor',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/sales'
        },
        {
          'name': 'Extra Components',
          'isLink': true,
          'link': '#'
        },
        {
          'name': 'Text-Editor',
          'isLink': false
        }
      ]
    };
  }

  focus() {
    this.focused = true;
    this.blured = false;
  }

  blur() {
    this.focused = false;
    this.blured = true;
  }

  setControl() {
    this.form.setControl('editor', new FormControl('test - new Control'));
  }

  patchValue() {
    this.form.get('editor').patchValue(`${this.form.get('editor').value} patched!`);
  }
  

  //QUILL CUSTOM TOOLBAR
  tools = {
    //syntax: true,
    htmlEditButton: {syntax: true },
    toolbar: [
  // ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  // ['blockquote', 'code-block'],
  GlobalConstants.RTE_TOOGLED_BUTTONS,
  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  GlobalConstants.RTE_H1_H2,
  // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  GlobalConstants.RTE_LISTS,
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  GlobalConstants.RTE_SUP_SUB_SCRIPTS,
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  GlobalConstants.RTE_INDENTS,
  // [{ 'direction': 'rtl' }],                         // text direction
  GlobalConstants.RTE_RTL,
  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  GlobalConstants.RTE_TEXT_SIZE,
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  GlobalConstants.RTE_HEADERS,
  // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  GlobalConstants.RTE_COLOR_BACKGROUND,
  // [{ 'font': [] }],
  GlobalConstants.RTE_FONT,
  // [{ 'align': [] }],
  GlobalConstants.RTE_ALIGNMENT,
  // ['clean'],                                        // remove formatting button
  GlobalConstants.RTE_CLEAN,
  // ['link', 'image', 'video']                         // link and image, video
  GlobalConstants.RTE_LINK_IMAGE
],
imageResize: true,
imageDrop: true,
};

}
