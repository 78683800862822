<form [formGroup]="ResetForm" (ngSubmit)="tryReset()" *ngIf="onSuccess === false">
    <div style="padding:10px">
        <h2>Reset Password</h2>
        <!-- <label>Username: </label>
        <div class="form-group position-relative has-icon-left">
            <input type="text" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && r.email.errors }" id="user-name"
                placeholder="Username" required>
            <div class="form-control-position">
                <i class="la la-user font-medium-5 line-height-1 text-muted icon-align"
                    aria-hidden="true"></i>
            </div>
            <div *ngIf="submitted && r.email.errors" class="invalid-feedback">
                <div *ngIf="r.email.errors.required">Username is required</div>
            </div>
        </div> 

        <label>Password: </label>
        <div class="form-group position-relative has-icon-left">
            <input type="password" placeholder="Password" formControlName="oldPassword" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && r.oldPassword.errors }" />
            <div class="form-control-position">
                <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                    aria-hidden="true"></i>
            </div>
            <div *ngIf="submitted && r.oldPassword.errors" class="invalid-feedback">
                <div *ngIf="r.oldPassword.errors.required">Password is required</div>
            </div>
        </div> -->

        <label>New Password: </label>
        <div class="form-group position-relative has-icon-left">
            <input type="password" placeholder="New Password" formControlName="password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && r.password.errors }" />
            <div class="form-control-position">
                <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                    aria-hidden="true"></i>
            </div>
            <div *ngIf="submitted && r.password.errors" class="invalid-feedback">
                <div *ngIf="r.password.errors.required">New Password is required</div>
            </div>
        </div>
        <label>Confirm Password: </label>
        <div class="form-group position-relative has-icon-left">
            <input type="password" placeholder="Confirm Passwor" formControlName="confirmPassword"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && r.confirmPassword.errors }" />
            <div class="form-control-position">
                <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                    aria-hidden="true"></i>
            </div>
            <div *ngIf="submitted && r.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="r.confirmPassword.errors.required">Confirm Passwor is required</div>
            </div>
        </div> 
        <button type="submit" class="btn btn-outline-success btn-lg">
            <i class="fa fa-refresh fa-spin" *ngIf="loading" aria-hidden="true"></i>
            <i class="feather ft-unlock" *ngIf="!loading" aria-hidden="true"></i> Reset</button>


    </div>
    
    
</form>
<div style="padding:10px" *ngIf="onSuccess === true">
    <p>Please click <a href="">Login</a> </p>
</div>