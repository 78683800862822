import { Component, OnInit, Inject, Renderer2, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as $ from 'jquery'
import { SignupService } from './../signup/signup.service';
import * as CryptoTS from 'crypto-ts';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  // @HostListener('window:scroll', ['$event']) 
  //   scrollHandler(event) {
  //     this.scrollFunction();
  //   }
  title = 'appBootstrap';
  key: string = 'b14ca5898a4e4133bbce2ea2315a1916';

  closeResult: string;
  loginForm: FormGroup;
  ResetForm: FormGroup;
  FogotForm: FormGroup;
  loading = false;
  submitted = false;
  isReset = false;
  isForgot = false;
  returnUrl = '/insights';
  isPageLoaded = false;
  modalRef: NgbModalRef;

  contactForm: FormGroup;
  blockLoader: boolean;

  rolesPermission:any;
  

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    public signupService: SignupService,
    private renderer: Renderer2) {
    this.route.queryParams.subscribe(params => {
      //this.returnUrl = params['returnUrl'];
    });

  }

  open(LoginFormIconContent) {
    this.modalRef = this.modalService.open(LoginFormIconContent, { windowClass: 'animated fadeInDown xlModal', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollFunction, true);
    $(document).ready(function () {
      $(window).scroll(function () {
        var sticky = $('#header'),
          scroll = $(window).scrollTop();

        if (scroll >= 20) {
          sticky.addClass('fixed');
          $('.back-to-top').addClass('active');
        } else {
          sticky.removeClass('fixed');
          $('.back-to-top').removeClass('active');
        }
      });
      $("a.nav-bar").click(function () {
        $(".inner-nav").addClass('active-menu');
      });

      $("p.close-menu").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });

      $("ul.nav.nav-pills a").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });
      $(window).resize(function () {
        $("#log").append("<div>Handler for .resize() called.</div>");
      });
      const links: any = document.querySelectorAll(".scrollto");
      for (const link of links) {
        link.addEventListener("click", this.clickHandler);
      }

    });
    var element = document.getElementsByTagName("body");
    element[0].classList.remove('fixed-navbar');
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    this.ResetForm = this.formBuilder.group({
      email: ['', Validators.required],
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    this.FogotForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      this.router.navigate(['/insights']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }


    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      subject: [''],
      message: ['', Validators.required]
    });

  }

  clickHandler(e) {
    e.preventDefault();
    // const href = this.getAttribute("href");
    // const offsetTop = document.querySelector(href).offsetTop - 50;

    // scroll({
    //     top: offsetTop,
    //     behavior: "smooth"
    // });
  }

  get f() {
    return this.loginForm.controls;
  }

  get r() {
    return this.ResetForm.controls;
  }

  get g() {
    return this.FogotForm.controls;
  }



  onReset() {
    this.isReset = true;
    this.isForgot = false;
  }

  onFogot() {
    this.isReset = null;
    this.isForgot = true;
  }

  clearLoginData() {
    this.loading = false;
    this.submitted = false;
    this.isReset = false;
    this.isForgot = false;
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    this.ResetForm = this.formBuilder.group({
      email: ['', Validators.required],
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    this.FogotForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  tryFogot() {
    this.submitted = true;
    if (this.FogotForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.getPasswordMail({
      "userName": this.g.email.value
    }).subscribe(
      (res: any) => {
        if(res.message.toLowerCase() === 'success'){
          Swal.fire({
            title: 'Forgot Password',
            text: 'Email Sent you registered email',
            icon: 'sucess',
          })
        }else {
          Swal.fire({
            title: 'Forgot Password',
            text: res.message,
            icon: 'error',
          })
        }
        
        this.clearLoginData();
      },
      err => {
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let error_description = err.error.exceptionMessage;
        Swal.fire({
          title: 'Forgot Password',
          text: error_description,
          icon: 'error',
        })

      }
    );
  }

  tryReset() {
    this.submitted = true;
    if (this.ResetForm.invalid) {
      return;
    }
    if (this.r.password.value != this.r.confirmPassword.value) {
      Swal.fire({
        title: 'Reset Password',
        text: 'New Password and Confirm Password is not matching',
        icon: 'warning',
      })
      return;
    }
    this.loading = true;
    this.authService.resetPassword({
      "userName": this.r.email.value,
      "secretKey": this.r.oldPassword.value,
      "newSecretKey": this.r.confirmPassword.value
    }).subscribe(
      res => {
        Swal.fire({
          title: 'Reset Password',
          text: 'Password updated successfully',
          icon: 'sucess',
        })
        this.clearLoginData();
      },
      err => {
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let error_description = err.error.exceptionMessage;
        Swal.fire({
          title: 'Reset Password',
          text: error_description,
          icon: 'error',
        })

      }
    );
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    // const value = {
    //   grant_type: 'password',
    //   UserName: this.f.email.value,
    //   Password: this.f.password.value
    // };const usertext = CryptoTS.AES.encrypt(this.f.email.value, this.key);
    var key = CryptoJS.enc.Utf8.parse('b14ca5898a4e4133bbce2ea2315a1916');
    var encryptedUser = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.f.email.value), key,
      {
        keySize: 256 / 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

    var encryptedPwd = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.f.password.value), key,
      {
        keySize: 256 / 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

    console.log("result1", encryptedUser);
    //const value = "UserName=" + encryptedUser + "&Password=" + encryptedPwd + "&grant_type=password";
    const value = "UserName=" + this.f.email.value + "&Password=" + this.f.password.value + "&grant_type=password";
    this.authService.doLogin(value).subscribe(
      res => {
        if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
          localStorage.setItem('remember', 'true');
        } else {
          localStorage.removeItem('remember');
        }
        this.setUserInStorage(res);
      },
      err => {
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let error_description = err.error?.error_description;
        Swal.fire({
          title: 'Login failed',
          text: error_description,
          icon: 'error',
        })

      }
    );
  }

  getPath(menuList){
    for(let i=0;i<menuList.length;i++){
      if(menuList[i].submenu){
        this.getPath(menuList[i].submenu.items)
      }else {
        if (menuList[i].parentKey && !menuList[i].subKey) {
          if(this.rolesPermission[menuList[i].parentKey]) {
            if(this.rolesPermission[menuList[i].parentKey].enabled) {
              this.returnUrl = menuList[i].page;
              return;
              
            }
          }
        } else if (menuList[i].parentKey && menuList[i].subKey) {
          if(this.rolesPermission[menuList[i].parentKey]) {
            for (let f = 0; f < this.rolesPermission[menuList[i].parentKey].features.length; f++) {
              if (this.rolesPermission[menuList[i].parentKey].features[f].feature === menuList[i].subKey) {
                if(this.rolesPermission[menuList[i].parentKey].features[f].enabled){
                  this.returnUrl = menuList[i].page;
                  return;
                  
                }
              }
            }
          }
        }
      }
    }
  }

  setUserInStorage(res) {
    document.cookie = `MPOWER-Token=${res.access_token}; path=/`;
    localStorage.setItem('userID', res.UserID);
    this.authService.getUserProfile(res.UserID).subscribe(
      (res:any) => {
        localStorage.setItem('currentUser', JSON.stringify(res));
        if(res.role){
          if(res.role.permission) {
            this.rolesPermission = JSON.parse(res?.role?.permission);
          }
        }
        
        //localStorage.setItem('role', res.role);
        localStorage.removeItem('currentLayoutStyle');
        
        let menuList = [

          {
            title: 'Insights',
            parentKey: 'insights',
            subKey: 'insights',
            icon: 'assets/img/icon/analytic.svg',
            page: '/insights'
          },
          {
            title: 'Orders',
            parentKey: 'orders',
            icon: 'assets/img/icon/orde.svg',
            page: '/lazada/lazadaOrders'
          },
          
          {
            title: 'Products',
            icon: 'assets/img/icon/product.svg',
            page: '/products/productsList/Lazada'
          },
          
          {
            title: 'Onboard',
            parentKey: 'onboard',
            subKey: 'onboard',
            icon: 'assets/img/icon/Shop.svg',
            page: '/platform/onboard'
          },
          
          {
            title: 'Product Manager',
            icon: 'assets/img/icon/ProductManager.svg',
            parentKey: 'products manager',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Catalogues',
                  page: '/catalog/list',
                  parentKey: 'products manager',
                  subKey: 'catalogues',
                  icon: 'assets/img/icon/catalogue.svg'
                },
                {
                  title: 'Bundles',
                  icon: 'assets/img/icon/ProductBundles.svg',
                  parentKey: 'products manager',
                  subKey: 'bundles',
                  page: '/bundles'
                },
                {
                  title: 'SKUs Merge',
                  icon: 'assets/img/icon/MergeProducts.svg',
                  parentKey: 'products manager',
                  subKey: 'skus merge',
                  page: '/merged-sku'
                },
                {
                  title: 'Price Manager',
                  icon: 'assets/img/icon/PriceManager.svg',
                  parentKey: 'products manager',
                  subKey: 'price manager',
                  page: '/price-management'
                },
                {
                  title: 'Order Exchange',
                  icon: 'assets/img/icon/PriceManager.svg',
                  parentKey: 'products manager',
                  subKey: 'order exchange',
                  page: '/orders/orderReplacement'
                },
                {
                  title: 'Promotions',
                  icon: 'assets/img/icon/PriceManager.svg',
                  parentKey: 'products manager',
                  subKey: 'promotions',
                  page: '/products/promotions'
                }
              ],
            }
          },
          {
            title: 'Stock Manager',
            icon: 'assets/img/icon/StockManage.svg',
            parentKey: 'stock manager',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Inventory',
                  icon: 'assets/img/icon/InventoryStock.svg',
                  parentKey: 'stock manager',
                  subKey: 'inventory list',
                  page: '/opening-warehouse-stock'
                },
               
                {
                  title: 'Suppliers',
                  parentKey: 'stock manager',
                  subKey: 'suppliers',
                  icon: 'assets/img/icon/SuppliersVendors.svg',
                  page: '/suppliers'
                },
                {
                  title: 'Purchase Orders',
                  icon: 'assets/img/icon/PurchaseOrders.svg',
                  parentKey: 'stock manager',
                  subKey: 'purchase order',
                  page: '/PO'
                },
                {
                  title: 'Goods Received',
                  icon: 'assets/img/icon/InputStock.svg',
                  parentKey: 'stock manager',
                  subKey: 'goods received',
                  page: '/stocks'
                },
                
              
                
                {
                  title: 'Warehouses',
                  icon: 'assets/img/icon/Warehouse.svg',
                  parentKey: 'stock manager',
                  subKey: 'warehouse',
                  page: '/storage-point'
                },     
                {
                  title: 'Locations',
                  icon: 'assets/img/icon/Warehouse.svg',
                  parentKey: 'stock manager',
                  subKey: 'location',
                  page: '/storage-location'
                },
                {
                  title: 'Warehouse Settings',
                  parentKey: 'stock manager',
                  subKey: 'warehouse settings',
                  icon: 'assets/img/icon/PriceManager.svg',
                  page: '/products/warehouseSettings'
                },
                {
                  title: 'Identifications',
                  parentKey: 'stock manager',
                  subKey: 'identifications',
                  icon: 'assets/img/icon/Warehouse.svg',
                  page: '/itemsidentifications'
                },      
              ],
            }
          },
          {
            title: 'Integrations',
            icon: 'assets/img/icon/Shop.svg',
            parentKey: 'integration',
            subKey: 'integration',
            page: '/integrations/list'
          },
     
          
           
          { 
            title: 'Offline Sales',
            parentKey: 'offline sales',
            icon: 'assets/img/icon/Shop.svg',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Orders',
                  parentKey: 'offline sales',
                  subKey:'offline orders',
                  icon: 'assets/img/icon/orde.svg',
                  page: '/offline/stores/offlineOrders'
                },
                {
                  title: 'Quotations',
                  parentKey: 'offline sales',
                  subKey:'quatation',
                  icon: 'assets/img/icon/orde.svg',
                  page: '/offline/stores/offlineQuotation'
                },
                {
                  title: 'Products',
                  parentKey: 'offline sales',
                  subKey:'products',
                  icon: 'assets/img/icon/product.svg',
                  page: '/offline/stores/b2bproductslist'
                },
                {
                  title: 'Customers',
                  parentKey: 'offline sales',
                  subKey:'customers',
                  icon: 'assets/img/icon/Users.svg',
                  page: '/offline/stores/offlineCustomers'
                },
                {
                  title: 'Stores',
                  parentKey: 'offline sales',
                  subKey:'stores',
                  icon: 'assets/img/icon/Shop.svg',
                  page: '/offline/stores/storeDetails'
                }
              ],
            }
          },    
    
         
          { 
            title: 'Reports',
            parentKey: 'reports',
            icon: 'assets/img/icon/Reports.svg',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Inventory',
                  parentKey: 'reports',
                  subKey: 'inventory',
                  icon: 'assets/img/icon/InventoryStock.svg',
                  page: '/reports/inventory'
                },
                {
                  title: 'Sales',
                  parentKey: 'reports',
                  subKey: 'sales',
                  icon: 'assets/img/icon/usdollar.svg',
                  page: '/reports/earnings'
                },
                {
                  title: 'Products',
                  parentKey: 'reports',
                  subKey: 'product',
                  icon: 'assets/img/icon/product.svg',
                  page: '/reports/products'
                },
                {
                  title: 'Daily Sales',
                  parentKey: 'reports',
                  subKey: 'daily sales',
                  icon: 'assets/img/icon/orde.svg',
                  page: '/reports/dailysales'
                },
                {
                  title: 'Fee Name Mapping',
                  parentKey: 'reports',
                  subKey: 'feename Mapping',
                  icon: 'assets/img/icon/orde.svg',
                  page: '/reports/feenamemapping'
                },
              ],
            }
          },      
    
          { 
            title: 'Warehouse',
            parentKey: 'stock manager',
            icon: 'assets/img/icon/Warehouse.svg',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Inventory',
                  parentKey: 'stock manager',
                  subKey: 'warehouse inventory',
                  icon: 'assets/img/icon/Warehouse.svg',
                  page: '/warehouse'
                },
                {
                  title: 'Store Inventory',
                  icon: 'assets/img/icon/InventoryStock.svg',
                  page: '/warehouse/storeinventory'
                },
                {
                  title: 'Stock Transfers',
                  parentKey: 'stock manager',
                  subKey: 'stock transfers',
                  icon: 'assets/img/icon/Stock Transfer.svg',
                  page: '/warehouse/stocktransfers'
                },
                {
                  title: 'Write Off',
                  parentKey: 'stock manager',
                  subKey: 'write off',
                  icon: 'assets/img/icon/Write Off.svg',
                  page: '/warehouse/writeoff'
                },
              ]
            }
          },
           
          
    
          { 
            title: 'B2B',
            parentKey: 'b2b',
            icon: 'assets/img/icon/analytic.svg',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Orders',
                  icon: 'assets/img/icon/order.svg',
                  page: '/b2b/orders'
                },
                {
                  title: 'Retail Customers',
                  icon: 'assets/img/icon/ProductManager.svg',
                  page: '/b2b/customers'
                },
                {
                  title: 'App Users',
                  icon: 'assets/img/icon/Users.svg',
                  page: '/b2b/appusers'
                },
                {
                  title: 'Category Mapping',
                  icon: 'assets/img/icon/catalogue.svg',
                  page: '/b2b/categorymapping'
                },
                {
                  title: 'Products Discount',
                  icon: 'assets/img/icon/catalogue.svg',
                  page: '/b2b/productsgrademapping'
                },
                {
                  title: 'Customers Grade',
                  icon: 'assets/img/icon/catalogue.svg',
                  page: '/b2b/customersgrademapping'
                },
                
                {
                  title: 'Masters',
                  icon: 'assets/img/icon/Settings.svg',
                  page: 'null',
                  submenu: {
                    items: [
                      {
                        title: 'Categories',
                        //icon: 'assets/img/icon/catalogue.svg',
                        page: '/b2b/categories'
                      },
                      {
                        title: 'SubCategories',
                        //icon: 'assets/img/icon/catalogue.svg',
                        page: '/b2b/subcategories'
                      },
                      {
                        title: 'Grades',
                        //icon: 'assets/img/icon/catalogue.svg',
                        page: '/b2b/grades'
                      },
                      {
                        title: 'Credit Terms',
                        //icon: 'assets/img/icon/usdollar.svg',
                        page: '/b2b/creditterms'
                      },
                      {
                        title: 'Payment Modes',
                        //icon: 'assets/img/icon/usdollar.svg',
                        page: '/b2b/paymentmodes'
                      },
                      {
                        title: 'Payment Statuses',
                        //icon: 'assets/img/icon/PriceManager.svg',
                        page: '/b2b/paymentstatus'
                      },
                      {
                        title: 'Order Statuses',
                        //icon: 'assets/img/icon/PriceManager.svg',
                        page: '/b2b/orderstatus'
                      },
                      {
                        title: 'Delivery Types',
                        //icon: 'assets/img/icon/PriceManager.svg',
                        page: '/b2b/deliverytypes'
                      },
                      {
                        title: 'Holidays',
                        //icon: 'assets/img/icon/PriceManager.svg',
                        page: '/b2b/holidays'
                      },
                      {
                        title: 'UOM',
                        //icon: 'assets/img/icon/usdollar.svg',
                        page: '/b2b/uom'
                      },
                    ]
                  }
                },
                {
                  title: 'B2B Settings',
                  icon: 'assets/img/icon/Settings.svg',
                  page: '/b2b/settings'
                },
                
               
              ],
            }
          },
    
          {
            title: 'Users',
            icon: 'assets/img/icon/Users.svg',
            page: '/users/users-list'
          },
          {
            title: 'Roles',
            icon: 'assets/img/icon/Users.svg',
            page: '/users/roles-list'
          },
          {
            title: 'Company',
            icon: 'assets/img/icon/company.svg',
            page: '/company/CompanyList'
          },
          {
            title: 'Schedulers',
            parentKey: 'schedulers',
            icon: 'assets/img/icon/Schedulers.svg',
            page: 'null',
            submenu: {
              items: [
                {
                  title: 'Scheduled Jobs',
                  parentKey: 'schedulers',
                  subKey: 'scheduled jobs',
                  icon: 'assets/img/icon/Schedulers.svg',
                  page: '/scheduler/scheduledjobs'
                },
                {
                  title: 'Runs',
                  subKey: 'runs',
                  parentKey: 'schedulers',
                  icon: 'assets/img/icon/JobRuns.svg',
                  page: '/scheduler/runs'
                },
              ],
            }
          },
    
    
          
        ];
        if(this.rolesPermission){
          this.getPath(menuList);
        }
        
        
        //this.returnUrl = '/insights';
        if (this.returnUrl) {
          let returnUrl = this.returnUrl;
          this.modalRef.close();
          this.router.navigate([returnUrl]);
        }
        
      }
    );
  }

  //slider slick
  slides = [
    { img: "assets/img/Order-management.jpg" },
    { img: "assets/img/Group-Bundle.jpg" },
    { img: "assets/img/onboarding - missing-SKUs.jpg" }

  ];
  tabletSlide = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": false,
    "autoplaySpeed": 2000,
    "centerMode": true,
    "centerPadding": '0px',
    "infinite": true,
    "dots": true,
    "arrows":false,
    "responsive": [{
      "breakpoint": 1024,
      "settings": {
        "autoplay": true,
        "autoplaySpeed": 2000,
        "arrows":true
      }
    }]
  };

  testimonialSlide = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "centerMode": true,
    "centerPadding": '0px',
    "arrows": false,
    "dots": true,
    "variableWidth":true,
    "responsive": [{
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000,
        "infinite": true,
        "variableWidth":false,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth":false,
        "dots": true
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth":false,
        "dots": true
      }
    }
    ]
  };

  pricingSlide = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "autoplay": false,
    "autoplaySpeed": 0,
    "arrows": false,
    "dots": false,
    "responsive": [{
      "breakpoint": 1150,
      "settings": {
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000,
        "infinite": true,
        "arrows": true,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 2000,
        "arrows": true,
        "dots": true
      }
    }]
  };

  onClick(id: string): void {
    const el: HTMLElement | null = document.getElementById(id);
    if (el) {
      setTimeout(() =>
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
    }
  }
  scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      if (document.getElementById("header")) {
        document.getElementById("header").classList.add("fixed");
      }

    } else {
      if (document.getElementById("header")) {
        document.getElementById("header").classList.remove("fixed");
      }

    }
  }


  //window.onscroll = function() {scrollFunction()};

  // convenience getter for easy access to form fields
  get c() {
    return this.contactForm.controls;
  }

  tryContact() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }
    this.startBlockUI();

    this.signupService.doContact(this.contactForm.value).subscribe(
      (res: any) => {
        this.stopBlockUI();
        if (res.result == 1) {
          this.submitted = false;
          this.contactForm.reset();
          Swal.fire({
            title: 'Success',
            text: 'Great! We shall contact you Soon',
            icon: 'success',
          }).then((willDelete) => {
            if (willDelete) {
            } else {
            }
          });
        }

        if (res.result == 0) {
          Swal.fire({
            title: 'Error',
            text: 'Some thing went Wrong!, Please try again',
            icon: 'error',
          })
        }

      },
      err => {
        this.stopBlockUI();
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let errorDetails = err.error;
        Swal.fire({
          title: 'Some thing went Wrong!, Please try again',
          text: errorDetails,
          icon: 'error',
        })

      }
    );
  }

  public stopBlockUI(): void {
    this.blockLoader = false;
  }

  public startBlockUI(): void {
    this.blockLoader = true;
  }

}

