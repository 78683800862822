import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { id } from '@swimlane/ngx-datatable';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { delay, timeout } from 'rxjs/operators';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class CommonService {
  public sellerEditItem = {};
  public sellerSelected: string = '';
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getSellers(platformType, companyId) {
    return this.http.post(`${this.apiBaseURL}/Sellers/GetSellers`, { "salesPlatform": platformType, "companyID": companyId });
  }
  getSellersById(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/GetSellers`, obj);

  }
  createSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/NewSeller`, obj);
  }
  updateSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/UpdateSeller`, obj)
  }

  getReplaceOrder(obj){
    return this.http.post(`${this.apiBaseURL}/Common/GetReplaceOrderDetails`, obj)
  }
  onReplaceOrder(obj){
    return this.http.post(`${this.apiBaseURL}/Common/ReplaceOrderItems`, obj)
  }

  getStatus(platformType) {
    return this.http.get(`${this.apiBaseURL}/Common/GetOrderStatuses?Platform=${platformType}`);
  }
  getPickPack(reqObj, type) {
    return this.http.post(`${this.apiBaseURL}/Common/Get${type}List`, reqObj);
  }
  getMassPick(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/GetBatchCodePickList`, reqObj);
  }
  saveBatchCodePick(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/SaveBatchCodePickList`, reqObj);
  }
  validateProductBarcode(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/ValidateProductBarcode`, reqObj);
  }
  validateBatchBarcode(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/ValidateBatchBarcode`, reqObj);
  }

  checkProductBarcode(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/CheckBarcode`, reqObj);
  }

  checkProductBatchBarcode(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/CheckBatchBarcode`, reqObj);
  }
  checkAutocountBatchBarcode(reqObj){
    return this.http.post(`${this.apiBaseURL}/AutoCount/CheckAutoCountBarcode`, reqObj);
  }
  checkBatchCode(reqObj){
    return this.http.post(`${this.apiBaseURL}/StockIn/ValidateBatchCode`, reqObj);
  }
  checkStockBatchCode(reqObj){
    return this.http.post(`${this.apiBaseURL}/Stocks/ValidateBatchCode`, reqObj);
  }
  getStockinDetail(reqObj){
    return this.http.post(`${this.apiBaseURL}/StockIn/GetStockInDetails`, reqObj);
  }

  getSalesPlatform() {
    return this.http.get(`${this.apiBaseURL}/Common/GetSalesPlatform`);
  }

  getAccountingPlatform() {
    return this.http.get(`${this.apiBaseURL}/Common/GetAccountingPlatform`);
  }
  getCompanyList(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/GetCompany`, reqObj);
  }
  createCompany(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/NewCompany`, reqObj);
  }
  updateCompany(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/UpdateCompany`, reqObj);
  }
  generateInventoryQRCodes(obj) {
    return this.http.post(`${this.apiBaseURL}/Inventory/GenerateInventoryQRCodes`,obj);
  }
  getStorageTypesList() {
    return this.http.get(`${this.apiBaseURL}/WareHouse/GetStorageTypes`);
  }
  getStorageCategoriesList() {
    return this.http.get(`${this.apiBaseURL}/WareHouse/GetStorageCategories`);
  }
  createStoragePoint(obj) {
    return this.http.post(`${this.apiBaseURL}/WareHouse/CreateStoragePoint`, obj);
  }
  linkWareHouses(obj){
    return this.http.post(`${this.apiBaseURL}/WarehouseSetting/LinkWarehouse`, obj);
  }

  deleteWareHouses(obj){
    return this.http.post(`${this.apiBaseURL}/WarehouseSetting/DeleteLinkedWarehouse`, obj);
  }

  syncWareHouses(obj){
    return this.http.post(`${this.apiBaseURL}/WarehouseSetting/SyncWarehouse`, obj);
  }
  updateStoragePoint(obj) {
    return this.http.post(`${this.apiBaseURL}/WareHouse/EditStoragePoint`, obj)
  }
  DeleteStoragePoint(obj){
    return this.http.post(`${this.apiBaseURL}/WareHouse/DeleteStoragePoint`, obj);
  }
  getStoragePointByID(StoragePointID) {
    let params = new HttpParams();
    params = params.append("StoragePointID", StoragePointID);
    return this.http.get(`${this.apiBaseURL}/WareHouse/GetStoragePointByID`, { params: params })
  }
  getAllStoragePoints(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/WareHouse/GetAllStoragePoints`,{ params: params });
  }
  createStorageLocation(obj) {
    return this.http.post(`${this.apiBaseURL}/WareHouse/CreateStorageLocation`, obj);
  }
  updateStorageLocation(obj) {
    return this.http.post(`${this.apiBaseURL}/WareHouse/EditStorageLocation`, obj)
  }
  DeleteStorageLocation(obj){
    return this.http.post(`${this.apiBaseURL}/WareHouse/DeleteStorageLocation`, obj);
  }
  getStorageLocationByID(StorageLocationID) {
    let params = new HttpParams();
    params = params.append("storageLocationID", StorageLocationID);
    return this.http.get(`${this.apiBaseURL}/WareHouse/getStorageLocationByID`, { params: params });
  }
  getAllStorageLocations(companyID, StoragePointID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("StoragePointID", StoragePointID);
    return this.http.get(`${this.apiBaseURL}/WareHouse/GetAllStorageLocations`,{ params: params });
  }
  getCountryList() {
    return this.http.get(`${this.apiBaseURL}/Common/GetCountryCodes`);
  }
  getSuppliersList(obj) {
    return this.http.post(`${this.apiBaseURL}/Supplier/GetSuppliers`, obj)
  }
  getSupplierByID(obj) {
    return this.http.post(`${this.apiBaseURL}/Supplier/GetSuppliers`, obj)
  }
  createSupplier(obj) {
    return this.http.post(`${this.apiBaseURL}/Supplier/CreateSupplier`, obj)
  }
  updateSupplier(obj) {
    return this.http.post(`${this.apiBaseURL}/Supplier/UpdateSupplier`, obj)
  }
  getStockInList(obj) {
    return this.http.post(`${this.apiBaseURL}/StockIn/GetStockInList`, obj)
  }

  getStocksList(obj) {
    return this.http.post(`${this.apiBaseURL}/Stocks/GetStockInList`, obj)
  }
  getProductsDropdownList(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Inventory/GetInventoryList`, { params: params });
  }

  getProductsDropdownListWithoutBundle(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Inventory/GetInvWithoutBundles`, { params: params });
  }

  getStoragePointDropdownList(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.post(`${this.apiBaseURL}/Supplier/GetStoragePoints`, "", { params: params });
  }

  getStorageLocationDropdownList(storagePointID) {
    let params = new HttpParams();
    params = params.append("storagePointID", storagePointID);
    return this.http.get(`${this.apiBaseURL}/Supplier/GetStorageLocation`, { params: params });
  }
  createStockIn(obj) {
    return this.http.post(`${this.apiBaseURL}/StockIn/CreateStockIn`, obj);
  }
  createStock(obj) {
    return this.http.post(`${this.apiBaseURL}/Stocks/CreateStockIn`, obj);
  }
  editStock(obj) {
    return this.http.post(`${this.apiBaseURL}/Stocks/EditStockIn`, obj);
  }
  updateRestock(companyID, sku, reStock, whId){
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("SKU", sku);
    params = params.append("RestockQty", reStock);
    params = params.append("WHInventoryID", whId)
    return this.http.post(`${this.apiBaseURL}/OpeningStock/SetRestock`, "", { params: params });
  }
  removeInventory(obj){
    return this.http.post(`${this.apiBaseURL}/Inventory/DeleteInventoryBySKU`, obj);
  }
  removeStock(companyID, StockinID){
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("StockinID", StockinID);
    return this.http.post(`${this.apiBaseURL}/Stocks/DeleteStockIn`, "", { params: params });
  }
  getStock(obj){
    return this.http.post(`${this.apiBaseURL}/Stocks/GetStockInDetails`, obj);
  }
  getLazadaAuthenticateUrl(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Lazada/GetLazadaURL`, { params: params });
  }
  getShopeeAuthenticateUrl(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeURL`, "", { params: params });
  }
  getShopeeAuthenticateUrlV2(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.post(`${this.apiBaseURL}/Shopeev2/GetShopeeURL`, "", { params: params });
  }
  syncShopee(companyID, sellerID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("sellerid", sellerID);
    return this.http.get(`${this.apiBaseURL}/Shopeev2/GetShopeeInfo`, { params: params });
  }
  shopeeOnboard(obj) {
    return this.http.post(`${this.apiBaseURL}/Shopeev2/ShopeeOnboard`, obj);
  }

  getLazadaAuthenticateUrlV2(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Lazada/GetLazadaURL`,{ params: params });
  }

  lazadaOnboard(obj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/LazOnboard`, obj);
  }

  syncLazada(companyID, sellerID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("Sellerid", sellerID);
    return this.http.get(`${this.apiBaseURL}/Lazada/GetSellerInfo`, { params: params });
  }

  getAmazonAuthenticateUrlV2(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Amazon/GetAuthURL`,{ params: params });
  }

  amazonOnboard(obj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/AmazonOnboard`, obj);
  }

  syncAmazon(companyID, sellerID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("Sellerid", sellerID);
    return this.http.get(`${this.apiBaseURL}/Amazon/GetSellerInfo`, { params: params });
  }

  getTiktokAuthenticateUrlV2(companyID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    return this.http.get(`${this.apiBaseURL}/Tiktok/GetAuthURL`,{ params: params });
  }

  tiktokOnboard(obj) {
    return this.http.post(`${this.apiBaseURL}/Tiktok/TiktokOnboard`, obj);
  }

  syncTiktok(companyID, sellerID) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyID);
    params = params.append("Sellerid", sellerID);
    return this.http.get(`${this.apiBaseURL}/Tiktok/GetSellerInfo`, { params: params });
  }

  syncQoo10(companyID, sellerID) {
    let params = new HttpParams();
    params = params.append("companyid", companyID);
    params = params.append("sellerid", sellerID);
    return this.http.get(`${this.apiBaseURL}/Qoo10/GetShopeInfo`, { params: params });
  }

  qoo10Onboard(obj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/Qoo10Onboard`, obj);
  }


  GetShopeeSellerInfo(obj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/ValidateSeller`, obj);
  }
  saveShopeeSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/SaveShopeeSeller`, obj)
  }
  saveQoo10Seller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/SaveQoo10Seller`, obj)
  }
  getQoo10ValidateSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ValidateSeller`, obj)
  }
  GetSellerInfo(obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", obj.companyID);
    params = params.append("Code", obj.code);
    return this.http.get(`${this.apiBaseURL}/Lazada/GetLazadaURL`, { params: params });
  }

  getPurchaseOrders(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/GetPurchaseOrders`, obj);
  }
  getPurchaseOrderDetails(obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", obj.CompanyID);
    params = params.append("PurchaseOrderID", obj.PurchaseOrderID);
    return this.http.get(`${this.apiBaseURL}/PO/GetPurchaseOrderDetails`, { params: params });
  }
  createPurchaseOrder(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/CreatePurchaseOrder`, obj);
  }
  saveLazadaSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/SaveLazadaSeller`, obj);
  }
  getDODetails(obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", obj.CompanyID);
    params = params.append("DeliveryOrderID", obj.DeliveryOrderID);
   // return this.http.get(`${this.apiBaseURL}/PO/GetDeliveryOrderDetails`, { params: params });
    return this.http.get(`${this.apiBaseURL}/PO/GetDODetails`, { params: params });
  }
  updatePO(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/UpdatePurchaseOrder`, obj);
  }
  PoRaised(companyId, poId) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyId);
    params = params.append("PurchaseOrderID", poId);
    return this.http.get(`${this.apiBaseURL}/PO/MarkASRaised`, { params: params });
  }
  createDeliveryOrder(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/CreateDeliveryOrder`, obj);
  }

  getPOCheckInItems(obj) {
    let params = new HttpParams();
   // params = params.append("PurchaseOrderID", obj);
    //return this.http.get(`${this.apiBaseURL}/PO/GetPOCheckInItems`, { params: params });
    params = params.append("CompanyID", obj.CompanyID);
    // params = params.append("PurchaseOrderID", obj.PurchaseOrderID);
    // return this.http.get(`${this.apiBaseURL}/PO/GetPurchaseOrderDetails`, { params: params });
    params = params.append("DeliveryOrderID", obj.DeliveryOrderID);
    // return this.http.get(`${this.apiBaseURL}/PO/GetDeliveryOrderDetails`, { params: params });
     return this.http.get(`${this.apiBaseURL}/PO/GetDODetails`, { params: params });
  }
  checkInPO(obj) {
    //return this.http.post(`${this.apiBaseURL}/PO/CheckInPO`, obj);
    return this.http.post(`${this.apiBaseURL}/PO/CheckIn`, obj);
  }
  getOpeningStocks(obj) {
    return this.http.post(`${this.apiBaseURL}/OpeningStock/GetOpeningStocks`, obj);
  }

  getNewOpeningStocks(obj) {
    return this.http.post(`${this.apiBaseURL}/OpeningStock/GetNewOpeningStocks`, obj);
  }
  getReservedStocks(id) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    return this.http.post(`${this.apiBaseURL}/OpeningStock/SyncReservedQty?CompanyID=${id}`, {});
  }
  updateOpeningStocks(obj) {
    return this.http.post(`${this.apiBaseURL}/OpeningStock/EditStock`, obj);
  }
  openingStockValidateSKU(obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", obj.CompanyID);
    params = params.append("ProductSKU", obj.ProductSKU);
    return this.http.get(`${this.apiBaseURL}/OpeningStock/ValidateSKU`, { params: params });
  }
  createOpeningStock(obj) {
    return this.http.post(`${this.apiBaseURL}/OpeningStock/NewOpeningStock`, obj);

  }
  uploadOpeningStocks(id, obj, type?) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    let url = '/OpeningStock/UpdateOpeningStocks';
    if(type === 'Product Codes') {
      url = '/OpeningStock/UpdateSKUMapping';
    }
    return this.http.post(`${this.apiBaseURL}${url}`, obj, { params: params });
  }
  getPriceManagementList(obj) {
    return this.http.post(`${this.apiBaseURL}/PriceManagement/GetPriceList`, obj);
  }

  editCostPrice(obj) {
    return this.http.post(`${this.apiBaseURL}/PriceManagement/EditCostPrice`, obj);
  }
  editSellPrice(obj) {
    return this.http.post(`${this.apiBaseURL}/PriceManagement/EditSellPrice`, obj);
  }

  updateIndividualOpeningStocks(obj){
    return this.http.post(`${this.apiBaseURL}/OpeningStock/EditStockBySeller`, obj);
  }
  getPriceManagementTemplate(id) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    return this.http.post(`${this.apiBaseURL}/PriceManagement/GetPriceTemplate`, "", { params: params }).pipe(timeout(600000));
  }
  getOpeningStockTemplate(id, type?) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    let url = '/OpeningStock/GetOSTemplate';
    if(type === 'Product Codes') {
      url = '/OpeningStock/GetSKUMappingTemplate';
    }
    return this.http.get(`${this.apiBaseURL}${url}`, { params: params }).pipe(timeout(600000));
  }
  updatePriceManagementPrices(id, obj, platform) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    params = params.append("platform", platform);
    return this.http.post(`${this.apiBaseURL}/PriceManagement/UpdatePrices`, obj, { params: params });
  }
  getProductsMergedSKU(id, platform) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    params = params.append("platform", platform);
    return this.http.get(`${this.apiBaseURL}/MergedSku/GetProducts`, { params: params });
  }
  createNewMergedSKU(obj, url) {
    return this.http.post(url, obj);
  }
  getMergedSKUS(id, sku, isExact) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    params = params.append("SearchKey", sku);
    return this.http.post(`${this.apiBaseURL}/MergedSku/GetMergedSKUs`, { CompanyID: id, SearchKey: sku, isExactSearch:  isExact});

  }
  removeMergedSKU(obj){
    return this.http.post(`${this.apiBaseURL}/MergedSku/DeleteMergedSKU`, obj);
  }

  getStockDetails(companyId, inventoryId){
    let params = new HttpParams();
    params = params.append("CompanyID", companyId);
    params = params.append("InventoryID", inventoryId);
    return this.http.get(`${this.apiBaseURL}/OpeningStock/GetOpeningStockByID`, { params: params });
  }
  getNewStockDetails(companyId, inventoryId, warehouseId){
    let params = new HttpParams();
    params = params.append("CompanyID", companyId);
    params = params.append("InventoryID", inventoryId);
    params = params.append("WareHouseID", warehouseId);
    return this.http.get(`${this.apiBaseURL}/OpeningStock/GetNewOpeningStockByID`, { params: params });
  }

  getWareHouseStock(companyId, sku) {
    let params = new HttpParams();
    params = params.append("CompanyID", companyId);
    params = params.append("SKU", sku);
    //return this.http.get(`${this.apiBaseURL}/OpeningStock/GetWarehouseDetails`, { params: params });
    return this.http.post(`${this.apiBaseURL}/OpeningStock/GetWarehouseDetails`, {CompanyID: companyId, SKU:sku});
  }


  /* STOCK IN - DOWNLOAD & UPLOAD - 13-07-2021 */
  GetStockInTemplate(companyId){
    let params = new HttpParams();
    params = params.append("CompanyID", companyId);
    return this.http.get(`${this.apiBaseURL}/StockIn/GetStockInTemplate`, { params: params });
  }

  UploadStockInTemplate(id, obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    return this.http.post(`${this.apiBaseURL}/StockIn/UploadStockInTemplate`, obj, { params: params });
  }


  DeleteSupplier(obj){
    return this.http.post(`${this.apiBaseURL}/Supplier/DeleteSupplier`, obj);
  }

  //DELIVERY ORDERS
  getDeliveryOrders(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/GetDeliveryOrders`, obj);
  }

  updateDO(obj) {
    return this.http.post(`${this.apiBaseURL}/PO/UpdateDeliveryOrder`, obj);
  }

  getRaisedPO(obj) {
    return this.http.get(`${this.apiBaseURL}/PO/GetRaisedPO`, {params:obj})
    //return this.http.post(`${this.apiBaseURL}/PO/GetRaisedPO`, obj)
  }

  GetActivePlatforms(companyId) {
    return this.http.get(`${this.apiBaseURL}/Sellers/GetActivePlatforms?CompanyID=${companyId}`)
  }

  ExportPackList(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/ExportPackList`, reqObj);
  }

  GetShipmentList(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/GetShipmentList`, reqObj);
  }

  ExportShipmentList(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Common/ExportShipmentList`, reqObj);
  }

  SaveWooStore(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/SaveWooStore`, obj)
  }

  DeleteInventoryBySKU(obj){
    return this.http.post(`${this.apiBaseURL}/Inventory/DeleteInventoryBySKU`, obj);
  }

  GetBundleMergedSKUs(obj){
    return this.http.post(`${this.apiBaseURL}/OpeningStock/GetBundleMergedSKUs`, obj);
  }
//EASYPARCEL  - START
EPRateCheck(obj){
  return this.http.post(`${this.apiBaseURL}/EasyParcel/EPRateCheck`, obj);
}
EPPlaceOrder(obj){
  return this.http.post(`${this.apiBaseURL}/EasyParcel/EPPlaceOrder`, obj);
}
GetEPAddresses(obj){
  return this.http.post(`${this.apiBaseURL}/EasyParcel/GetEPAddresses`, obj);
}
//EASYPARCEL  - END
//GST
GetGSTPercentage(GSTDate) {
return this.http.post(`${this.apiBaseURL}/PO/GetGSTPercentage?GSTDate=${GSTDate}`,{GSTDate:GSTDate});
}
UpdateSellerRemarks(obj){
  return this.http.post(`${this.apiBaseURL}/Common/UpdSellerRemarks`, obj)
}

//INEGRATIONS COMMON SERVICES
GetPlatformChargesColumnMapping(platform) {
  return this.http.get(`${this.apiBaseURL}/Common/GetPlatformChargesColumnMapping?platform=${platform}`);
}
GetOnlinePlatformCharges(platform) {
  return this.http.get(`${this.apiBaseURL}/Common/GetOnlinePlatformCharges?platform=${platform}`);
}
  
  saveAmazonSeller(obj) {
    return this.http.post(`${this.apiBaseURL}/Sellers/SaveAmazonSeller`, obj);
  }
}