<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i%7CQuicksand:300,400,500,700"
    rel="stylesheet">


</head>

<body style="height: fit-content !important;
    padding-top: 0rem !important;">
  <header class="navigation-top top-zero" id="header">
    <div class="container">
      <nav class="navbar" id="navbar-example2">
        <a class="navbar-brand scrollto" (click)="onClick('hero')">
          <img class="img-fluid" src="assets/img/logo.svg" style="height:70px; width:auto"> </a>
        <div class="inner-nav">
          <p class="close-menu"></p>

          <ul class="nav nav-pills">
            <a class="mobile-menu-logo scrollto" (click)="onClick('hero')"><img src="assets/img/logo.svg" /> </a>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('contact')">Contact</a></li>
            <li class="nav-item"><a class="nav-link scrollto" routerLink="/signup">Get Started</a></li>
            <li class="nav-item" ngbDropdown>
              <a type="button" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag"
                style="background:none;">
                <span style="font-size:16px">Events</span>
              </a>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                <a class="dropdown-item" routerLink="/amazonexpo">Amazon Expo Boot Camp</a>
                <a class="dropdown-item" routerLink="/shopeeworkshop">Shopee University workshop</a>
              </div>
            </li>
            <li class="nav-item button-menu"><a class="getstarted scrollto"
                (click)="onClick('LoginFormIconContent')">Login</a></li>
          </ul>
        </div>
        <a class="nav-bar"><i class="bi bi-list">&nbsp;</i></a>
      </nav>
    </div>
  </header>
  <!-- ======= End Header ======= -->







  <!-- ======= Hero Section ======= -->
  <div>
    <section style="padding: 7%;">

      <div class="card1">
        <div class="card-body">
          <div class="row" style="font-size: 90%;padding:1%">
            <div class="col-lg-12 m-1">
            </div>

            <div class="col-lg-12 m-2">
              <h1 style="font-size: 175%;color:#3A519F;font-weight: 600;">Terms of Service</h1>
            </div>

            <div class="col-lg-12" style="padding-left:3%">

              <p class="ql-align-justify">
                The Sell &amp; Chill brand and related services are provided by Mind Master Solutions Pte Ltd (“
                <strong>
                  Mind Master
                </strong>
                ”). These Terms of Service (“
                <strong>
                  Terms
                </strong>
                ”) constitute a contract between you and Mind Master and govern the use by you, your agents and end
                users of our website at
                <a href="https://sellnchill.com" rel="noopener noreferrer" target="_blank"
                  style="color: rgb(0, 0, 255);">
                  <u>
                    https://sellnchill.com
                  </u>
                </a>
                &nbsp;(the “
                <strong>
                  Website
                </strong>
                ”), the Sell &amp; Chill platform and all of the related products and services offered by Mind Master
                thereon.
              </p>
              
              <p class="ql-align-justify">
                By accepting these Terms, or by accessing or using the service or site, or authorising or permitting any
                agent or end-user to access or use the service, you agree to be bound by these terms. If you are
                entering into these Terms on behalf of a company, organisation or another legal entity (an “
                <strong>
                  Entity
                </strong>
                ”), you are agreeing to these Terms for that Entity and representing to Mind Master that you have the
                authority to bind such Entity and its affiliates to these Terms, in which case the terms “you”, “your”
                or related terms herein shall refer to such Entity and its affiliates. If you do not have such
                authority, or if you do not agree with these Terms, you must not accept these Terms and may not use the
                service.
              </p>
              
              <p class="ql-align-justify">
                If you have any questions about these Terms, please feel free to contact us through our site.
              </p>
              
              <p class="ql-align-justify">
                1.&nbsp;
                <strong>
                  Services
                </strong>
              </p>
              
              <p class="ql-align-justify">
                1.1&nbsp;Mind Master provides users with access to a variety of online applications, including but not
                limited to Sell &amp; Chill, a Multichannel eCommerce Enabler and any applicable software (collectively,
                the “
                <strong>
                  Services
                </strong>
                ”). The Services are provided by Mind Master to you and any individual or Entity to whom you grant the
                right to access the Services through your account as an agent and/or administrator as identified through
                a unique login (“
                <strong>
                  Agent
                </strong>
                ”), or as an end-user connecting with you or your Agents via the Services (“
                <strong>
                  End User
                </strong>
                ”), subject to these Terms and all modifications thereto and/or other rules that may be published from
                time to time by Mind Master.
              </p>
              
              <p class="ql-align-justify">
                1.2&nbsp;Unless explicitly stated otherwise, any new features and/or updates that augment or enhance the
                Services shall be subject to these Terms.
              </p>
              
              <p class="ql-align-justify">
                1.3&nbsp;The use of the Services is at your own risk. Mind Master does not warrant any results from the
                Services. The Services do not constitute consulting, professional advice or any other form of advice
                whatsoever.
              </p>
              
              <p class="ql-align-justify">
                1.4&nbsp;You shall use the Services in compliance with these Terms. You shall use the Services only if
                you have the legal capacity to do so. In using the Services, you shall comply with all applicable laws.
                You accept that the Services may change, including when Mind Master refines and adds more features. We
                may stop, suspend or modify the Services at any time without prior notice to you. We may also remove any
                content from our Services. You agree that Mind Master shall not be liable to you or to any third party
                for any modification, suspension or discontinuance of the Services.
              </p>
              
              <p class="ql-align-justify">
                1.5&nbsp;You acknowledge and agree that your use of the Services, including information transmitted to
                or stored by Mind Master, is governed by our privacy policy at
                <a href="https://sellnchill.com/privacy-policy" rel="noopener noreferrer" target="_blank"
                  style="color: rgb(0, 0, 255);">
                  <u>
                    https://sellnchill.com/privacy-policy
                  </u>
                </a>
                .
              </p>
              
              <p class="ql-align-justify">
                1.6&nbsp;A breach or violation of any term in these Terms, including the Acceptable Use Policy set out
                herein, as determined in the sole discretion of Mind Master, may result in an immediate termination of
                your Services.
              </p>
              
              
              <p class="ql-align-justify">
                <span style="color: rgb(0, 0, 0);">
                  2.&nbsp;
                </span>
                <strong style="color: rgb(0, 0, 0);">
                  Subscription Fees and Subscription Period
                </strong>
              </p>
              
              <p class="ql-align-justify">
                2.1&nbsp;To the extent the Services or any portion thereof is made available for any fee, you will be
                required to select a subscription payment plan, the terms of which shall be set out in Mind Master’s
                quotation for providing the Services to you (the “
                <strong>
                  Quotation
                </strong>
                ”). You shall provide Mind Master with information regarding your credit card or other payment
                instrument. You represent and warrant to Mind Master that such information is true and that you are
                authorised to use the payment instrument. You will promptly update your account information with any
                changes (for example, a change in your billing address or credit card expiration date) that may occur.
              </p>
              
              <p class="ql-align-justify">
                2.2&nbsp;You agree to pay Mind Master the amount specified in the Quotation for your selected
                subscription payment plan in accordance with the terms of such subscription plan as presented on the
                Quotation.
              </p>
              
              <p class="ql-align-justify">
                2.3&nbsp;Our charges stated are exclusive of all taxes, including Goods and Services Tax, VAT, sales
                tax, service tax or other similar taxes or levies. You are liable to pay all taxes associated with your
                subscription.
              </p>
              
              <p class="ql-align-justify">
                2.4&nbsp;Mind Master’s payment terms shall be set out in the Quotation and/or invoice(s) to you.
              </p>
              
              <p class="ql-align-justify">
                2.5&nbsp;You shall make payment to Mind Master in the manner and terms set out in the Quotation and/or
                invoice(s), without any deduction whether by way of set-off, withholding, counterclaim, discount or
                otherwise. Time for payment shall be of the essence.
              </p>
              
              <p class="ql-align-justify">
                2.6&nbsp;No indulgence granted by Mind Master concerning your payment obligations shall be or deemed to
                be a credit facility, or be taken to be any waiver of our right to payment.&nbsp;
              </p>
              
              <p class="ql-align-justify">
                2.7&nbsp;If any sum due from you to Mind Master under the Quotation or any other contract is not paid
                before the due date for payment:-
              </p>
              <p class="ql-align-justify">
                2.7.1&nbsp;all sums then owing by you to Mind Master shall become due and payable immediately;
              </p>
              <p class="ql-align-justify">
                2.7.2&nbsp;without prejudice to any other right or remedy available to Mind Master, we will immediately
                stop providing you the Services, and your right and licence to use the Services shall immediately cease
                <span style="color: rgb(0, 0, 0);">
                  .
                </span>
                &nbsp;Mind Master shall have no responsibility or liability with respect to any loss of your data or
                content in this event.
              </p>
              
              <p class="ql-align-justify">
                2.8&nbsp;Mind Master is entitled to be indemnified by you for all expenses, legal fees and court costs
                on a solicitor and client basis, incurred by us to collect and obtain payment of any outstanding sums
                from you to Mind Master.
              </p>
              
              <p class="ql-align-justify">
                3.&nbsp;
                <strong>
                  Ownership and Privacy
                </strong>
              </p>
              
              <p class="ql-align-justify">
                3.1&nbsp;When using our Services you may provide us with information, files, data, links to data
                sources, folders and other content which belong to you, your Agents or end users (collectively referred
                to as “
                <strong>
                  your content
                </strong>
                ”). You retain full ownership to your content. Save as provided in these Terms, and that Mind Master
                shall have the non-exclusive right and license to use the names, trademarks, service marks and logos
                associated with your company to promote the Services, we do not claim any intellectual property rights
                over your content.
              </p>
              
              <p class="ql-align-justify">
                3.2&nbsp;We do not monitor your content or any other information that are subject to the Services. We
                are not responsible for the accuracy, completeness, appropriateness or legality of your content or any
                other content, results and information you may access with or receive from the Services. You acknowledge
                and agree, however, that Mind Master shall have the right to monitor the Services and disclose any
                information as necessary or appropriate to satisfy any law, regulation or other governmental request, to
                operate the Services properly, or to protect the Services.
              </p>
              
              <p class="ql-align-justify">
                3.3&nbsp;You grant us a limited, free, irrevocable, non-exclusive and worldwide license to store,
                process, back-up and otherwise deal with your content solely for the purpose of providing to you the
                Services. We may grant a similar sub-license to the subcontractors, suppliers and service providers that
                we may use to provide the Services.
              </p>
              
              <p class="ql-align-justify">
                3.4&nbsp;Except as provided in these Terms, we do not disclose your content to other users, customers,
                agencies or third parties.
              </p>
              
              <p class="ql-align-justify">
                3.5&nbsp;In using the Services, you are solely responsible for your conduct, content and dealing with
                your content and your communications with others. You warrant that you have all the right and power
                required to deal with your content.
              </p>
              
              <p class="ql-align-justify">
                4.&nbsp;
                <strong>
                  Sharing
                </strong>
              </p>
              
              <p class="ql-align-justify">
                The Services may allow you to share with others your content or the results of the Services. There are
                many things that others may do with your information. For example, they may copy, modify or share your
                content with others. Your content may contain information that is secret, confidential or sensitive.
                Before sharing them, you may wish to ensure that you are protected by appropriate confidentiality
                agreements. We are not responsible or liable for any of your activities described in this clause.
              </p>
              
              <p class="ql-align-justify">
                5.&nbsp;
                <strong>
                  Your Responsibilities
                </strong>
              </p>
              
              <p class="ql-align-justify">
                5.1&nbsp;During the term of your subscription to the Services, you have the limited right to use the
                Services solely for your own internal, personal or commercial use.
              </p>
              
              <p class="ql-align-justify">
                5.2&nbsp;You shall not transfer, lease, rent, assign, sublicense or resell the Services, in whole or in
                part, and you further agree not to allow any third party other than your Agents and end users to access
                the Services. You are responsible for compliance with provisions of these Terms by your Agents and end
                users and for any and all activities that occur under your account.
              </p>
              
              <p class="ql-align-justify">
                5.3&nbsp;Files and other content in the Services may be subject to the intellectual property rights of
                others. You agree not to copy, upload, download, share or otherwise deal with content or information
                unless you have the necessary right or license. You alone are responsible and liable for content and
                information that you copy, share, upload, download or otherwise deal with.
              </p>
              
              <p class="ql-align-justify">
                5.4&nbsp;You shall not by any act or omission cause spyware, virus or other malicious software to infect
                the Services.
              </p>
              
              <p class="ql-align-justify">
                5.5&nbsp;You alone are responsible and liable for maintaining and protecting your content. We are not
                responsible or liable for any expenses, losses or damages which you may suffer or incur arising out of
                or in connection with the loss or corruption of your content, including costs or expenses for backing up
                or restoring your content.
              </p>
              
              <p class="ql-align-justify">
                5.6&nbsp;You shall be responsible for obtaining and maintaining any equipment and ancillary services
                needed to connect to, access or otherwise use the Services (collectively, “
                <strong>
                  Equipment
                </strong>
                ”). You shall be responsible for ensuring that such Equipment is compatible with the Services and
                complies with all configurations and specifications set forth in Mind Master’s policies then in effect.
              </p>
              
              <p class="ql-align-justify">
                5.7&nbsp;You shall from time to time update your contact information or other information related to
                your account. You shall provide true, accurate, current and complete information as required by Mind
                Master.
              </p>
              
              <p class="ql-align-justify">
                6.&nbsp;
                <strong>
                  Account Security
                </strong>
              </p>
              
              <p class="ql-align-justify">
                You shall keep secret the password that you use to access the Services and not disclose your password.
                You are responsible for keeping your password secure. You are responsible and liable for all activities
                related to your account, whether or not you authorized that activity. You should immediately notify us
                of any unauthorized use of your account. You agree and acknowledge that a secure encrypted connection to
                communicate with the Services can help protect your content. If you are an administrator, you shall
                ensure that all your users of the Services comply with the obligations in this clause. Mind Master
                cannot and will not be liable for any loss or damage from your failure to maintain the security of your
                account and password.
              </p>
              
              
              <p class="ql-align-justify">
                7.&nbsp;
                <strong>
                  Software and Updates
                </strong>
              </p>
              
              <p class="ql-align-justify">
                We provide you software as a service, as a means for you to use the Services. We grant you a limited,
                nonexclusive, non-transferable, revocable license to use the software, solely to access the Services.
                Your license to use the software is automatically revoked if you breach any of these Terms. We reserve
                all rights not expressly granted in these Terms. You shall not directly or indirectly reverse engineer
                or decompile the software, attempt to or assist others to do so. Our Services may update the software on
                your device automatically when a new version is available.
              </p>
              
              
              <p class="ql-align-justify">
                8.&nbsp;
                <strong>
                  Our Property and Feedback
                </strong>
              </p>
              
              <p class="ql-align-justify">
                These Terms do not grant you any right, title or interest in the Services, our software or any of their
                content. We may use any of your feedback, comments or suggestions without any obligation or liability to
                you. Copyright, trademark and other applicable laws protect the software and other technology we use to
                provide the Services. These Terms do not grant you any rights to use any of our trademarks, logos,
                domain names or other brand features. We may use all data, content and results arising out of or in
                connection with the Services or software in aggregated anonymous form in the ordinary course of our
                business, including to generate benchmarks or similar metrics. You are not permitted to disseminate any
                information that is made available to you, as a licensee, by Mind Master, including without limitation,
                login credentials.
              </p>
              
              
              <p class="ql-align-justify">
                9.&nbsp;
                <strong>
                  Third Party Services
                </strong>
              </p>
              
              <p class="ql-align-justify">
                9.1&nbsp;In using Sell &amp; Chill and our Services, we may from time to time recommend, provide you
                with access to, or enable third party software, applications, products, services or website links
                (collectively, “
                <strong>
                  Third Party Services
                </strong>
                ”) for your consideration or use, including but not limited to marketplaces such as Shopee, Lazada,
                Qoo10 and Amazon, webstores such as Shopify and WooCommerce, and other accounting and integration
                software. Such Third Party Services are made available only as a convenience, and your purchase, access
                or use of any such Third Party Services is solely between you and the applicable third party services
                provider (“
                <strong>
                  Third Party Provider
                </strong>
                ”). In addition to these Terms, you also agree to be bound by the additional service-specific terms and
                privacy policies applicable to services that are provided by Third Party Providers.
              </p>
              
              <p class="ql-align-justify">
                  9.2&nbsp;Any use by you of Third Party Services offered through the Services or Website is entirely at
                  your own risk and discretion, and it is your responsibility to read the terms and conditions and/or
                  privacy policies applicable to such Third Party Services before using them.
              </p>
             
              <p class="ql-align-justify">
                  9.3&nbsp;We do not provide any warranties or make representations to you with respect to Third Party
                  Services. You acknowledge that Mind Master has no control over Third Party Services and shall not be
                  responsible or liable to you or anyone else for such Third Party Services. The availability of Third
                  Party Services on the Website, or the integration or enabling of such Third Party Services with the
                  Services does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or
                  with Mind Master. Mind Master does not guarantee the availability of Third Party Services and you
                  acknowledge that Mind Master may disable access to any Third Party Services at any time in its sole
                  discretion and without notice to you. Mind Master is not responsible or liable to anyone for
                  discontinuation or suspension of access to, or disablement of, any Third Party Service.
              </p>
             
              <p class="ql-align-justify">
                  9.4&nbsp;If you install or enable a Third Party Service for use with the Services, you grant us
                  permission to allow the applicable Third Party Provider to access your data and your content and to
                  take any other actions as required for the interoperation of the Third Party Service with the
                  Services, and any exchange of data or other content or other interaction between you and the Third
                  Party Provider is solely between you and such Third Party Provider. Mind Master is not responsible for
                  any disclosure, modification or deletion of your data or your content, or for any corresponding losses
                  or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to
                  your data or your content.
              </p>
            
              <p class="ql-align-justify">
                  9.5&nbsp;The relationship between you and any Third Party Provider is strictly between you and such
                  Third Party Provider, and Mind Master is not obligated to intervene in any dispute arising between you
                  and a Third Party Provider. You are solely responsible for your dealings with any Third Party Provider
                  related to the Services, including the delivery of and payment for goods and services. Should you have
                  any problems resulting from your use of any Third Party Services, or should you suffer data loss or
                  other losses as a result of problems with any of your other service providers or any Third Party
                  Services, we disclaim all liability.
              </p>
             
              <p class="ql-align-justify">
                  9.6&nbsp;You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates,
                  Mind Master partners, officers, directors, agents, employees, and suppliers harmless from any claim or
                  demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Service or your
                  relationship with a Third Party Provider.
              </p>
              
              
              <p class="ql-align-justify">
                10.&nbsp;
                <strong>
                  Acceptable Use Policy and Compliance with Laws
                </strong>
              </p>
              
              <p class="ql-align-justify">
                10.1&nbsp;You shall not directly or indirectly abuse the Services or deal with the Services otherwise
                than for their intended ordinary uses.
              </p>
              
              <p class="ql-align-justify">
                10.2&nbsp;Without limiting the generality of the previous sub-clause, you shall not carry out any of the
                following:
              </p>
              <p class="ql-align-justify">
                10.2.1&nbsp;probe, scan, or test the vulnerability of any system or network;
              </p>
              <p class="ql-align-justify">
                10.2.2&nbsp;breach or otherwise circumvent any security or authentication measures;
              </p>
              <p class="ql-align-justify">
                10.2.3&nbsp;access, tamper with, or use non-public areas of the Services, shared areas of the Services
                you have not been invited to, our (or our service providers’) computer systems;
              </p>
              <p class="ql-align-justify">
                10.2.4&nbsp;interfere with or disrupt any user, host, or network, for example by sending a virus,
                overloading, flooding, spamming, or mail-bombing any part of the Services;
              </p>
              <p class="ql-align-justify">
                10.2.5&nbsp;plant malware or otherwise use the Services to distribute malware;
              </p>
              <p class="ql-align-justify">
                10.2.6&nbsp;access or search the Services by any means other than our publicly supported interfaces (for
                example, by scraping);
              </p>
              <p class="ql-align-justify">
                10.2.7&nbsp;send unsolicited communications, promotions or advertisements, or spam;
              </p>
              <p class="ql-align-justify">
                10.2.8&nbsp;send altered, deceptive or false source-identifying information, including spoofing or
                phishing;
              </p>
              <p class="ql-align-justify">
                10.2.9&nbsp;publish anything that is fraudulent, misleading or infringes another’s rights;
              </p>
              <p class="ql-align-justify">
                10.2.10&nbsp;impersonate or misrepresent your affiliation with any person or entity;
              </p>
              <p class="ql-align-justify">
                10.2.11&nbsp;publish or share materials that are unlawfully pornographic or indecent, or that advocate
                bigotry, religious, racial or ethnic hatred; or
              </p>
              <p class="ql-align-justify">
                10.2.12&nbsp;violate the law in any way, or to violate the privacy of others, or to defame others.
              </p>
              
              <p class="ql-align-justify">
                10.3&nbsp;You shall use the Services only in the furtherance and for the purposes of activities that are
                legal in the applicable jurisdiction. You shall in using the Services comply with all applicable laws.
                The laws referred to in this clause include and are not limited to laws relating to junk mail, spam and
                electronic communications and transactions.
              </p>
              
              <p class="ql-align-justify">
                10.4&nbsp;Mind Master reserves the right to refuse to transmit or to remove any information or
                materials, in whole or in part, that in its sole discretion, Mind Master deems unacceptable,
                undesirable, inappropriate or in violation of any law or these Terms.
              </p>
              
              <p class="ql-align-justify">
                11.&nbsp;
                <strong>
                  Data Protection
                </strong>
              </p>
              
              <p class="ql-align-justify">
                11.1&nbsp;While Mind Master will take reasonable security arrangements to protect your materials and
                information imported into our software (“
                <strong>
                  Customer Data
                </strong>
                ”) to prevent unauthorized or accidental access, collection, use, disclosure, copying, modification,
                disposal or destruction of Customer Data, we cannot guarantee the security of any information you
                disclose online. You acknowledge by entering into this agreement that you are aware of our limitations
                with respect to security and privacy and that Mind Master will have no liability to you for any
                unauthorised access or use of any of your content, or any corruption, deletion, destruction or loss of
                any of your content.&nbsp;
              </p>
              
              <p class="ql-align-justify">
                11.2&nbsp;Your use of the Services is subject to our Privacy Policy, which you can refer to at
                &lt;https://sellnchill.com/privacy-policy&gt;. The Privacy Policy is incorporated into this Agreement by
                this reference.
              </p>
              
              <p class="ql-align-justify">
                <span style="color: rgb(0, 0, 0);">
                  12.&nbsp;
                </span>
                <strong style="color: rgb(0, 0, 0);">
                  Renewal and Termination
                </strong>
              </p>
              
              <p class="ql-align-justify">
                12.1&nbsp;Without prejudice to any of the terms herein, either Mind Master or you may terminate these
                Terms and your subscription as of the end of your then-current subscription term upon written notice to
                the other, on or prior to the date thirty (30) days preceding the end of such subscription term. Unless
                your subscription is so terminated, your subscription will renew for a subscription term equivalent in
                length to the then-expiring subscription term. Unless otherwise agreed in writing, the fees applicable
                to your subscription for any such subsequent subscription term shall be Mind Master’s standard fees for
                the service plan to which you have subscribed as of the time such subsequent subscription term
                commences.
              </p>
              
              <p class="ql-align-justify">
                12.2&nbsp;No refunds or credits for fees or payments will be provided to you if you elect to terminate
                your subscription prior to the end of your then-effective subscription term. Following the termination
                or cancellation of our subscription, Mind Master reserves the right to delete all your content in the
                normal course of operation. Your content cannot be recovered once your subscription is cancelled.&nbsp;
              </p>
              
              <p class="ql-align-justify">
                12.3&nbsp;If you terminate your subscription prior to the end of your then-effective subscription term,
                or Mind Master effects such termination or cancellation under these Terms, in addition to other amounts
                you may owe Mind Master, you must immediately pay any unpaid subscription charges associated with the
                remainder of such subscription term.
              </p>
              
              <p class="ql-align-justify">
                12.4&nbsp;Mind Master may, in its sole discretion, immediately terminate, discontinue or modify the
                Services or your use of the Services, permanently or temporarily,for any reason in its sole discretion,
                without notice at any time, including but not limited to any one or more of the following events
                occurring:-
              </p>
              
              <p class="ql-align-justify">
                12.4.1&nbsp;Mind Master has determined that you, your Agents and/or your end users have violated any of
                these Terms;
              </p>
              <p class="ql-align-justify">
                12.4.2&nbsp;you, your Agents and/or your end users have caused verbal or written abuse of any kind to
                any Mind Master employee, member or officer.
              </p>
              
              <p class="ql-align-justify">
                Mind Master will endeavour, but is not obliged, to give you advance notice prior to taking any of the
                foregoing actions detailed in this clause.&nbsp;Mind Master shall not be liable to you, your Agents, end
                users or any other third party for any such modification, suspension or discontinuation of your rights
                to access and use the Services. Any suspected fraudulent, abusive, or illegal activity by you, your
                Agents, or end users may be referred to law enforcement authorities at Mind Master’s sole discretion. We
                reserve the right to pursue legal action if we deem it necessary.
              </p>
              
              <p class="ql-align-justify">
                12.5&nbsp;Upon termination of these Terms:-
              </p>
              <p class="ql-align-justify">
                12.5.1&nbsp;you shall pay to Mind Master in full all fees, costs and expenses accrued and owing to Mind
                Master for the Services;
              </p>
              <p class="ql-align-justify">
                12.5.2&nbsp;your right and licence to use the Services shall immediately cease; and
              </p>
              <p class="ql-align-justify">
                12.5.3&nbsp;you shall thereafter have no right, and Mind Master shall have no obligation, to forward
                your content, data or information in your account to you, your End Users or any other third party. Mind
                Master shall have no responsibility or liability with respect to any loss of your data or content in any
                event.
              </p>
              
              <p class="ql-align-justify">
                12.6&nbsp;Termination is not an exclusive remedy and we reserve all other available remedies in
                contract, law or equity.
              </p>
              
              <p class="ql-align-justify">
                13.&nbsp;
                <strong>
                  Disclaimer of Warranties
                </strong>
              </p>
              
              <p class="ql-align-justify">
                13.1&nbsp;These provisions apply to the greatest extent permitted under applicable laws. The Services
                and our software are provided as-is, at your own risk, without express or implied warranty or condition
                of any kind. Mind Master specifically disclaims any and all warranties including, but not limited to:
                warranties concerning the availability, accuracy, security, usefulness, interoperability, or content of
                the Services; and any warranties of non-infringement, title, merchantability, or fitness for a
                particular purpose. This disclaimer applies to any damages or injury caused by the service, including
                without limitation as a result of any failure of performance, error, omission, interruption, deletion,
                defect, delay or error in operation or transmission, computer virus, communication line failure, theft
                or destruction or unauthorised access to, alteration of, or use of record.
              </p>
              
              <p class="ql-align-justify">
                13.2&nbsp;We are not responsible or liable for any harm to your computer system, loss or corruption of
                data or other harm that results from your access to or use of the Services and/or our software.
              </p>
              
              <p class="ql-align-justify">
                13.3&nbsp;We do not warrant that the results that may be obtained from the use of the Services will be
                accurate or reliable.
              </p>
              
              <p class="ql-align-justify">
                13.4&nbsp;We do not warrant that the quality of any products, services, information or other materials
                obtained by you through the Services will meet your expectations, or that any errors in the Services
                will be corrected.
              </p>
              
              <p class="ql-align-justify">
                13.5&nbsp;No advice or information, whether oral or written, obtained by you from Mind Master, shall
                create any warranty not expressly made herein. Mind Master assumes no responsibility for the deletion or
                failure to store, deliver or timely deliver any information.
              </p>
              
              <p class="ql-align-justify">
                14.&nbsp;
                <strong>
                  Exclusions and Limitations of Liability
                </strong>
              </p>
              
              <p class="ql-align-justify">
                14.1&nbsp;You expressly understand and agree that Mind Master, its officers, directors, employees,
                advisors, affiliates, agents, successors or assigns, shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages, including but not limited to damages for loss
                of profits, goodwill, use, data or other intangible losses (even if Mind Master has been advised of the
                possibility of such damages), resulting from:-
              </p>
              
              <p class="ql-align-justify">
                14.1.1&nbsp;The use or the inability to use the Services;
              </p>
              <p class="ql-align-justify">
                14.1.2&nbsp;Any claim attributable to errors, omissions and/or other inaccuracies in, or destructive
                properties of the Services, or any other software of other content;
              </p>
              <p class="ql-align-justify">
                14.1.3&nbsp;The cost of procurement of substitute goods and services resulting from any goods, data,
                information or services purchased or obtained or messages received, or transactions entered through or
                from the Services;
              </p>
              <p class="ql-align-justify">
                14.1.4&nbsp;Unauthorized access to or alteration of your transmissions or data;
              </p>
              <p class="ql-align-justify">
                14.1.5&nbsp;Statements or conduct of any third party on the Services; or
              </p>
              <p class="ql-align-justify">
                14.1.6&nbsp;Any other matter relating to the Services.
              </p>
              
              <p class="ql-align-justify">
                14.2&nbsp;You indemnify and hold us harmless from and against all claims, damages, expenses or losses
                that we may incur or suffer arising out of or in connection with your breach of these Terms.
              </p>
              
              <p class="ql-align-justify">
                15.&nbsp;
                <strong>
                  Modifications
                </strong>
              </p>
              
              <p class="ql-align-justify">
                Mind Master may revise these Terms from time to time. By continuing to access or use the Services after
                revisions become effective, you agree to be bound by the revised Terms.
              </p>
              
              <p class="ql-align-justify">
                16.&nbsp;
                <strong>
                  No Resale of Services
                </strong>
              </p>
              
              <p class="ql-align-justify">
                You agree not to reproduce, transfer, duplicate, copy, sell, resell or exploit for any commercial
                purposes, any portion of the Services, use of the Services, or access to the Services.
              </p>
              
              <p class="ql-align-justify">
                17.&nbsp;
                <strong>
                  Confidentiality
                </strong>
              </p>
              
              <p class="ql-align-justify">
                17.1&nbsp;Each party shall not, without the other party’s prior written approval, disclose or use any
                proprietary or confidential information received from the other party pursuant to these Terms.
              </p>
              
              <p class="ql-align-justify">
                17.2&nbsp;The confidentiality obligations in this clause shall not apply to any information which:
              </p>
              <p class="ql-align-justify">
                17.2.1&nbsp;becomes generally known to the public, other than by reason of any wilful or negligent act
                or omission of the receiving party;
              </p>
              <p class="ql-align-justify">
                17.2.2&nbsp;is at the time of disclosure, legally in the possession of the receiving party without any
                obligation of confidentiality; or
              </p>
              <p class="ql-align-justify">
                17.2.3&nbsp;is required to be disclosed pursuant to any applicable court, governmental orders or other
                legal requirements.
              </p>
              
              <p class="ql-align-justify">
                17.3&nbsp;The recipient of any proprietary or confidential information shall, upon the other party’s
                request or the termination of these Terms, return all such information within three (3) days of such
                request.
              </p>
              
              <p class="ql-align-justify">
                18.&nbsp;
                <strong>
                  Force Majeure
                </strong>
              </p>
              
              <p class="ql-align-justify">
                18.1&nbsp;A “
                <strong>
                  Force Majeure Event
                </strong>
                ” means any event outside the reasonable control of either party affecting its ability to perform any of
                its obligations (other than payment) under these Terms, including but not limited to, any breakdown or
                malfunction of hardware, software, networks or systems used in connection with the Services, acts of
                God, war, hostility, invasion, act of foreign enemies, rebellion, revolution, riots, civil war,
                disturbances, requisitioning or other acts of civil or military authority, laws, regulations, acts or
                orders of any governmental authority, body, agency or official, fires, inclement weather, rain or floods
                (however caused), strikes, lock-outs or other labour disputes, epidemics, outbreaks, embargoes or other
                catastrophes.
              </p>
              
              <p class="ql-align-justify">
                18.2&nbsp;Mind Master shall not be liable to you as a result of any delay or failure to perform our
                obligations under these Terms as a result of a Force Majeure Event.
              </p>
              
              <p class="ql-align-justify">
                18.3&nbsp;If the Force Majeure Event prevents us from providing any of the Services to you for more than
                sixty (60) days, we shall, without limiting our other rights or remedies, be entitled to suspend or
                terminate the Services immediately by giving written notice to you.
              </p>
              
              <p class="ql-align-justify">
                19.&nbsp;
                <strong>
                  Governing Law
                </strong>
              </p>
              
              <p class="ql-align-justify">
                This agreement shall be governed by, and construed in accordance with, the laws of Singapore and the
                parties hereby irrevocably submit to the exclusive jurisdiction of the courts of Singapore and waive any
                objection to proceedings in any such court on the grounds of venue or on the grounds that the
                proceedings have been brought in an inconvenient forum.&nbsp;The taking of proceedings in the court of
                Singapore shall not preclude any Party from taking enforcement proceedings against the other Party in
                any other jurisdiction.
              </p>
              
              <p class="ql-align-justify">
                20.&nbsp;
                <strong>
                  Assignment and Third-Party Rights
                </strong>
              </p>
              
              <p class="ql-align-justify">
                20.1&nbsp;You shall not assign or otherwise transfer any of its rights, benefits or obligations
                hereunder in whole or in part without the prior written consent of Mind Master.
              </p>
              
              <p class="ql-align-justify">
                20.2&nbsp;A person who is not a party to these Terms has no right under the Contracts (Rights of Third
                Parties) Act (Cap 53B) to enforce any of these Terms.
              </p>
              
              <p class="ql-align-justify">
                21.&nbsp;
                <strong>
                  General
                </strong>
              </p>
              
              <p class="ql-align-justify">
                21.1&nbsp;These Terms, the Privacy Policy and the Quotation constitute the entire agreement, and
                supersede any and all prior agreements or understandings between you and Mind Master with regard to the
                subject matter hereof. These Terms, Privacy Policy and Quotation shall apply in lieu of the terms or
                conditions in any purchase other or other order documentation you or any Entity which you represent
                provide (all such terms or conditions shall be null and void), and, except as expressly stated herein,
                there are no other agreements, representations, warranties or commitments which may be relied upon by
                either party with respect to the subject matter hereof.
              </p>
              
              <p class="ql-align-justify">
                21.2&nbsp;Notwithstanding the foregoing, additional terms may apply to certain features of the Services
                (the “
                <strong>
                  additional terms
                </strong>
                ”). The additional terms will be considered incorporated into these Terms when you activate the feature.
                If there is any conflict between these Terms and the additional terms, the additional terms shall
                prevail to the extent of such inconsistency and conflict.
              </p>
              
              <p class="ql-align-justify">
                21.3&nbsp;Mind Master reserves the right to update and change these Terms by posting updates and changes
                to the Website, in which case the new terms will supersede prior versions. You are advised to check the
                Terms from time to time for any updates or changes that may impact you, and if you do not accept such
                amendments, you must cease using the Services. Your continued use of the Services following the
                effective date of such update(s) and/or change(s) may be relied upon by Mind Master as your consent to
                any such amendment.
              </p>
              
              <p class="ql-align-justify">
                21.4&nbsp;The waiver by either party of any breach of these Terms shall not prevent the subsequent
                enforcement of that provision and shall not be deemed to be a waiver of any subsequent breach of that or
                any other provision.
              </p>
              
              <p class="ql-align-justify">
                21.5&nbsp;If at any time any part of these Terms is held to be or becomes void or otherwise
                unenforceable for any reason under any applicable law, the same shall be deemed omitted from these Terms
                and the validity and/or enforceability of the remaining provisions of these Terms shall not in any way
                be affected or impaired as a result of that omission.
              </p>
              
              <p class="ql-align-justify">
                21.6&nbsp;While we will make every available effort to keep the Services operational at all times, there
                will be periods of downtime for maintenance and upgrades, and sometimes, for reasons that we did not
                plan. We will attempt to provide at least 12 hours of notice for scheduled maintenance or downtime, but
                some downtime may be unscheduled and beyond our control.
              </p>
              
              <p class="ql-align-justify">
                21.7&nbsp;Any notice or other communication required to be given to a party under or in connection with
                these Terms shall be in writing. Notice by email is deemed to be in writing.
              </p>
              
              
              <p class="ql-align-justify">
                Last Updated: 17 September 2021&nbsp;
              </p>
              <p>
              </p>
            </div>
          </div>
        </div>
      </div>


    </section>





    <!-- ======= Footer ======= -->

    <footer class="footer" id="footer" style="padding: 0px;">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12  col-sm-12   footer-info">
              <a class="logo d-flex align-items-center scrollto" href="#hero">
                <img alt="" style="height:70px; width:auto;margin-left: 26px;" src="assets/img/logoWhite.png"
                  class="md mx-auto d-block" /> </a>



              <div class="social-links mt-3"></div>
            </div>

            <div class="col-lg-4 col-12  col-sm-12   footer-links">
              <h4>Useful Links</h4>

              <ul class="footer-menu">
                <li> <a class="nav-link scrollto" (click)="onClick('hero')">Home</a></li>
                <li><a href="https://mindmaster.com.sg/" target="_blank">About us</a></li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a>
                </li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
                <li class="nav-item"><a class="nav-link scrollto" routerLink="/privacy-policy">Privacy Policy</a></li>
                <li class="nav-item"><a class="nav-link scrollto" routerLink="/terms-of-service">Terms of Service</a></li>
                <li class="nav-item"><a class="nav-link" (click)="onClick('LoginFormIconContent')">Login</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12   footer-contact ">
              <h4>Contact Us</h4>

              <p>Foo Wah Industrial Building<br /> 45 Jln Pemimpin,<br /> Singapore 577197<br />
                <br />
                <span class="Green">+65 6250 5623</span><br /> support@sellnchill.com
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">&copy; Copyright <strong><span>Sell & Chill</span></strong>. All Rights Reserved</div>

      </div>
    </footer>
  </div>
  <!-- ======= End Footer ======= -->


</body>

</html>