import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // Logged in so return true
      return true;
    }
    //Enable else while running in local Don't push changes in this file`
    // else {
    //   localStorage.setItem('currentUser', JSON.stringify({"userProfile":{"webUserID":4,"companyID":1,"userTypeID":2,"firstName":"Developer","lastName":"Account","userName":"Developer","secretKey":"*****","countryCode":"91","handPhone":"9502230911","email":"sriram@mindmaster.com.sg","profileImage":null,"lastAccessedOn":null,"createdBy":null,"createdOn":"2020-06-26T00:55:40.533","status":true},"companyDetails":{"companyID":1,"company1":"Orient Photo Pte Ltd","uen":"200701551G","address1":"1 Rochor Canal Road #06-02A","address2":"Sim Lim Square","country":"Singapore","postalCode":"188504","phone":"+6563391035","email":"sales@orientfoto.com.sg","logo":"http://localhost:62994/Files/Company//orient.png","isBatchBarcode":true,"isAddOns":true,"isStockUpdate":true,"status":true}}))
    //   return true;
    // }
    // Not logged in so redirect to login page with the return url
    window.location.href = '/';
    return false;
  }
}
