import { Component, OnInit, Inject, Renderer2, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
import * as CryptoTS from 'crypto-ts';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  ResetForm: FormGroup;
  loading = false;
  submitted = false;
  onSuccess = false;
  userId = '';
  timeStampId ='';
  userName='';
  key: string = 'b14ca5898a4e4133bbce2ea2315a1916';
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2) {
      this.route.queryParams.subscribe(params => {
        if(params['user'] && params['expiry']) {
          this.userId = params['user'];
          this.timeStampId =  params['expiry'];
        }
      });
    }

  ngOnInit(): void {
    this.ResetForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    var resolution;
    var currenTimeStamp = new Date().getTime();
    var EndTime:any = (this.timeStampId && (typeof this.timeStampId === 'string')) ? JSON.parse(this.timeStampId): '';
    resolution = currenTimeStamp - EndTime;
    var resolutionTime = (((resolution / 1000) / 60));
    if(resolutionTime>30){
      this.onSuccess = undefined;
      Swal.fire({
        title: 'Reset Password',
        text: 'URL was expired please try again',
        icon: 'error',
      });
    }
    var key = CryptoJS.enc.Utf8.parse('b14ca5898a4e4133bbce2ea2315a1916');
    var encryptedUser='';
    const bytes = CryptoJS.AES.decrypt(this.userId, key, {
      keySize: 256 / 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    if (bytes.toString()) {
      this.userName = bytes.toString(CryptoJS.enc.Utf8);
    }else {
      this.onSuccess = undefined;
      Swal.fire({
        title: 'Reset Password',
        text: 'Something is wrong, Please contact admin',
        icon: 'error',
      });

    }

      console.log('dec', encryptedUser);
  }

  get r() {
    return this.ResetForm.controls;
  }

  tryReset() {
    this.submitted = true;
    if (this.ResetForm.invalid) {
      return;
    }
    if (this.r.password.value != this.r.confirmPassword.value) {
      Swal.fire({
        title: 'Reset Password',
        text: 'New Password and Confirm Password is not matching',
        icon: 'warning',
      })
      return;
    }
    this.loading = true;
    this.authService.resetPassword({
      "userName": this.userName,
      "secretKey": '',
      "newSecretKey": this.r.confirmPassword.value
    }).subscribe(
      (res:any) => {
        if(res) {
          this.onSuccess = true;
          Swal.fire({
            title: 'Reset Password',
            text: 'Reset Password Successfully',
            icon: 'sucess',
          })
        }else {
          this.onSuccess = false;
          Swal.fire({
            title: 'Reset Password',
            text: res.message,
            icon: 'error',
          })
        }
      },
      err => {
        this.submitted = false;
        this.loading = false;
        this.onSuccess = false;
        //this.alertService.error(err.error.error);

        let error_description = err.error.exceptionMessage;
        Swal.fire({
          title: 'Forgot Password',
          text: error_description,
          icon: 'error',
        })

      }
    );
  }

}
