import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
import { LandingComponent } from './landing/landing.component';

import { DeliveryOrdersCreationComponent } from './content/delivery-orders/delivery-orders-creation/delivery-orders-creation.component';
import { SignupComponent } from './signup';
import { AmazonexpoComponent } from './amazonexpo';
import { ShopeeworkshopComponent } from './shopeeworkshop';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const appRoutes: Routes = [
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-service', component: TermsConditionComponent },
  { path: 'landing', component: LandingComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'amazonexpo', component: AmazonexpoComponent },
      { path: 'shopeeworkshop', component: ShopeeworkshopComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'reset', component: ResetPasswordComponent },
      { path: '', component: LandingComponent }

    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'error', loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'authentication', loadChildren: () => import('../app/content/full-pages/authentication/authentication.module').then(m =>
          m.AuthenticationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: () => import('../app/content/full-pages/others/others.module').then(m => m.OthersModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      {
        path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'components', loadChildren: () => import('../app/content/ngbbootstrap/components.module').then(m => m.ComponentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'suppliers', loadChildren: () => import('../app/content/suppliers/suppliers.module').then(m => m.SuppliersModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'storage-point', loadChildren: () => import('../app/content/storage-point/storage-point.module').then(m => m.StoragePointModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'storage-location', loadChildren: () => import('../app/content/storage-location/storage-location.module').then(m => m.StorageLocationModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'stock-in', loadChildren: () => import('../app/content/stock-in/stock-in.module').then(m => m.StockInModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'stocks', loadChildren: () => import('../app/content/stocks/stocks.module').then(m => m.StocksModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'todo-app', loadChildren: () => import('../app/content/applications/todo-app/todo-app.module').then(m => m.TodoAppModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'orders', loadChildren: () => import('../app/content/orders/orders.module').then(m => m.OrderModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'lazada', loadChildren: () => import('../app/content/lazada/lazada.module').then(m => m.LazadaModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'amazon', loadChildren: () => import('../app/content/amazon/amazon.module').then(m => m.AmazonModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'tiktok', loadChildren: () => import('../app/content/tiktok/tiktok.module').then(m => m.TiktokModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'sellers', loadChildren: () => import('../app/content/sellers/sellers.module').then(m => m.SellersModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'platform', loadChildren: () => import('../app/content/platform-onboard/onboard.module').then(m => m.OnboardModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'integrations', loadChildren: () => import('../app/content/integrations/integrations.module').then(m => m.IntegrationsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'shopee', loadChildren: () => import('../app/content/shopee/shopee.module').then(m => m.ShopeeModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'qoo10', loadChildren: () => import('../app/content/qoo10/qoo10.module').then(m => m.Qoo10Module)
        , canActivate: [AuthGuard]
      },
      {
        path: 'shopify', loadChildren: () => import('../app/content/shopify/shopify.module').then(m => m.ShopifyModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'price-management', loadChildren: () => import('../app/content/price-management/price-management.module').then(m => m.PriceManagementModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'merged-sku', loadChildren: () => import('../app/content/merged-sku/merged-sku.module').then(m => m.MergedSkuModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'PO', loadChildren: () => import('../app/content/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'DO', loadChildren: () => import('../app/content/delivery-orders/delivery-orders.module').then(m => m.DeliveryOrdersModule)
        , canActivate: [AuthGuard]
      },
      
      {
        path: 'company', loadChildren: () => import('../app/content/company/company.module').then(m => m.CompanyModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'opening-stock', loadChildren: () => import('../app/content/opening-stock/opening-stock.module').then(m => m.OpeningStockModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'opening-warehouse-stock', loadChildren: () => import('../app/content/opening-warehouse-stock/opening-warehouse-stock.module').then(m => m.OpeningWareHouseStockModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'users', loadChildren: () => import('../app/content/user/user.module').then(m => m.UserModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'catalog', loadChildren: () => import('../app/content/catalogs/catalog.module').then(m => m.CatalogModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'scheduler', loadChildren: () => import('../app/content/scheduler/scheduled-jobs/scheduled-jobs.module').then(m => m.ScheduledJobsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'scheduler', loadChildren: () => import('../app/content/scheduler/runs/runs.module').then(m => m.RunsModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'unleashed', loadChildren: () => import('../app/content/unleashed/unleashed.module').then(m => m.UnleashedModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'autocount', loadChildren: () => import('../app/content/autocount/autocount.module').then(m => m.AutocountModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'reports/inventory', loadChildren: () => import('../app/content/reports/inventory/inventory.module').then(m => m.InventoryModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'reports/products', loadChildren: () => import('../app/content/reports/products/products.module').then(m => m.ProductsModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'reports/earnings', loadChildren: () => import('../app/content/reports/earnings/earnings.module').then(m => m.EarningsModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'reports/dailysales', loadChildren: () => import('../app/content/reports/dailysales/dailysales.module').then(m => m.DailysalesModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'reports/feenamemapping', loadChildren: () => import('../app/content/reports/feenamemapping/feenamemapping.module').then(m => m.FeenamemappingModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'woocommerce', loadChildren: () => import('../app/content/woocommerce/woocommerce.module').then(m => m.WooCommerceModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'vend', loadChildren: () => import('../app/content/vend/vend.module').then(m => m.VendModule)
        , canActivate: [AuthGuard]
      },


      {
        path: 'swimlines', loadChildren: () => import('../app/content/swimlines/swimlines.module').then(m => m.SwimlinesModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'bundles', loadChildren: () => import('../app/content/bundles/bundles.module').then(m => m.BundlesModule), canActivate: [AuthGuard]
      },
      {
        path: 'products', loadChildren: () => import('../app/content/products/products.module').then(m => m.ProductsModule)
        , canActivate: [AuthGuard]

      },

      {
        path: 'offline/stores', loadChildren: () => import('../app/content/offline/stores/store.module').then(m => m.StoresModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'insights', loadChildren: () => import('../app/content/insights/insights.module').then(m => m.InsightsModule)
        , canActivate: [AuthGuard]
      },


      {
        path: 'warehouse', loadChildren: () => import('../app/content/warehouse/warehouse.module').then(m => m.WarehouseModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'dear', loadChildren: () => import('../app/content/dear/dear.module').then(m => m.DearModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'euroStop', loadChildren: () => import('../app/content/euroStop/euroStop.module').then(m => m.EuroStopModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'quickBook', loadChildren: () => import('../app/content/quickBook/quickBook.module').then(m => m.QuickBookModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'campaigns', loadChildren: () => import('../app/content/campaigns/campaigns.module').then(m => m.CampaignsModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'itemsidentifications', loadChildren: () => import('../app/content/items-identification/items-identification.module').then(m => m.ItemsIdentificationModule)
        , canActivate: [AuthGuard]
      },


      {
        path: 'xero', loadChildren: () => import('../app/content/xero/xero.module').then(m => m.XeroModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'reports/financial', loadChildren: () => import('../app/content/reports/financial/financial.module').then(m => m.FinancialModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'b2b', loadChildren: () => import('../app/content/b2b/b2b.module').then(m => m.B2BModule)
        , canActivate: [AuthGuard]
      },
      
      {
        path: 'cin7', loadChildren: () => import('../app/content/cin7/cin7.module').then(m => m.Cin7Module)
        , canActivate: [AuthGuard]
      },

      {
        path: 'businesscentral', loadChildren: () => import('../app/content/businesscentral/businesscentral.module').then(m => m.BusinesscentralModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'pickpackermapping', loadChildren: () => import('../app/content/mapping-pickpack/mappingpickpack.module').then(m => m.MappingPickPackModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'chats', loadChildren: () => import('../app/content/applications/chat/chats.module').then(m => m.ChatsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'email', loadChildren: () => import('../app/content/applications/email/email.module').then(m => m.EmailModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'calender', loadChildren: () => import('../app/content/applications/calender/calender.module').then(m => m.CalenderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts', loadChildren: () => import('../app/content/applications/contacts/contacts.module').then(m => m.ContactsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'treeview', loadChildren: () => import('../app/content/extra-components/treeview/treeview.module').then(m => m.TreeViewModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'chartjs', loadChildren: () => import('../app/content/charts-maps/chartjs/chartjs.module').then(m => m.ChartjsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'form-elements', loadChildren: () => import('../app/content/forms/form-elements/form-elements.module').then(m =>
          m.FormElementsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-layouts', loadChildren: () => import('../app/content/forms/form-layouts/form-layouts.module').then(m =>
          m.FormLayoutsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-wizard', loadChildren: () => import('../app/content/forms/form-wizard/form-wizard.module').then(m =>
          m.FormWizardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'form-repeater', loadChildren: () => import('../app/content/forms/form-repeater/form-repeater.module').then(m =>
          m.FormRepeaterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'ngchartist', loadChildren: () => import('../app/content/charts-maps/ngchartist/ngchartist.module').then(m =>
          m.NgchartistModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'boostraptables', loadChildren: () => import('../app/content/table/boostraptables/boostraptables.module').then(m =>
          m.BoostraptablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatables', loadChildren: () => import('../app/content/table/datatables/datatables.module').then(m => m.DatatablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatablesext', loadChildren: () => import('../app/content/table/datatablesext/datatablesext.module').then(m =>
          m.DatatablesextModule),
        canActivate: [AuthGuard]
      },
      { path: 'icons', loadChildren: () => import('../app/content/icons/icons.module').then(m => m.IconsModule), canActivate: [AuthGuard] },
      { path: 'cards', loadChildren: () => import('../app/content/cards/cards.module').then(m => m.CardsModule), canActivate: [AuthGuard] },
      { path: 'maps', loadChildren: () => import('../app/content/maps/maps.module').then(m => m.MapsModule), canActivate: [AuthGuard] },
      {
        path: 'invoice', loadChildren: () => import('../app/content/pages/invoice/invoice.module').then(m => m.InvoiceModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'timelines', loadChildren: () => import('../app/content/pages/timelines/timelines.module').then(m => m.TimelinesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user', loadChildren: () => import('../app/content/pages/user/user.module').then(m => m.UserModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'gallery', loadChildren: () => import('../app/content/pages/gallery/gallery.module').then(m => m.GalleryModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'news-feed', loadChildren: () => import('../app/content/pages/news-feed/news-feed.module').then(m => m.NewsFeedModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'dropzone', loadChildren: () => import('../app/content/pages/dropzone/dropzone.module').then(m => m.DropzoneModule)
        , canActivate: [AuthGuard]
      },
      // FILE UPLOAD - 01-04-2021
      {
        path: 'fileupload', loadChildren: () => import('../app/content/fileupload/fileupload.module').then(m => m.FileuploadModule)
        , canActivate: [AuthGuard]
      },

      {
        path: 'social-feed', loadChildren: () => import('../app/content/pages/social-feed/social-feed.module').then(m =>
          m.SocialFeedModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'search', loadChildren: () => import('../app/content/pages/search/search.module').then(m => m.SearchModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'advanceCards', loadChildren: () => import('../app/content/advance-cards/advance-cards.module').then(m =>
          m.AdvanceCardsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'extraComponents', loadChildren: () => import('../app/content/extra-components/extra-components.module').then(m =>
          m.ExtraComponentsModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
