import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export class HttpInterceptorService implements HttpInterceptor {
  private readonly exclusions: Array<string> = [
    "assets",
    "e-assets",
    "favicon",
    "Token",
    "images"
];
  constructor() {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let addHeaders: boolean = true;
    this.exclusions.forEach(exclusion => {
      if (request.url.includes(exclusion)) {
        addHeaders = false;
      }
    });

    if (addHeaders) {
        request = request.clone({headers: request.headers.set('Authorization', 'bearer '+this.getCookie('MPOWER-Token'))});
    }
    if (request.url.includes('Token')) {
        request = request.clone({headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded')})
        request = request.clone({headers: request.headers.set('No-Auth', 'True')})
    }
    request = request.clone({headers: request.headers.set('Access-Control-Allow-Origin', '*')})
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error && error.status) {
            if (error.status === 401 || error.status === 2002) {
                // alert('Login expires');
                // localStorage.removeItem('currentUser');
                // localStorage.removeItem('remember');
                // localStorage.removeItem('userID');
                // window.location.href = '/';
                Swal.fire({
                  title: 'Login Expired!',
                  text: 'Please Login again',
                  icon: "warning"
                }).then((willDelete) => {
                  localStorage.removeItem('currentUser');
                  localStorage.removeItem('remember');
                  localStorage.removeItem('userID');
                  window.location.href = '/';
                });
              // Show no automatic flash message for 404 code and 2002 -- which is our own mapping of 404
            } else {
              //
            }
          }
          return throwError(error);
        })
      );
  }

  private getCookie(name: string): string {
    const match: any = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }
}
