import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
 // public apiBaseURL = GlobalConstants.apiURL;
 apiBaseURL = 'https://mindmaster.com.sg/sellnchill-signup';
 //apiBaseURL = 'http://localhost/sellnchill-signup';
 constructor(private http : HttpClient, private router:Router) { }

  doSignup(obj){
    return this.http.post(`${this.apiBaseURL}/Signup.php`, obj);
  }

  doContact(obj){
    return this.http.post(`${this.apiBaseURL}/Contact.php`, obj);
  }

}
