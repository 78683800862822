<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i%7CQuicksand:300,400,500,700"
    rel="stylesheet">


</head>

<body style="height: fit-content !important;
    padding-top: 0rem !important;">
  <header class="navigation-top top-zero" id="header">
    <div class="container">
      <nav class="navbar" id="navbar-example2">
        <a class="navbar-brand scrollto" (click)="onClick('hero')">
          <img class="img-fluid" src="assets/img/logo.svg" style="height:70px; width:auto"> </a>
        <div class="inner-nav">
          <p class="close-menu"></p>

          <ul class="nav nav-pills">
            <a class="mobile-menu-logo scrollto" (click)="onClick('hero')"><img src="assets/img/logo.svg" /> </a>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('contact')">Contact</a></li>
            <li class="nav-item"><a class="nav-link scrollto" routerLink="/signup">Get Started</a></li>

            <li class="nav-item" ngbDropdown>
              <a type="button" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag"
                style="background:none;">
                <span style="font-size:16px">Events</span>
              </a>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                <a class="dropdown-item" routerLink="/amazonexpo">Amazon Expo Boot Camp</a>
                <a class="dropdown-item" routerLink="/shopeeworkshop">Shopee University workshop</a>
              </div>
            </li>
            <li class="nav-item button-menu"><a class="getstarted scrollto"
                (click)="onClick('LoginFormIconContent')">Login</a></li>
          </ul>
        </div>
        <a class="nav-bar"><i class="bi bi-list">&nbsp;</i></a>
      </nav>
    </div>
  </header>
  <!-- ======= End Header ======= -->







  <!-- ======= Hero Section ======= -->
  <div class="row pt-10" style="margin-top:18px;background: #fff;"></div>
  <section class="jumbotron jumbotron-fluid contact pt-10" id="contact"
    [ngClass]="blockLoader ? 'save-loader-opacity' : ''">
    <div class="table-loader" *ngIf="blockLoader"></div>

    <div class="container aos-init aos-animate" data-aos="fade-up">


      <div class="row ">
        <div class="col-lg-6">
          <div class="row text-center">
            <div class="col-md-12 pt-5">
              
            </div>

            <div class="col-md-12 pt-1">
              <img src="../../assets/img/shopeeworkshop.png" class="img-fluid" />
            </div>


            <div class="col-md-12 pt-3 text-left">
              <p style="color:#3fad5a; font-weight:800;font-size: 20px;text-align: left; line-height: 35px;">Featuring
                Special Guests from Sell&Chill
              </p>

              <p>Sell & Chill helps businesses sell easily everywhere. They automate & simplify multichannel selling by
                aggregating all sales channels into 1 platform.
              </p>
              <p>Sell & Chill is a SaaS product of Mind Master - a leading technology company founded and headquartered
                in Singapore</p>
            </div>

          </div>
        </div>

        <div class="col-lg-6 pt-1">
          <form class="php-email-form pt-5" style="background-color:#fff;" [formGroup]="shopeeworkshopForm"
            (ngSubmit)="tryShopeeworkshop()">

            <div class="row gy-4 pt-2">
              <!-- <div class="col-md-12">
                <p style="color:#fff; font-weight:800;font-size: x-large; line-height: 35px;">If you are interested to
                  learn more about and apply for this grant, please fill in the form below:</p>
              </div> -->
              <div class="col-md-6">

                <input type="text" formControlName="name" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="name" placeholder="Your Name *">

                <!-- <small *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <span *ngIf="f.name.errors.required">Name is required</span>
                </small> -->

              </div>
              <div class="col-md-6 ">
                <input type="text" formControlName="company" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.company.errors }" id="company" placeholder="Your Company">

                <!-- <small *ngIf="submitted && f.company.errors" class="invalid-feedback">
                  <span *ngIf="f.company.errors.required">Company is required</span>
                </small> -->

              </div>
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12">
                    <div style="padding-right:1%;width: 19%;float:left;">
                      <select class="form-control" formControlName='countrycode' style="height: 43px !important">
                        <option value="65">+65</option>
                        <option value="63">+63</option>
                        <option value="60">+60</option>
                      </select>

                      <!-- <small *ngIf="submitted && f.number.errors" class="invalid-feedback">
                    <span *ngIf="f.number.errors.required">Number is required</span>
                  </small> -->
                    </div>


                    <div style="width:80%;float:left">
                      <input type="tel" formControlName="number" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.number.errors }" id="number"
                        placeholder="Your Number">

                      <!-- <small *ngIf="submitted && f.number.errors" class="invalid-feedback">
                  <span *ngIf="f.number.errors.required">Number is required</span>
                </small> -->
                    </div>

                  </div>


                </div>


              </div>
              <div class="col-md-6 ">
                <input type="email" formControlName="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" email placeholder="Your Email *">

                <!-- <small *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <span *ngIf="f.email.errors.required">Email is required</span>
              </small> -->

              </div>


              <div class="col-md-2"></div>


              <div class="col-md-8 ">
                <label class="text-black">Enter your promo code below:</label>
                <input type="text" formControlName="promocode" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.promocode.errors }" id="promocode" placeholder="Enter Code">

                <!-- <small *ngIf="submitted && f.promocode.errors" class="invalid-feedback">
                  <span *ngIf="f.promocode.errors.required">Promo code is required</span>
                </small> -->
              </div>

              <div class="col-md-2"></div>

              <div class="col-md-1 col-sm-2" style="max-width: 20px;margin-top: 5px;">
                <input class="form-check-input" type="checkbox" formControlName='agreeTerms' id="flexCheckDefault"
                  style="height: auto;" [ngClass]="{ 'is-invalid': submitted && f.agreeTerms.errors }">

              </div>
              <div class="col-md-11 col-sm-8">
                <label class="form-check-label" for="flexCheckDefault" style="font-size:12px;"> By
                  submitting this form, I hereby warrant that all the information I have submitted in this form is true
                  and accurate to the best of my knowledge. I expressly consent for any personal data I have provided to
                  be processed and retained in accordance with our <a routerLink="/privacy-policy" target="_new"
                    class="text-black">Privacy Policy</a> </label>

              </div>

              <div class="col-md-12 text-center pt-3">
                <!--div class="loading">Loading</div>
                                          <div class="error-message"></div>
                                          <div class="sent-message">Your message has been sent. Thank you!</div-->
                <button type="submit" style="background-color:#3fad5a; color:#fff;">Claim 2 months Free Trial
                  now!</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>





  <!-- ======= Footer ======= -->

  <footer class="footer" id="footer" style="padding: 0px;">
    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12  col-sm-12   footer-info">
            <a class="logo d-flex align-items-center scrollto" href="#hero">
              <img alt="" style="height:70px; width:auto;margin-left: 26px;" src="assets/img/logoWhite.png"
                class="md mx-auto d-block" /> </a>



            <div class="social-links mt-3"></div>
          </div>

          <div class="col-lg-4 col-12  col-sm-12   footer-links">
            <h4>Useful Links</h4>

            <ul class="footer-menu">
              <li> <a class="nav-link scrollto" (click)="onClick('hero')">Home</a></li>
              <li><a href="https://mindmaster.com.sg/" target="_blank">About us</a></li>
              <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
              <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a>
              </li>
              <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
              <li class="nav-item"><a class="nav-link scrollto" routerLink="/privacy-policy">Privacy Policy</a></li>
              <li class="nav-item"><a class="nav-link scrollto" routerLink="/terms-of-service">Terms of Service</a></li>
              <li class="nav-item"><a class="nav-link" (click)="onClick('LoginFormIconContent')">Login</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 col-sm-12   footer-contact ">
            <h4>Contact Us</h4>

            <p>Foo Wah Industrial Building<br /> 45 Jln Pemimpin,<br /> Singapore 577197<br />
              <br />
              <span class="Green">+65 6250 5623</span><br /> support@sellnchill.com
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">&copy; Copyright <strong><span>Sell & Chill</span></strong>. All Rights Reserved</div>

    </div>
  </footer>
  <!-- ======= End Footer ======= -->


</body>

</html>