import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class BundleProductsService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getBundleProducts(obj) {
    //return this.http.get(`${this.apiBaseURL}/BundleProducts/GetBundleProducts?companyID=${companyID }`);
    return this.http.post(`${this.apiBaseURL}/BundleProducts/GetBundleProducts`, obj);
  }

  getBundleProductsWithUOM(obj) {
    return this.http.post(`${this.apiBaseURL}/BundleProducts/GetBundleProductsWithUOM`, obj);
  }

  getBundles(reqObj) {
    return this.http.post(`${this.apiBaseURL}/BundleProducts/GetBundles`, reqObj);
  }

  createBundle(reqObj) {
    return this.http.post(`${this.apiBaseURL}/BundleProducts/CreateBundleProducts`, reqObj);
  }

  updateBundle(reqObj) {
    return this.http.post(`${this.apiBaseURL}/BundleProducts/EditBundleProducts`, reqObj);
  }
  
  deleteBundleProduct(reqObj){
    return this.http.post(`${this.apiBaseURL}/BundleProducts/DeleteBundleProduct`, reqObj);
  }

}
