import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class SchedulerService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) {}
  
  getScheduledJobs(CompanyID){
    return this.http.get(`${this.apiBaseURL}/Scheduler/GetScheduledJobs?CompanyID=${CompanyID}`);
  }
  getRuns(CompanyID){
    return this.http.get(`${this.apiBaseURL}/Scheduler/GetRuns?CompanyID=${CompanyID}`);
  }

  ScheduleJob(data){
    return this.http.post(`${this.apiBaseURL}/Scheduler/ScheduleJob`, data);
  }
}

    //FOR SCHEDULED JOBS - 14-04-2021
    export const schedulerData = {
     operationTypes: [
      { value: 'Pull', label: 'Pull' },
      { value: 'Push Orders', label: 'Push Orders' },
      { value: 'Push Payments', label: 'Push Payments' },
    ]


}
