import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      this.router.navigate(['/insights']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    // const value = {
    //   grant_type: 'password',
    //   UserName: this.f.email.value,
    //   Password: this.f.password.value
    // };
    const value = "UserName=" + this.f.email.value + "&Password=" + this.f.password.value + "&grant_type=password";
    this.authService.doLogin(value).subscribe(
      res => {
        if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
          localStorage.setItem('remember', 'true');
        } else {
          localStorage.removeItem('remember');
        }
        this.setUserInStorage(res);
      },
      err => {
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let error_description = err.error?.error_description;
        Swal.fire({
          title: 'Login failed',
          text: error_description,
          icon: 'error',
        })

      }
    );
  }
addCheckbox(event) {
  const toggle = document.getElementById('icheckbox');
  if (event.currentTarget.className === 'icheckbox_square-blue') {
     this.renderer.addClass(toggle, 'checked');
  } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
    this.renderer.removeClass(toggle, 'checked');
  }
}
  setUserInStorage(res) {
    document.cookie = `MPOWER-Token=${res.access_token}; path=/`;
    localStorage.setItem('userID', res.UserID);
    this.authService.getUserProfile(res.UserID).subscribe(
      res => {
        localStorage.setItem('currentUser', JSON.stringify(res));
        //localStorage.setItem('role', res.role);
        localStorage.removeItem('currentLayoutStyle');
        let returnUrl = '/insights';
        if (this.returnUrl) {
          returnUrl = this.returnUrl;
        }
        this.router.navigate([returnUrl]);
      }
    ); 
  }
}
