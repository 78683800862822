<!DOCTYPE html>
<html lang="en">

<head>
    <title></title>
    <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i%7CQuicksand:300,400,500,700"
        rel="stylesheet">


</head>

<body style="height: fit-content !important;
    padding-top: 0rem !important;">
    <header class="navigation-top top-zero" id="header">
        <div class="container">
            <nav class="navbar" id="navbar-example2">
                <a class="navbar-brand scrollto" (click)="onClick('hero')">
                    <img alt="" class="img-fluid" src="assets/img/logo.svg" style="height:70px; width:auto"> </a>
                <div class="inner-nav">
                    <p class="close-menu"></p>

                    <ul class="nav nav-pills">
                        <a class="mobile-menu-logo scrollto" (click)="onClick('hero')"><img alt=""
                                src="assets/img/logo.svg" />
                        </a>
                        <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a>
                        </li>
                        <li class="nav-item"><a class="nav-link scrollto"
                                (click)="onClick('testimonials')">Testimonials</a></li>
                        <li class="nav-item"><a class="nav-link scrollto"
                                (click)="onClick('integration')">Integration</a></li>
                        <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('contact')">Contact</a></li>
                        <li class="nav-item"><a class="nav-link scrollto" routerLink="/signup">Get Started</a></li>
                        <li class="nav-item" ngbDropdown>
                            <a type="button" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag"
                                style="background:none;">
                                <span style="font-size:16px">Events</span>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                                <a class="dropdown-item" routerLink="/amazonexpo">Amazon Expo Boot Camp</a>
                                <a class="dropdown-item" routerLink="/shopeeworkshop">Shopee University workshop</a>
                            </div>
                        </li>
                        <li class="nav-item button-menu"><a class="getstarted scrollto"
                                (click)="open(LoginFormIconContent)">Login</a></li>
                    </ul>
                </div>
                <a class="nav-bar"><i class="bi bi-list" style="color:#3fad5a"aria-hidden="true">&nbsp;</i></a>
            </nav>
        </div>
    </header>
    <!-- ======= End Header ======= -->







    <!-- ======= Hero Section ======= -->
    <div [ngClass]="blockLoader ? 'save-loader-opacity' : ''">
        <div class="table-loader" *ngIf="blockLoader"></div>
        <section class="jumbotron jumbotron-fluid" id="hero" style="background-image: linear-gradient(to bottom right, black, #1d2e68 ) top center no-repeat;">
            <div class="container">
                <div class="row align-content-center">
                    <div
                        class="col-lg-4 d-flex flex-column justify-content-lg-center justify-content-md-start justify-content-sm-start" style="padding-top:10px">
                        <h1 class="aos-init aos-animate" data-aos="fade-up">The <span class="title-span">heart</span> of commerce</h1>

                        <h2 class="aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                            Manage your sales seamlessly<br> with an<span class="subtitle-span"> all-in-one platform </span></h2>

                        <div class="aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">
                            <div class="text-lg-start">
                                <a class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                                    routerLink="/signup">
                                    <span class="herobt">Get Started</span>
                                    <!-- <i class="bi bi-arrow-right"  aria-hidden="true" ></i> --></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 d-flex flex-column justify-content-center"><img alt="" class="img-fluid"
                            src="assets/img/hero-img-v2-green.png" /></div>
                </div>
            </div>
        </section>
        <!-- ======= End Hero Section ======= -->
        <!-- ======= Key Feature Section ======= -->

        <section class="jumbotron jumbotron-fluid values" id="key-feature" style="background: rgb(242, 243, 242);">
            <div class="container aos-init aos-animate" data-aos="fade-up">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center my-5">
                        <h2 class="section-title-text">Features to enhance your workflow</h2>
                    </div>
                </div>
            </div>

            <div class="container my-5">
                <div class="row justify-content-around align-items-center my-4" style="background: rgb(242, 243, 242);
                            border-radius: 5px;">

                    <img alt="" class="col-lg-5 img-fluid p-5 m-2" style="object-fit: cover;" src="assets/img/featureimgb2c.png" />

                    <div class="col-lg-6 align-items-center p-1">
                        <h3 class="subsection-title-text" style="font-weight: 700">Business-to-Customer</h3>
                        <ul class="subsection-subtitle-text" style="list-style-type: none">
                            <li>Product, Inventory and Order Management
                                <ul style="list-style-type: disc">
                                    <li>Edit, Add, Upload products to all offline and online channels</li>
                                    <li>Centralised inventory across online and offline channels</li>
                                    <li>Print in bulk for shipping labels, airways bills and invoices at different marketplaces</li>
                                </ul>
                            </li>
                            <li>Point of sales System
                                <ul style="list-style-type: disc">
                                    <li>Manage retail orders on a central platform</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="jumbotron jumbotron-fluid values" id="key-feature1" style="background: rgb(250, 250, 251)">
            <div class="container my-5">
                <div class="row justify-content-around align-items-center my-4" style="background: rgb(250, 250, 251);
                            border-radius: 5px;">

                    <div class="col-lg-6 align-items-center p-1">
                        <h3 class="subsection-title-text" style="font-weight: 700">Business-to-Business</h3>
                        <ul class="subsection-subtitle-text" style="list-style-type: none">
                            <li>B2B Portal
                                <ul style="list-style-type: disc">
                                    <li>Tailored storefront experience for different customers with customized pricing and promotion</li>
                                    <li>One-click reordering made easy through order history</li>
                                </ul>
                            </li>
                            <li>Simplyflow.ai
                                <ul style="list-style-type: disc">
                                    <li>Accurate data extraction from purchase orders, Emails and supplier portal</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <img alt="" class="col-lg-5 img-fluid p-5 m-2" style="object-fit: cover;" src="assets/img/featureimgb2b.png" />
                </div>
            </div>
        </section>
        <section class="jumbotron jumbotron-fluid values" id="key-feature2" style="background: rgb(242, 243, 242);">
            <div class="container my-5">
                <div class="row justify-content-around align-items-center my-4" style="background: rgb(242, 243, 242);
                            border-radius: 5px;">

                    <img alt="" class="col-lg-5 img-fluid p-5 m-2" style="object-fit: cover;" src="assets/img/featureimgwms2.png" />

                    <div class="col-lg-6 align-items-center p-1">
                        <h3 class="subsection-title-text" style="font-weight: 700">Warehouse Management System</h3>
                        <ul class="subsection-subtitle-text">
                            <li>Using Multiple location inventory to set inventory for different locations</li>
                            <li>Easing warehouse experience with pick and pack app</li>
                            <li>Seamless integrations with WMS and inventory systems</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="jumbotron jumbotron-fluid values" id="key-feature3" style="background: rgb(250, 250, 251)">
            <div class="container my-5">
                <div class="row justify-content-around align-items-center my-4" style="background: rgb(250, 250, 251);
                            border-radius: 5px;">
                    <div class="col-lg-6 align-items-center p-1">
                        <h3 class="subsection-title-text" style="font-weight: 700">ERP & Accounting Systems</h3>
                        <ul class="subsection-subtitle-text">
                            <li>Have an optimized workflow to your ERP & Accounting systems</li>
                            <li>Plug In the gaps in organizations to have seamless workflow</li>
                        </ul>
                    </div>

                    <img alt="" class="col-lg-5 img-fluid p-5 m-2" style="object-fit: cover;" src="assets/img/featureimgerp.png" />
                </div>
            </div>
        </section>

        <!-- ======= End Key Feature Section ======= -->
        <!-- ======= Services Section ======= -->

        <section class="jumbotron jumbotron-fluid services" id="services" style="background-color: #e2effd;">
            <div class="container aos-init aos-animate" data-aos="fade-up">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center my-5">
                        <h3 class="section-title-text">Solutions for every business</h3>
                    </div>
                </div>

                <div class="container px-4" id="bizTypeList">
                    <div class="row row-cols-5">
                        <button class="col-sm mx-1 button-81" role="button" 
                            (click)="slickModal.slickGoTo(0)">Brand Owner</button>
                        <button class="col-sm mx-1 button-81" role="button" 
                            (click)="slickModal.slickGoTo(1)">Online Seller</button>
                        <button class="col-sm mx-1 button-81" role="button"
                            (click)="slickModal.slickGoTo(2)">Distributor</button>
                        <button class="col-sm mx-1 button-81" role="button"
                            (click)="slickModal.slickGoTo(3)">Omni-channel Seller</button>
                        <button class="col-sm mx-1 button-81" role="button" 
                            (click)="slickModal.slickGoTo(4)">Brand Enabler</button>
                    </div>
                </div>
                
                <div>
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                        [config]="tabletSlide">

                        <div ngxSlickItem class="slide m-2" style="background: rgba(250, 250, 251, 0.95);
                        border-radius: 5px; box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); width: 100%;">

                            <div class="row row-cols-2 justify-content-center align-items-center"
                                style="height: auto; width:100%; padding: 100px 30px;">
                                <img alt="" class="col-lg-5 img-fluid" style="object-fit: cover;"
                                    src="assets/img/personabrandowner2.png" />
                                <!--</div>-->
                                <div class="col-lg-6">
                                    <p class="subsection-title-text" style="font-weight: 500;"> 
                                        Streamlining work for <span class="inline-span" style="color:#3fad5a;
                                        font-weight: 700;">Brand Owners</span>
                                        <br>
                                    </p>
                                    <ul class="subsection-subtitle-text">
                                        <li>Pushing data to your forecasting systems</li>
                                        <li>Overseeing Financial reconciliation even with foreign currencies</li>
                                        <li>Performing multiple location management and consignments using our WMS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="slide m-2" style="background: rgba(250, 250, 251, 0.95);
                                border-radius: 5px; box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); width: 100%;">

                            <div class="row row-cols-2 justify-content-center align-items-center"
                                style="height: auto; width:100%; padding: 100px 30px;">
                                <img alt="" class="col-lg-5 img-fluid" style="object-fit: cover;"
                                    src="assets/img/personaonlineseller.png" />
                                <!--</div>-->
                                <div class="col-lg-6">
                                    <p class="subsection-title-text" style="font-weight: 500;">
                                        Easing tedious tasks for <span class="inline-span" style="color:#3fad5a;
                                        font-weight: 700;">Online Sellers</span>
                                        <br>
                                    </p>
                                    <ul class="subsection-subtitle-text">
                                        <li>Reduce overselling by having centralised inventory across all marketplaces</li>
                                        <li>Mass printing of shipping labels, POs, airway bills etc.</li>
                                        <li>Oversee Financial reconciliation for your ecommerce marketplaces</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="slide m-2" style="background: rgba(250, 250, 251, 0.95);
                        border-radius: 5px; box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); width: 100%;">

                            <div class="row row-cols-2 justify-content-center align-items-center"
                                style="height: auto; width:100%; padding: 100px 30px;">
                                <img alt="" class="col-lg-5 img-fluid" style="object-fit: cover;"
                                    src="assets/img/personadistributor.png" />

                                <div class="col-lg-6">

                                        <p class="subsection-title-text" style="font-weight: 500;">
                                            Digitalize <span class="inline-span" style="color:#3fad5a;
                                            font-weight: 700;">Distribution</span> businesses 
                                            <br>
                                        </p>
                                        <ul class="subsection-subtitle-text">
                                            <li>Managing orders seamlessly from B2B app to extracting accurate information from documents
                                                and then pushing data to different systems</li>
                                            <li>Removing manual entry for staffs to focus on more valuable task</li>
                                            <li>No unnecessary back and forth comparison with offline reconciliation</li>
                                        </ul>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="slide m-2" style="background: rgba(250, 250, 251, 0.95);
                        border-radius: 5px; box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); width: 100%;">

                            <div class="row row-cols-2 justify-content-center align-items-center"
                                style="height: auto; width:100%; padding: 100px 30px;">
                                <img alt="" class="col-lg-5 img-fluid" style="object-fit: cover;"
                                    src="assets/img/personaomnichannel.png" />
                                <!--</div>-->
                                <div class="col-lg-6">
                                        <p class="subsection-title-text" style="font-weight: 500;">
                                            Enabling <span class="inline-span" style="color:#3fad5a;
                                            font-weight: 700;">Omni Sellers</span> to
                                            <br>
                                        </p>
                                        <ul class="subsection-subtitle-text">
                                            <li>View live inventory across all retail store and marketplaces</li>
                                            <li>Automate inventory updating upon sale from any channel</li>
                                            <li>Retail price and online sale</li>
                                            <li>Optimise sales on multiple store</li>
                                        </ul>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="slide m-2"
                            style="background: rgba(250, 250, 251, 0.95);
                            border-radius: 5px; box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25); width: 100%;">

                            <div class="row row-cols-2 justify-content-center align-items-center"
                                style="height: auto; width:100%; padding: 100px 30px;">
                                <img alt="" class="col-lg-5 img-fluid" style="object-fit: cover;"
                                    src="assets/img/personabrandenabler.png" />
                                <!--</div>-->
                                <div class="col-lg-6">

                                        <p class="subsection-title-text" style="font-weight: 500;">
                                            Easing <span class="inline-span" style="color:#3fad5a;
                                            font-weight: 700;">Brand enabler</span> life
                                            <br>
                                        </p>
                                        <ul class="subsection-subtitle-text">
                                            <li>Oversee of customer<span class="inline-span">&#8217;</span>s online shops</li>
                                            <li>Grouping of customers under designated accounts</li>
                                            <li>Pushing orders directly to WMS app for pickers and packers</li>
                                            <li>Alerting to pick items that are closer to expiry date first</li>
                                        </ul>
                                </div>
                            </div>
                        </div>

                    </ngx-slick-carousel>
                </div>

                <!--</div>-->
                <!--</div>
                </div>-->
            </div>
        </section>
        <!-- ======= About Section ======= -->

        <!-- ======= Testimonials Section ======= -->

        <section class="jumbotron jumbotron-fluid testimonials" id="testimonials" style="background-color: #F7F8F9;">
            <div class="container aos-init aos-animate" data-aos="fade-up">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center my-5">
                        <h2 class="section-title-text">What they are saying about us</h2>
                    </div>
                </div>

                <div>
                    <ngx-slick-carousel class="carousel testimonials-slider" #slickModal="slick-carousel"
                        [config]="testimonialSlide">

                        <div ngxSlickItem class="testimonial-item slide">
                            <div>
                                <div class="profile row py-2 mt-auto">
                                    <img alt="" class="testimonial-img2 col" src="assets/img/LHH.jpg" />
                                    <div class="col justify-content-end">
                                        <h3>Zijian Huang</h3><br>
                                        <h4>Director <br /> LHH Vegetable</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style: normal; font-size: 17px; color: #3FAD5A;">Business
                                        Type:</p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Distributor</span>
                                </h4>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style:  normal; font-size: 17px; color: #3FAD5A;">Industry:
                                    </p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Organic
                                        Foods</span>
                                </h4>
                                <p class="pt-1" style="font-size: 16px; color: #1d2e68;">"My B2B business took a hit due
                                    to Covid-19 and I had to grow my B2C business from scratch.
                                    Sell&Chill seamlessly integrates with all my sales and products from Shopee, Lazada
                                    and Qoo10. Within 3 months, I became one of the top sellers in Shopee.
                                    I relied on them for streamlining my backend operations such as assisting in
                                    automating my packing and delivery process as well."
                                </p>
                            </div>
                        </div>

                        <div ngxSlickItem class="testimonial-item slide">
                            <div>
                                <div class="profile row py-2 mt-auto">
                                    <img alt="" class="testimonial-img2 col" src="assets/img/orient.png" />
                                    <div class="col justify-content-end">
                                        <h3>Lynn</h3><br>
                                        <h4>CEO <br /> Orient Photo Pte Ltd</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style: normal; font-size: 17px; color: #3FAD5A;">Business
                                        Type:</p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Omni-Channel Seller</span>
                                </h4>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style:  normal; font-size: 17px; color: #3FAD5A;">Industry:
                                    </p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Consumer Electronic</span>
                                </h4>
                                <p class="pt-1" style="font-size: 16px; color: #1d2e68;">
                                    "Technology is the key factor to future success and the key driver behind technology is
                                    talent! I have explored numerous solution providers in the market and no other platform
                                    offers the integrated solution like sellnchill, this omni channel system solves my
                                    operation pain points with various online sales platforms like lazada, shopee and qoo10,
                                    it not only allows me to stay focused on business planning but also manages inventory
                                    efficiently and boost operation productivity. The most standout point is the talent
                                    behind the system values our feedback and keeps improving and finding ways to automate
                                    processes and make doing business easier. Greatly recommended, just take a trial and you
                                    will love it too! " 
                                </p>
                            </div>
                        </div>

                        <div ngxSlickItem class="testimonial-item slide">
                            <div>
                                <div class="profile row py-2 mt-auto">
                                    <img alt="" class="testimonial-img2 col" src="assets/img/testimonials/Gladleigh.jpeg" />
                                    <div class="col justify-content-end">
                                        <h3>Jimmy Tan</h3><br>
                                        <h4>Director <br /> Gladleigh</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style: normal; font-size: 17px; color: #3FAD5A;">Business
                                        Type:</p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Online Seller</span>
                                </h4>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style:  normal; font-size: 17px; color: #3FAD5A;">Industry:
                                    </p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Home & Living</span>
                                </h4>
                                <p class="pt-1" style="font-size: 16px; color: #1d2e68;">
                                    "Qoo10 is a key sales channel for me & their operations is slightly different from other
                                    marketplaces. My product listings are layered with multiple options & most aggregators I
                                    speak to cannot address my inventory challenges. Sell & Chill successfully manages my inventory for all marketplacsz including qoo10.
                                    Also built a brand new website for me within 2 weeks! And their support is fantastic.
                                    Thank you guys!"
                                </p>
                            </div>
                        </div>

                        <div ngxSlickItem class="testimonial-item slide">
                            <div>
                                <div class="profile row py-2 mt-auto">
                                    <img alt="" class="testimonial-img2 col" src="assets/img/testimonials/Rigorer_Logo.png" />
                                    <div class="col justify-content-end">
                                        <h3>Jun Ming</h3><br>
                                        <h4>Head of Business Development <br /> Rigorer Singapore</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style: normal; font-size: 17px; color: #3FAD5A;">Business
                                        Type:</p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Online Seller</span>
                                </h4>
                                <h4 class="pt-1">
                                    <p style="margin:0; font-style:  normal; font-size: 17px; color: #3FAD5A;">Industry:
                                    </p>
                                    <span style="font-weight: bold; font-size: 18px; color: #1d2e68;">Sports Equipment</span>
                                </h4>
                                <p class="pt-1" style="font-size: 16px; color: #1d2e68;">
                                    "It was not easy finding a local tech solutions provider who would listen, adopt, adapt
                                    to the ever demanding e-commerce landscape. The solution we wanted required innovations,
                                    customizations and sometimes flexibility. We needed an inventory management software
                                    that is relevant, scalable and future-proof. Challenges include over-selling during
                                    campaigns, uniformed picking list, combined platform sales dashboard and time for
                                    managing digital inventory.
                                    Sell & Chill checks them all boxes. Highly recommend anybody to use their services.
                                    Thank you team!"
                                </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </section>
        <!-- ======= End Testimonials Section ======= -->
        <!-- ======= Integration Section ======= -->

        <section class="jumbotron jumbotron-fluid integration" id="integration" style="background-color: #F7F8F9;">
            <div class="container aos-init aos-animate" data-aos="fade-up">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center my-5">
                        <h2 class="section-title-text text-center">World class integrations to help you grow your sales while chilling</h2>
                    </div>
                </div>

                <div class="row gy-4">
                    <div class="col-lg-12 col-sm-12 ">
                        <div class="outer-count-box">

                            <h4 style="color:#3b519c;font-weight: 500;">Marketplaces</h4>
                            <div class="integration-box-section">
                                <div class="integration-box">
                                    <img alt="" alt="Lazada" class="img-fluid" src="assets/img/lazada.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Shopee" class="img-fluid" src="assets/img/shopee.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Qoo10" class="img-fluid" src="assets/img/qoo10.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Redmart" class="img-fluid" src="assets/img/redmart.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Amazon" class="img-fluid" src="assets/img/amazon.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="eBay" class="img-fluid" src="assets/img/ebay.png" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 ">
                        <div class="outer-count-box">
                        </div>

                        <h4 style="color:#3b519c;font-weight: 500;">Web stores &amp; POS system</h4>
                        <div class="integration-box-section">
                            <div class="integration-box">
                                <img alt="" alt="Vend" class="img-fluid" src="assets/img/vend.png" />
                            </div>
                            <div class="integration-box">
                                <img alt="" alt="Shopify" class="img-fluid" src="assets/img/shopify.png" />
                            </div>
                            <div class="integration-box">
                                <img alt="" alt="Woocomerce" class="img-fluid" class="img-fluid"
                                    src="assets/img/woocommerce_logo.png" />
                            </div>
                            <div class="integration-box">
                                <img alt="" alt="Woocomerce" class="img-fluid" class="img-fluid"
                                    src="assets/img/shopline.png" />
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 col-sm-12 ">
                        <div class="outer-count-box">


                            <h4 style="color:#3b519c; font-weight: 500;">Accounting &amp; ERP systems</h4>
                            <div class="integration-box-section">
                                <div class="integration-box">
                                    <img alt="" alt="Microsoft" class="img-fluid" src="assets/img/ms.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="DP" class="img-fluid" src="assets/img/dp.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="xero" class="img-fluid" src="assets/img/xero-2.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Dear" class="img-fluid" src="assets/img/dear.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="AC" class="img-fluid" src="assets/img/ac.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Unleased" class="img-fluid" src="assets/img/unleased.jpg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Unleased" class="img-fluid" src="assets/img/cin7.svg" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Unleased" class="img-fluid" src="assets/img/oraclenetsuite2.png" />
                                </div>
                                <div class="integration-box">
                                    <img alt="" alt="Unleased" class="img-fluid" src="assets/img/sap.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="col-lg-12 d-flex flex-column align-items-center-center heading-section mt-5" style="font-family: 'Poppins';">
                    <p>If your vendor is not on the list, let us know &amp; we are happy to explore custom
                        integration
                    </p>
                </div>
            </div>

        </section>

        <!-- ======= Pricing Section ======= -->
<!--
    <section class="jumbotron jumbotron-fluid testimonials" id="pricing" style="background-color: #F7F8F9;">
        <div class=" aos-init aos-animate" data-aos="fade-up">

            <div class="row">
                <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center my-5">
                    <h2 class=" section-title-text ">See what suits you best</h2>
                </div>
            </div>

            <div>
                <ngx-slick-carousel class="carousel" #slickModal3="slick-carousel"
                    [config]="pricingSlide">

                    <div ngxSlickItem class="pricing-item slide">
                        <div style="min-height: 120px;">
                            <h1 class="subsection-title-text"
                                style="font-weight: bold;">Lite</h1>
                            <h2 style="color: #1d2e68; 
                            height: 50px;
                            font-size: 3rem;
                            font-weight: 300;">$299 </h2>
                            <span
                                style="color: #1d2e68; font-size: 16px; font-weight: 100; padding-left: 1px;">SGD/Month</span>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div style="min-height: 200px;">
                            <h2 style="font-weight: bold;">Includes:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; font-weight: 200; color: #1d2e68; margin:0;padding: 0;">
                                <li>2 User Accounts</li>
                                <li>Unlimited SKU</li>
                                <li>All SG integrated marketplaces</li>
                                <li>Webstore Logo</li>
                                <li>4 Stores per Marketplace</li>
                            </ul>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div>
                            <h2 style="font-weight: bold;">Add-Ons:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; font-weight: 200; color: #1d2e68; margin:0;padding: 0;">
                                <li>Simplyflow</li>
                                <li>Financial Reconciliation</li>
                                <li>B2B App</li>
                            </ul>
                        </div>
                    </div>

                    <div ngxSlickItem class="pricing-item slide">
                        <div style="min-height: 120px;">
                            <h1 class="subsection-title-text"
                                style="font-weight: bold;">Advance</h1>
                            <h2 style="color: #1d2e68; 
                            height: 50px;
                            font-size: 3rem;
                            font-weight: 300;">$399</h2>
                            <span
                                style="color: #1d2e68; font-size: 16px; font-weight: 100; padding-left: 1px;">SGD/Month</span>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div style="min-height: 200px;">
                            <h2 style="font-weight: bold;">Includes Lite, plus:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; font-weight: 200; color: #1d2e68; margin:0;padding: 0;">
                                <li>5 User Accounts</li>
                                <li>Unlimited Stores per Marketplace</li>
                                <li>Xero & Quickbooks Integration</li>
                            </ul>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div>
                            <h2 style="font-weight: bold;">Add-Ons:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-weight: 200; font-size: 16px; color: #1d2e68; margin:0;padding: 0;">
                                <li>Simplyflow</li>
                                <li>Financial Reconciliation</li>
                                <li>B2B App</li>
                            </ul>
                        </div>
                    </div>

                    <div ngxSlickItem class="pricing-item slide">
                        <div style="min-height: 120px;">
                            <h1 class="subsection-title-text"
                                style="font-weight: bold;">Pro</h1>
                            <h2 style="color: #1d2e68; 
                            height: 50px;
                            font-size: 3rem;
                            font-weight: 300;">$499</h2>
                            <span
                                style="color: #1d2e68; font-size: 16px; font-weight: 100; padding-left: 1px;">SGD/Month</span>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div style="min-height: 200px;">
                            <h2 style="font-weight: bold;">Includes Advance, plus:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68; font-weight: 200; margin:0;padding: 0;">
                                <li>Multiple Local Inventory</li>
                                <li>Point-of-Sale</li>
                                <li>Accounting and ERP Integration</li>
                            </ul>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div>
                            <h2 style="font-weight: bold;">Add-Ons:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68; margin:0;padding: 0; font-weight: 200;">
                                <li>Simplyflow</li>
                                <li>Financial Reconciliation</li>
                                <li>B2B App</li>
                            </ul>
                        </div>
                    </div>

                    <div ngxSlickItem class="pricing-item slide">
                        <div style="min-height: 120px;">
                            <h1 class="subsection-title-text"
                                style="font-weight: bold;">Premium</h1>
                            <h2 style="color: #1d2e68; 
                            height: 50px;
                            font-size: 3rem;
                            font-weight: 300;">$599</h2>
                            <span
                                style="color: #1d2e68; font-size: 16px; font-weight: 100; padding-left: 1px;">SGD/Month</span>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div style="min-height: 200px;">
                            <h2 style="font-weight: bold;">Includes Pro, plus:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68;  margin:0;padding: 0; font-weight: 200;">
                                <li>Warehouse Management App</li>
                            </ul>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div>
                            <h2 style="font-weight: bold;">Add-Ons:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68; margin:0;padding: 0; font-weight: 200;">
                                <li>Simplyflow</li>
                                <li>Financial Reconciliation</li>
                                <li>B2B App</li>
                            </ul>
                        </div>
                    </div>

                    <div ngxSlickItem class="pricing-item slide">
                        <div style="min-height: 120px;">
                            <h1 class="subsection-title-text"
                                style="font-weight: bold;">Enterprise</h1>
                            <br>
                            <button class="button-81" (click)="onClick('contact')">Contact us</button>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div style="min-height: 200px;">
                            <h2 style="font-weight: bold;">Includes Premium, plus:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68; margin:0;padding: 0; font-weight: 200;">
                                <li>Warehouse Integration</li>
                                <li>Point-of-Sale Integration</li>
                                <li>Sell on Marketplaces overseas</li>
                                <li>ERP Automation and Integration</li>
                            </ul>
                        </div>
                        <br>
                        <div style="width: 80%;
                        margin: 0 10% 10%;
                        border: 1px solid #d5d5d5;
                        border-color:#c5c7d0"></div>
                        <div>
                            <h2 style="font-weight: bold;">Add-Ons:</h2>
                            <ul class="pt-1"
                                style="list-style-type: none; font-size: 16px; color: #1d2e68; margin:0;padding: 0; font-weight: 200;">
                                <li>Simplyflow</li>
                                <li>Financial Reconciliation</li>
                                <li>B2B App</li>
                            </ul>
                        </div>
                    </div>


                </ngx-slick-carousel>
            </div>
            
            
        </div>
    </section>
-->
        
    <!-- ======= End Pricing Section ======= -->

        <!-- ======= Contact Section ======= -->

        <section class="jumbotron jumbotron-fluid contact" id="contact" style=" background-color: #F7F8F9;">
            <div class="container aos-init aos-animate" data-aos="fade-up">
                <div class="row">
                    <div class="col-lg-12 d-flex flex-column align-items-center-center heading-section">
                        <h2 class="section-title-text">Contact Us</h2>
                    </div>
                </div>

                <div class="row gy-4">
                    <div class="col-lg-6">
                        <div class="row gy-4 contact-detals-row"><img alt="" class="img-fluid"
                                src="assets/img/CONTACT.png" /></div>
                    </div>

                    <div class="col-lg-6">
                        <form class="php-email-form" [formGroup]="contactForm" (ngSubmit)="tryContact()">
                            <div class="row gy-4 pt-5">
                                <div class="col-md-6"><input class="form-control" formControlName="name"
                                        placeholder="Your Name *" type="text"
                                        [ngClass]="{ 'is-invalid': submitted && c.name.errors }" /></div>

                                <div class="col-md-6 "><input class="form-control" formControlName="email"
                                        placeholder="Your Email *" type="email" email
                                        [ngClass]="{ 'is-invalid': submitted && c.email.errors }" /></div>

                                <div class="col-md-12"><input class="form-control" formControlName="subject"
                                        placeholder="Subject" type="text"
                                        [ngClass]="{ 'is-invalid': submitted && c.subject.errors }" /></div>

                                <div class="col-md-12"><textarea class="form-control" formControlName="message"
                                        placeholder="Message *" rows="6"
                                        [ngClass]="{ 'is-invalid': submitted && c.message.errors }"></textarea></div>

                                <div class="col-md-12 text-center">
                                    <button type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- ======= End Blog Section ======= -->
        <!-- ======= Footer ======= -->

        <footer class="footer" id="footer" style="padding: 0px;">
            <div class="footer-newsletter">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center">
                            <h4 style="font-family: 'Lato', sans-serif;">Get Started today with<br />
                                <div class="highlight-text">50% Government subsidy</div>
                            </h4>
                        </div>

                        <div class="col-lg-6 text-center"><button class="btn btn-sell" routerLink="/signup">
                                Get started</button></div>

                    </div>
                </div>
            </div>

            <div class="footer-top">
                <div class="container">
                    <div class="row gy-4">
                        <div class="col-lg-5 col-md-12  col-sm-12   footer-info">
                            <a class="logo d-flex align-items-center scrollto" href="#hero">
                                <img alt="" style="height:70px; width:auto;margin-left: 26px;"
                                    src="assets/img/logoWhite.png" class="md mx-auto d-block" /> </a>



                            <div class="social-links mt-3"></div>
                        </div>

                        <div class="col-lg-4 col-12  col-sm-12   footer-links">
                            <h4>Useful Links</h4>

                            <ul class="footer-menu">
                                <li> <a class="nav-link scrollto" (click)="onClick('hero')">Home</a></li>
                                <li><a href="https://mindmaster.com.sg/" target="_blank">About us</a></li>
                                <li class="nav-item"><a class="nav-link scrollto"
                                        (click)="onClick('key-feature')">Features</a></li>
                                <li class="nav-item"><a class="nav-link scrollto"
                                        (click)="onClick('testimonials')">Testimonials</a></li>
                                <li class="nav-item"><a class="nav-link scrollto"
                                        (click)="onClick('integration')">Integration</a></li>
                                <li class="nav-item"><a class="nav-link scrollto" routerLink="/privacy-policy">Privacy
                                        Policy</a></li>
                                <li class="nav-item"><a class="nav-link scrollto" routerLink="/terms-of-service">Terms
                                        of Service</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="open(LoginFormIconContent)">Login</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12   footer-contact ">
                            <h4>Contact Us</h4>

                            <p>Foo Wah Industrial Building<br /> 45 Jln Pemimpin,<br /> Singapore 577197<br />
                                <br />
                                <span class="Green">+65 6250 5623</span><br /> support@sellnchill.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="copyright">&copy; Copyright <strong><span>Sell & Chill</span></strong>. All Rights Reserved
                </div>

            </div>
        </footer>
    </div>
    <!-- ======= End Footer ======= -->
    <!-- ======= Login Model ======= -->
    <ng-template class="modal text-left" #LoginFormIconContent let-d="dismiss">
        <div class="modal-xl">
            <div class="modal-header">
                <img alt="" class="img-fluid" src="assets/img/logo.svg" style="height: 50px;
            width: auto;
            text-align: center;
            margin: 0 auto;">
                <!-- <h3 class="modal-title text-600" id="myModalLabel34">Login</h3> -->
                <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click');clearLoginData()">
              <span aria-hidden="true">&times;</span>
            </button> -->
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="tryLogin()" *ngIf="isReset === false">
                <div class="modal-body">
                    <label>Username: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                            placeholder="Username" required>
                        <div class="form-control-position">
                            <i class="la la-user font-medium-5 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Username is required</div>
                        </div>
                    </div>

                    <label>Password: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="password" placeholder="Password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div class="form-control-position">
                            <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div style="text-align: end;
                    cursor: pointer;
                    color: #3fad5a;
                    text-decoration: underline;
                    margin-bottom: 10px;" (click)="onFogot()"><a class="card-link">Forgot Password</a>
                    </div>
                    <div style="text-align: end;
                    cursor: pointer;
                    color: #3fad5a;
                    text-decoration: underline;
                    margin-bottom: 10px;" (click)="onReset()"><a class="card-link">Reset Password</a>
                    </div>
                    <!-- <div>Already have an account? <a href="#" style="color:#3fad5a">Sign in</a></div> -->

                    <div class="clearfix">
                        <label class="inline">
                            By clicking Login, you confirm that you accept our <a href="/terms-of-service"
                                target="_blank">Terms of Service</a> and <a href="/privacy-policy"
                                target="_blank">Privacy Policy</a>.
                        </label>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn grey btn-outline-secondary btn-lg"
                        (click)="d('Close modal');clearLoginData()">Close</button>
                    <button type="submit" class="btn btn-outline-success btn-lg">
                        <i class="fa fa-refresh fa-spin" *ngIf="loading" aria-hidden="true"></i>
                        <i class="feather ft-unlock" *ngIf="!loading" aria-hidden="true"></i> Login</button>
                </div>
            </form>
            <form [formGroup]="ResetForm" (ngSubmit)="tryReset()" *ngIf="isReset === true ">
                <div class="modal-body">
                    <label>Username: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && r.email.errors }" id="user-name"
                            placeholder="Username" required>
                        <div class="form-control-position">
                            <i class="la la-user font-medium-5 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && r.email.errors" class="invalid-feedback">
                            <div *ngIf="r.email.errors.required">Username is required</div>
                        </div>
                    </div>

                    <label>Password: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="password" placeholder="Password" formControlName="oldPassword" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && r.oldPassword.errors }" />
                        <div class="form-control-position">
                            <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && r.oldPassword.errors" class="invalid-feedback">
                            <div *ngIf="r.oldPassword.errors.required">Password is required</div>
                        </div>
                    </div>

                    <label>New Password: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="password" placeholder="New Password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && r.password.errors }" />
                        <div class="form-control-position">
                            <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && r.password.errors" class="invalid-feedback">
                            <div *ngIf="r.password.errors.required">New Password is required</div>
                        </div>
                    </div>
                    <label>Confirm Password: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="password" placeholder="Confirm Passwor" formControlName="confirmPassword"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && r.confirmPassword.errors }" />
                        <div class="form-control-position">
                            <i class="la la-lock font-large-1 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && r.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="r.confirmPassword.errors.required">Confirm Passwor is required</div>
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn grey btn-outline-secondary btn-lg"
                        (click)="d('Close modal');clearLoginData()">Close</button>
                    <button type="submit" class="btn btn-outline-success btn-lg">
                        <i class="fa fa-refresh fa-spin" *ngIf="loading" aria-hidden="true"></i>
                        <i class="feather ft-unlock" *ngIf="!loading" aria-hidden="true"></i> Reset</button>
                </div>
            </form>
            <form [formGroup]="FogotForm" (ngSubmit)="tryFogot()" *ngIf="isForgot === true ">
                <div class="modal-body">
                    <label>Username: </label>
                    <div class="form-group position-relative has-icon-left">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && g.email.errors }" id="user-name"
                            placeholder="Username" required>
                        <div class="form-control-position">
                            <i class="la la-user font-medium-5 line-height-1 text-muted icon-align"
                                aria-hidden="true"></i>
                        </div>
                        <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
                            <div *ngIf="g.email.errors.required">Username is required</div>
                        </div>
                    </div>

       


                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn grey btn-outline-secondary btn-lg"
                        (click)="d('Close modal');clearLoginData()">Close</button>
                    <button type="submit" class="btn btn-outline-success btn-lg">
                        <i class="fa fa-refresh fa-spin" *ngIf="loading" aria-hidden="true"></i>
                        <i class="feather ft-unlock" *ngIf="!loading" aria-hidden="true"></i> Forgot</button>
                </div>
            </form>
        </div>
    </ng-template>
    <ng-template #loginModal let-modal>
        <div class="modal-body">

            <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click');clearLoginData()">
                <span class="closse" aria-hidden="true">X</span>
            </button>

            <ngb-tabset [destroyOnHide]="false">
                <ngb-tab title="Login">
                    <ng-template ngbTabContent>
                        <form class="row g-3 " [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                            <div class="col-sm-12 mb-2 pt-3 ">
                                <label class="form-label" for="exampleFormControlInput1 ">Username</label>
                                <input type="text" formControlName="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                                    placeholder="Username" required>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Username is required</div>
                                </div>
                            </div>


                            <div class="col-sm-12 mb-2 ">
                                <label class="form-label" for="inputPassword2 ">Password</label>
                                <input type="password" placeholder="Password" formControlName="password"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>



                            <div class="col-sm-10 mb-2 form-check form-group">
                                <div class="form-check m-l-10">
                                    <label class="form-check-label" style="margin-bottom: 0px;">
                                        <input class="form-check-input" type="checkbox" name="loginPlatform"
                                            formcontrolname="loginPlatform" style="margin-left: -15px;
                                            margin-top: 2px;">
                                        Check me out
                                        <span class="form-check-sign" style="margin-top: -8px;margin-left: 5px;">
                                            <span class="check"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-sm-12 mb-2 "><button class="btn btn-primary " type="submit ">
                                    <i class="fa fa-refresh fa-spin" *ngIf="loading" aria-hidden="true"></i>
                                    <i class="feather ft-unlock" *ngIf="!loading" aria-hidden="true"></i> Submit
                                </button></div>
                        </form>
                    </ng-template>
                </ngb-tab>

                <ngb-tab title="Register">
                    <ng-template ngbTabContent>
                        <form class="needs-validation " novalidate=" ">
                            <div class="row g-3 mb-2 pt-3 ">
                                <div class="col-sm-6 "><label class="form-label " for="firstName ">Name</label> <input
                                        class="form-control " id="firstName " placeholder=" " required=" " type="text "
                                        value=" " />
                                    <div class="invalid-feedback ">Valid name is required.</div>
                                </div>

                                <div class="col-sm-6 "><label class="form-label " for="lastName ">Company Name*</label>
                                    <input class="form-control " id="lastName " placeholder=" " required=" "
                                        type="text " value=" " />
                                    <div class="invalid-feedback ">Valid Company Name is required.</div>
                                </div>

                                <div class="col-lg-6 col-sm-12  "><label class="form-label " for="email ">Email <span
                                            class="text-muted ">(Optional)</span></label> <input class="form-control "
                                        id="email " placeholder="you@example.com " type="email " />
                                    <div class="invalid-feedback ">Please enter a valid email.</div>
                                </div>

                                <div class="col-lg-6 col-sm-12 "><label class="form-label " for="telephone ">Telephone
                                        Number <span class="text-muted ">(Optional)</span></label> <input
                                        class="form-control " id="Telephone " placeholder="+12 2312 8462 02 "
                                        type="email " />
                                    <div class="invalid-feedback ">Please enter a valid Telephone Number.</div>
                                </div>

                                <div class="col-lg-6 col-sm-12 "><label class="form-label "
                                        for="inputPassword2 ">Password</label> <input class="form-control "
                                        id="inputPassword2 " placeholder="Password " type="password " />
                                    <div class="invalid-feedback ">Please enter your shipping address.</div>
                                </div>

                                <div class="col-sm-10 mb-2 form-check "><input class="form-check-input "
                                        id="exampleCheck1 " type="checkbox" /> <label class="form-check-label "
                                        for="exampleCheck1 ">Check me out</label></div>
                            </div>
                            <button class="w-100 btn btn-primary btn-lg " type="submit ">Submit</button>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </ng-template>
    <!-- Modal -->


</body>

</html>