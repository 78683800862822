import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopeeworkshopService } from './shopeeworkshop.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-shopeeworkshop',
  templateUrl: './shopeeworkshop.component.html',
  styleUrls: ['./shopeeworkshop.component.css']
})
export class ShopeeworkshopComponent implements OnInit {

  shopeeworkshopForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  blockLoader: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public shopeeworkshopService: ShopeeworkshopService,

) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $(window).scroll(function () {
        var sticky = $('#header'),
          scroll = $(window).scrollTop();

        if (scroll >= 20) {
          sticky.addClass('fixed');
          $('.back-to-top').addClass('active');
        } else {
          sticky.removeClass('fixed');
          $('.back-to-top').removeClass('active');
        }
      });
      $("a.nav-bar").click(function () {
        $(".inner-nav").addClass('active-menu');
      });

      $("p.close-menu").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });

      $("ul.nav.nav-pills a").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });
      $(window).resize(function () {
        $("#log").append("<div>Handler for .resize() called.</div>");
      });
      const links: any = document.querySelectorAll(".scrollto");
      for (const link of links) {
        link.addEventListener("click", this.clickHandler);
      }

    });

    this.shopeeworkshopForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: [''],
      countrycode:['65'],
      number: [''],
      email: ['', Validators.required],
      promocode: ['', Validators.required],
      agreeTerms: [false, Validators.requiredTrue]
    });
  }

    // convenience getter for easy access to form fields
    get f() {
      return this.shopeeworkshopForm.controls;
    }
  

  onClick(id: string): void {
   this.router.navigate(['/']);
  }

  tryShopeeworkshop() {
    this.submitted = true;
    if(this.shopeeworkshopForm.value.agreeTerms==false){
      Swal.fire({
        text: 'Must need to Agree Our Terms & Policy',
       // title: 'Accept Terms',
        icon: 'error',
      })
    }else if((this.shopeeworkshopForm.value.promocode).toLowerCase()!='shopee2022'){
      Swal.fire({
        text: 'Not a Valid promo code',
       // title: 'Accept Terms',
        icon: 'error',
      })
    }
    // stop here if form is invalid
    if (this.shopeeworkshopForm.invalid || this.shopeeworkshopForm.value.agreeTerms==false || (this.shopeeworkshopForm.value.promocode).toLowerCase()!='shopee2022' ) {
      return;
    }
    this.startBlockUI();
    
    this.shopeeworkshopService.doShopeeworkshop(this.shopeeworkshopForm.value).subscribe(
      (res:any) => {
        this.stopBlockUI();
       if(res.result==1){
        Swal.fire({
          title: 'Success',
          text: 'Thank you. We will be in touch with you shortly.',
          icon: 'success',
        }).then((willDelete) => {
          if (willDelete) {
            this.onClick('');
          }else{
            this.onClick('');
          }
        });
       }

       if(res.result==0){
        Swal.fire({
          title: 'Error',
          text: 'Some thing went Wrong!, Please try again',
          icon: 'error',
        })
       }

      },
      err => {
        this.stopBlockUI();
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let errorDetails = err.error;
        Swal.fire({
          title: 'failed!, Please try again',
          text: errorDetails,
          icon: 'error',
        })

      }
    );
  }

  public stopBlockUI(): void {
    this.blockLoader = false;
  }

  public startBlockUI(): void {
    this.blockLoader = true;
  }


}
