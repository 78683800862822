import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $(window).scroll(function () {
        var sticky = $('#header'),
          scroll = $(window).scrollTop();

        if (scroll >= 20) {
          sticky.addClass('fixed');
          $('.back-to-top').addClass('active');
        } else {
          sticky.removeClass('fixed');
          $('.back-to-top').removeClass('active');
        }
      });
      $("a.nav-bar").click(function () {
        $(".inner-nav").addClass('active-menu');
      });

      $("p.close-menu").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });

      $("ul.nav.nav-pills a").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });
      $(window).resize(function () {
        $("#log").append("<div>Handler for .resize() called.</div>");
      });
      const links: any = document.querySelectorAll(".scrollto");
      for (const link of links) {
        link.addEventListener("click", this.clickHandler);
      }

    });
  }

  onClick(id: string): void {
   this.router.navigate(['/']);
  }

}
