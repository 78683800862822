import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class WooCommerceOrderService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getOrders(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/GetWooOrders`, reqObj);
  }
  getOrdersSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/SyncWooOrder`, reqObj);
  }
  getwooStores(companyID) {
    return this.http.get(`${this.apiBaseURL}/WooCommerce/GetWooStores?companyID=${companyID }`);
  }
  getPrintWooCommerceDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/PrintWooCommerceDocs`, reqObj);
  }
  getDBSWooCommerceDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/GetLazDBSShippingLabels`, reqObj);
  }
  markAsPrinted(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkAsPrinted`, reqObj);
  }
  markDBSStatus(type, reqObj) {
    if (type === 'delivered') {
      return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkDBSOrderAsDelivered`, reqObj);
    } else {
      return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkDBSOrderAsFailed`, reqObj);
    }
  }
  getOrderItems(reqObj) {
    return this.http.get(`${this.apiBaseURL}/WooCommerce/GetWooCommerceOrderItems?CompanyID=${reqObj.CompanyID}&SellerID=${reqObj.SellerID}&Platform=${reqObj.Platform}&OrderID=${reqObj.OrderID}`);
  }
  cancelOrder(reqObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkAsCancel`, reqObj);
  }
  getWooCommerceFailureReasons() {
    return this.http.get(`${this.apiBaseURL}/WooCommerce/GetWooCommerceFailureReasons`);
  }
  readyToShip(reqObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkReadyToShip`, reqObj);
  }
  checkout(reqObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/MarkAsCheckout`, reqObj);
  }
  getOrderDetails(reqObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooInvoiceDetail`, reqObj);
  }
}
