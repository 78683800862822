import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class ShopeeOrderService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getOrders(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeOrders`, reqObj);
  }
  getOrdersSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/SyncShopeeOrder`, reqObj);
  }
  getPrintShopeeDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/PrintShippingLabel`, reqObj);
  }
  markAsPrinted(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/ShopeeMarkAsPrinted`, reqObj);
  }

  getShopeeCarrier(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/PrintCarrierManifest`, reqObj);
  }
  getOrderItems(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeOrderItems`, reqObj);
  }
  getMassOrderItems(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeMassOrderItems`, reqObj);
  }
  cancelOrder(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/MarkAsCancel`, reqObj);
  }
  readyToShip(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/MarkReadyToShip`, reqObj);
  }
  checkout(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/MarkAsCheckout`, reqObj);
  }
  getOrderDetails(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/ShopeeInvoiceDetail`, reqObj);
  }
  getShopeeFailureReasons() {
    return this.http.get(`${this.apiBaseURL}/Shopee/GetShopeeFailureReasons`);
  }
  getLogisticInfo(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/ShopeeGetShippingParameter`, reqObj);
  }
  getAddresses(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/ShopeeGetAddresses`, reqObj);
  }
  getTimeSlots(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/ShopeeGetTimeSlots`, reqObj);
  }
  init(reqObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/ReadyToShip`, reqObj);
  }

  getOrderLogistics(){
    return this.http.get(`${this.apiBaseURL}/Common/GetShopeeCarriers`);
  }

  //PAYMENT STATUS UPDATE
  SyncPaymentUpdate(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/SyncPaymentUpdate`, reqObj);
  }

  //UPDATE ORDERS
  SyncShopeeUpdateOrder(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/SyncShopeeUpdateOrder`, reqObj);
  }

  //CUSTOMER COPY - START - 11-02-2022
  ShopeeCustomerCopy(obj){
    return this.http.post(`${this.apiBaseURL}/Shopee/ShopeeCustomerCopy`, obj)
  }
}
