import { TreeviewItem } from "ngx-treeview";

export class GlobalConstants {
  public static apiURL: string = "https://sellnchill.com/M3"; // enable this for server
  //public static apiURL: string = "M3"; //enable this for local
  //public static apiURL: string = "http://158.140.133.82:82/V4"; //enable this for server
  //public static apiURL: string = "V4"; //enable this for local
  public apiimagesURL: string = "https://sellnchill.com/Bucket"; // FOR IMAGES
  public lazada_image = this.apiimagesURL + '/Platforms/100px/lazada.png';
  public qoo10_image = this.apiimagesURL + '/Platforms/100px/qoo10.png';
  public shopee_image = this.apiimagesURL + '/Platforms/100px/shopee.png';
  public shopify_image = this.apiimagesURL + '/Platforms/100px/shopify.png';
  public woo_image = this.apiimagesURL + '/Platforms/100px/woo.png';
  public vend_image = this.apiimagesURL + '/Platforms/100px/vend.png';
  public amazon_image = this.apiimagesURL + '/Platforms/100px/amazon.png';
  public tiktok_image = this.apiimagesURL + '/Platforms/100px/tiktok.png';
  public opencart_image = this.apiimagesURL + '/Platforms/100px/opencart.png';
  public tiki_image = this.apiimagesURL + '/Platforms/100px/tiki.png';
  public unleashed_image = '/assets/img/unleashed_logo.png';
  public autocount_image = '/assets/img/ac.jpg';
  public excel_icon = '/assets/img/icon/excel.svg';
  public logo_icon = '/assets/img/logo-icon.png';
  public offline_image = this.logo_icon;
  public dear_image = '/assets/img/dear.png';
  public xero_image = '/assets/img/xero.png';
  public insights_all_image = '/assets/img/All.png';
  public cin7_image = '/assets/img/cin7.png';
  public cin7_list_image = '/assets/img/cin7Full.png';
  public businesscentral_image = '/assets/img/businesscentral.png';
  public businesscentral_list_image = '/assets/img/businesscentralFull.png';

  public euroStop_image = '/assets/img/eurostop.png';
  public euroStop_list_image = '/assets/img/eurostop-list.jpg';

  public quickBook_image = '/assets/img/dp.jpg';
  public quickBook_list_image = '/assets/img/qb-list.jpg';

  public dear_list_image = '/assets/img/dear-list.jpg';
  public xero_list_image = '/assets/img/xero-list.jpg';
  public unleashed_list_image = '/assets/img/unleash-list.jpg';
  public autocount_list_image = '/assets/img/autocount-list.jpg';

  public easyparcel_image = '/assets/img/EasyParcel.png';
  public pos_image = '/assets/img/pos.png';

  public static RTE_TOOGLED_BUTTONS = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'];       // toggled buttons
  public static RTE_H1_H2 = [{ 'header': 1 }, { 'header': 2 }];       // custom button values
  public static RTE_LISTS = [{ 'list': 'ordered' }, { 'list': 'bullet' }];
  public static RTE_SUP_SUB_SCRIPTS = [{ 'script': 'sub' }, { 'script': 'super' }];      // superscript/subscript
  public static RTE_INDENTS = [{ 'indent': '-1' }, { 'indent': '+1' }];         // outdent/indent
  public static RTE_RTL = [{ 'direction': 'rtl' }];                    // text direction
  public static RTE_TEXT_SIZE = [{ 'size': ['small', false, 'large', 'huge'] }];  // custom dropdown
  public static RTE_HEADERS = [{ 'header': [1, 2, 3, 4, 5, 6, false] }];
  public static RTE_COLOR_BACKGROUND = [{ 'color': [] }, { 'background': [] }];          // dropdown with defaults from theme
  public static RTE_FONT = [{ 'font': [] }];
  public static RTE_ALIGNMENT = [{ 'align': [] }];
  public static RTE_CLEAN = ['clean'];                                      // remove formatting button
  public static RTE_LINK_IMAGE = ['link', 'image', 'video'];                 // link and image, video

  public transferTypes = [
    // { value: "inventory_to_store", label: "Inventory to Store" },
    // { value: "store_to_store", label: "Store to Store" },
    // { value: "warehouse_to_store", label: "Warehouse to Store" },
    { value: "warehouse_to_warehouse", label: "Warehouse to Warehouse", },
    { value: "internal", label: "Internal" },

  ];

  public costingMethodData = [
    { Value: 'FIFO', Label: 'FIFO' },
    { Value: 'FISN', Label: 'FIFO - Serial Number' },
    { Value: 'FIBATCH', Label: 'FIFO - Batch' },
    { Value: 'FESN', Label: 'FEFO - Serial Number' },
    { Value: 'FEBATCH', Label: 'FEFO - Batch' },
    { Value: 'BATCH', Label: 'Special - Batch' },
    { Value: 'SN', Label: 'Special - Serial Number' },
  ];

  public dropShippingData = [
    { Value: 'No Drop Ship', Label: 'No Drop Ship' },
    { Value: 'Optional Drop Ship', Label: 'Optional Drop Ship' },
    { Value: 'Always Drop Ship', Label: 'Always Drop Ship' },
  ];

  public ItemSizeUOMData = [
    { Value: 'inch', Label: 'inch' },
    { Value: 'foot', Label: 'foot' },
    { Value: 'yard', Label: 'yard' },
    { Value: 'mile', Label: 'mile' },
    { Value: 'millimetre', Label: 'millimetre' },
    { Value: 'centimetre', Label: 'centimetre' },
    { Value: 'metre', Label: 'metre' },
    { Value: 'kilometre', Label: 'kilometre' },
  ];

  public ItemWeightUOMData = [
    { Value: 'ounces', Label: 'ounces' },
    { Value: 'pound', Label: 'pound' },
    { Value: 'milligram', Label: 'milligram' },
    { Value: 'gram', Label: 'gram' },
    { Value: 'kilogram', Label: 'kilogram' },
  ];

  public dateByData = [
    { Value: 'Order Date', Label: 'Order Date' },
    { Value: 'Ship Date', Label: 'Ship Date' },
  ];

  public paymentGateways = [
    { Value: 'Hitpay', Label: 'HitPay' },
    { Value: 'Razorpay', Label: 'Razorpay' },
    { Value: 'Paypal', Label: 'Paypal' },
  ];

  public curencyList = [
    {
      "label": "USD($)",
      "value": "USD"
    },
    {
      "label": "CAD($)",
      "value": "CAD"
    },
    {
      "label": "EUR(€)",
      "value": "EUR"
    },
    {
      "label": "AED(د.إ.‏)",
      "value": "AED"
    },
    {
      "label": "AFN(؋)",
      "value": "AFN"
    },
    {
      "label": "ALL(Lek)",
      "value": "ALL"
    },
    {
      "label": "AMD(դր.)",
      "value": "AMD"
    },
    {
      "label": "ARS($)",
      "value": "ARS"
    },
    {
      "label": "AUD($)",
      "value": "AUD"
    },
    {
      "label": "AZN(ман.)",
      "value": "AZN"
    },
    {
      "label": "BAM(KM)",
      "value": "BAM"
    },
    {
      "label": "BDT(৳)",
      "value": "BDT"
    },
    {
      "label": "BGN(лв.)",
      "value": "BGN"
    },
    {
      "label": "BHD(د.ب.‏)",
      "value": "BHD"
    },
    {
      "label": "BIF(FBu)",
      "value": "BIF"
    },
    {
      "label": "BND($)",
      "value": "BND"
    },
    {
      "label": "BOB(Bs)",
      "value": "BOB"
    },
    {
      "label": "BRL(R$)",
      "value": "BRL"
    },
    {
      "label": "BWP(P)",
      "value": "BWP"
    },
    {
      "label": "BYN(руб.)",
      "value": "BYN"
    },
    {
      "label": "BZD($)",
      "value": "BZD"
    },
    {
      "label": "CDF(FrCD)",
      "value": "CDF"
    },
    {
      "label": "CHF(CHF)",
      "value": "CHF"
    },
    {
      "label": "CLP($)",
      "value": "CLP"
    },
    {
      "label": "CNY(CN¥)",
      "value": "CNY"
    },
    {
      "label": "COP($)",
      "value": "COP"
    },
    {
      "label": "CRC(₡)",
      "value": "CRC"
    },
    {
      "label": "CVE(CV$)",
      "value": "CVE"
    },
    {
      "label": "CZK(Kč)",
      "value": "CZK"
    },
    {
      "label": "DJF(Fdj)",
      "value": "DJF"
    },
    {
      "label": "DKK(kr)",
      "value": "DKK"
    },
    {
      "label": "DOP(RD$)",
      "value": "DOP"
    },
    {
      "label": "DZD(د.ج.‏)",
      "value": "DZD"
    },
    {
      "label": "EEK(kr)",
      "value": "EEK"
    },
    {
      "label": "EGP(ج.م.‏)",
      "value": "EGP"
    },
    {
      "label": "ERN(Nfk)",
      "value": "ERN"
    },
    {
      "label": "ETB(Br)",
      "value": "ETB"
    },
    {
      "label": "GBP(£)",
      "value": "GBP"
    },
    {
      "label": "GEL(GEL)",
      "value": "GEL"
    },
    {
      "label": "GHS(GH₵)",
      "value": "GHS"
    },
    {
      "label": "GNF(FG)",
      "value": "GNF"
    },
    {
      "label": "GTQ(Q)",
      "value": "GTQ"
    },
    {
      "label": "HKD($)",
      "value": "HKD"
    },
    {
      "label": "HNL(L)",
      "value": "HNL"
    },
    {
      "label": "HRK(kn)",
      "value": "HRK"
    },
    {
      "label": "HUF(Ft)",
      "value": "HUF"
    },
    {
      "label": "IDR(Rp)",
      "value": "IDR"
    },
    {
      "label": "ILS(₪)",
      "value": "ILS"
    },
    {
      "label": "INR(টকা)",
      "value": "INR"
    },
    {
      "label": "IQD(د.ع.‏)",
      "value": "IQD"
    },
    {
      "label": "IRR(﷼)",
      "value": "IRR"
    },
    {
      "label": "ISK(kr)",
      "value": "ISK"
    },
    {
      "label": "JMD($)",
      "value": "JMD"
    },
    {
      "label": "JOD(د.أ.‏)",
      "value": "JOD"
    },
    {
      "label": "JPY(￥)",
      "value": "JPY"
    },
    {
      "label": "KES(Ksh)",
      "value": "KES"
    },
    {
      "label": "KHR(៛)",
      "value": "KHR"
    },
    {
      "label": "KMF(FC)",
      "value": "KMF"
    },
    {
      "label": "KRW(₩)",
      "value": "KRW"
    },
    {
      "label": "KWD(د.ك.‏)",
      "value": "KWD"
    },
    {
      "label": "KZT(тңг.)",
      "value": "KZT"
    },
    {
      "label": "LBP(ل.ل.‏)",
      "value": "LBP"
    },
    {
      "label": "LKR(SL Re)",
      "value": "LKR"
    },
    {
      "label": "LTL(Lt)",
      "value": "LTL"
    },
    {
      "label": "LVL(Ls)",
      "value": "LVL"
    },
    {
      "label": "LYD(د.ل.‏)",
      "value": "LYD"
    },
    {
      "label": "MAD(د.م.‏)",
      "value": "MAD"
    },
    {
      "label": "MDL(MDL)",
      "value": "MDL"
    },
    {
      "label": "MGA(MGA)",
      "value": "MGA"
    },
    {
      "label": "MKD(MKD)",
      "value": "MKD"
    },
    {
      "label": "MMK(K)",
      "value": "MMK"
    },
    {
      "label": "MOP(MOP$)",
      "value": "MOP"
    },
    {
      "label": "MUR(MURs)",
      "value": "MUR"
    },
    {
      "label": "MXN($)",
      "value": "MXN"
    },
    {
      "label": "MYR(RM)",
      "value": "MYR"
    },
    {
      "label": "MZN(MTn)",
      "value": "MZN"
    },
    {
      "label": "NAD(N$)",
      "value": "NAD"
    },
    {
      "label": "NGN(₦)",
      "value": "NGN"
    },
    {
      "label": "NIO(C$)",
      "value": "NIO"
    },
    {
      "label": "NOK(kr)",
      "value": "NOK"
    },
    {
      "label": "NPR(नेरू)",
      "value": "NPR"
    },
    {
      "label": "NZD($)",
      "value": "NZD"
    },
    {
      "label": "OMR(ر.ع.‏)",
      "value": "OMR"
    },
    {
      "label": "PAB(B/.)",
      "value": "PAB"
    },
    {
      "label": "PEN(S/.)",
      "value": "PEN"
    },
    {
      "label": "PHP(₱)",
      "value": "PHP"
    },
    {
      "label": "PKR(₨)",
      "value": "PKR"
    },
    {
      "label": "PLN(zł)",
      "value": "PLN"
    },
    {
      "label": "PYG(₲)",
      "value": "PYG"
    },
    {
      "label": "QAR(ر.ق.‏)",
      "value": "QAR"
    },
    {
      "label": "RON(RON)",
      "value": "RON"
    },
    {
      "label": "RSD(дин.)",
      "value": "RSD"
    },
    {
      "label": "RUB(₽.)",
      "value": "RUB"
    },
    {
      "label": "RWF(FR)",
      "value": "RWF"
    },
    {
      "label": "SAR(ر.س.‏)",
      "value": "SAR"
    },
    {
      "label": "SDG(SDG)",
      "value": "SDG"
    },
    {
      "label": "SEK(kr)",
      "value": "SEK"
    },
    {
      "label": "SGD($)",
      "value": "SGD"
    },
    {
      "label": "SOS(Ssh)",
      "value": "SOS"
    },
    {
      "label": "SYP(ل.س.‏)",
      "value": "SYP"
    },
    {
      "label": "THB(฿)",
      "value": "THB"
    },
    {
      "label": "TND(د.ت.‏)",
      "value": "TND"
    },
    {
      "label": "TOP(T$)",
      "value": "TOP"
    },
    {
      "label": "TRY(TL)",
      "value": "TRY"
    },
    {
      "label": "TTD($)",
      "value": "TTD"
    },
    {
      "label": "TWD(NT$)",
      "value": "TWD"
    },
    {
      "label": "TZS(TSh)",
      "value": "TZS"
    },
    {
      "label": "UAH(₴)",
      "value": "UAH"
    },
    {
      "label": "UGX(USh)",
      "value": "UGX"
    },
    {
      "label": "UYU($)",
      "value": "UYU"
    },
    {
      "label": "UZS(UZS)",
      "value": "UZS"
    },
    {
      "label": "VEF(Bs.F.)",
      "value": "VEF"
    },
    {
      "label": "VND(₫)",
      "value": "VND"
    },
    {
      "label": "XAF(FCFA)",
      "value": "XAF"
    },
    {
      "label": "XOF(CFA)",
      "value": "XOF"
    },
    {
      "label": "YER(ر.ي.‏)",
      "value": "YER"
    },
    {
      "label": "ZAR(R)",
      "value": "ZAR"
    },
    {
      "label": "ZMK(ZK)",
      "value": "ZMK"
    },
    {
      "label": "ZWL(ZWL$)",
      "value": "ZWL"
    }
  ];

  removedollarsign(val) {
    // while(val.charAt(0) === '$')
    // {
    //   val = val.substring(1);
    // }
    val = val.replace(/[^\d.-]/g, '');
    return parseFloat(val);
  }

  shortDate(date) {
    var givenDate = date ? new Date(date) : new Date();
    let day = givenDate.getDate();
    let dayText = day < 10 ? '0' + day : day;

    let month = givenDate.getMonth() + 1;
    let monthText = month < 10 ? '0' + month : month;

    let year = givenDate.getFullYear();

    var formattedToDate = dayText + "-" + monthText + "-" + year;
    var shortDate = year + "-" + monthText + "-" + dayText;
    return shortDate;
  }

  shortUIDate(date) {
    var givenDate = date ? new Date(date) : new Date();
    let day = givenDate.getDate();
    let dayText = day < 10 ? '0' + day : day;

    let month = givenDate.getMonth() + 1;
    let monthText = month < 10 ? '0' + month : month;

    let year = givenDate.getFullYear();

    var formattedToDate = dayText + "-" + monthText + "-" + year;
    return formattedToDate;
  }


  getCustomOptions(): TreeviewItem[] {

    const childrenCategory = new TreeviewItem({
      text: "Children",
      value: 1,
      collapsed: false,
      children: [
        { text: "Baby 3-5", value: 11, checked: false },
        { text: "Baby 6-8", value: 12, checked: false },
        { text: "Baby 9-12", value: 13, checked: false }
      ]
    });


    const itCategory = new TreeviewItem({
      text: "IT",
      value: 9,
      children: [
        {
          text: "Programming",
          value: 91,
          children: [
            {
              text: "Frontend",
              value: 911,
              children: [
                { text: "Angular 1", value: 9111, checked: false },
                { text: "Angular 2", value: 9112, checked: false },
                { text: "ReactJS", value: 9113, checked: false, disabled: false }
              ]
            },
            {
              text: "Backend",
              value: 912,
              children: [
                { text: "C#", value: 9121, checked: false },
                { text: "Java", value: 9122, checked: false },
                { text: "Python", value: 9123, checked: false, disabled: true }
              ]
            }
          ]
        },
        {
          text: "Networking",
          value: 92,
          children: [
            { text: "Internet", value: 921, checked: false },
            { text: "Security", value: 922, checked: false }
          ]
        }
      ]
    });
    const teenCategory = new TreeviewItem({
      text: "Teen",
      value: 2,
      collapsed: false,
      disabled: false,
      children: [
        { text: "Adventure", value: 21, checked: false },
        { text: "Science", value: 22, checked: false }
      ]
    });
    const othersCategory = new TreeviewItem({
      text: "Others",
      value: 3,
      checked: false,
      disabled: false
    });

    return [childrenCategory, itCategory, teenCategory, othersCategory];
  }

  stringtoDate(date){
  let newdate = date ? date : null;
  let updatedDate = null;
  if (newdate) {
    let date_split = newdate.split("-");
    updatedDate = date_split[2] + '-' + date_split[1] + '-' + date_split[0];
  }
  return updatedDate;
}

groupByKey(data, key) {
  return data.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
}
