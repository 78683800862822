<html>

<head>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i%7CQuicksand:300,400,500,700"
    rel="stylesheet">


</head>

<body style="height: fit-content !important;
    padding-top: 0rem !important;">
  <header class="navigation-top top-zero" id="header">
    <div class="container">
      <nav class="navbar" id="navbar-example2">
        <a class="navbar-brand scrollto" (click)="onClick('hero')">
          <img class="img-fluid" src="assets/img/logo.svg" style="height:70px; width:auto"> </a>
        <div class="inner-nav">
          <p class="close-menu"></p>

          <ul class="nav nav-pills">
            <a class="mobile-menu-logo scrollto" (click)="onClick('hero')"><img src="assets/img/logo.svg" /> </a>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
            <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('contact')">Contact</a></li>
            <li class="nav-item"><a class="nav-link scrollto" routerLink="/signup">Get Started</a></li>
            <li class="nav-item" ngbDropdown>
              <a type="button" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag"
                style="background:none;">
                <span style="font-size:16px">Events</span>
              </a>
              <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                <a class="dropdown-item" routerLink="/amazonexpo">Amazon Expo Boot Camp</a>
                <a class="dropdown-item" routerLink="/shopeeworkshop">Shopee University workshop</a>
              </div>
            </li>
            <li class="nav-item button-menu"><a class="getstarted scrollto"
                (click)="onClick('LoginFormIconContent')">Login</a></li>
          </ul>
        </div>
        <a class="nav-bar"><i class="bi bi-list">&nbsp;</i></a>
      </nav>
    </div>
  </header>
  <!-- ======= End Header ======= -->







  <!-- ======= Hero Section ======= -->
  <div>
    <section style="padding: 7%;">

      <div class="card1">
        <div class="card-body">
          <div class="row" style="font-size: 90%;padding:1%">
            <div class="col-lg-12 m-1">
            </div>

            <div class="col-lg-12 m-2">
              <h1 style="font-size: 175%;color:#3A519F;font-weight: 600;">Privacy Policy</h1>
            </div>
            <div class="col-lg-12">

              <p class="ql-align-justify">
                This Privacy Policy (“
                <strong>
                  Policy
                </strong>
                ”) applies to the Sell &amp; Chill brand and related services which are provided by Mind Master
                Solutions Pte Ltd (“
                <strong>
                  Mind Master
                </strong>
                ”).
              </p>
              <p class="ql-align-justify">
                <strong>
                  1.&nbsp;Overview and Scope
                </strong>
              </p>
              <p class="ql-align-justify">
                Your personal data privacy is important to us and we are highly committed to respecting and managing
                your Personal Data collected in line with this Policy.
              </p>
              <p class="ql-align-justify">
                This Policy will help you better understand how we collect, use and disclose your Personal Data.
              </p>
              <p class="ql-align-justify">
                "Personal Data" means any data or information, whether true or not, about an individual who can be
                identified either (a) from that data; or (b) from that data and other information to which Mind Master
                is likely to have access to.
              </p>
              <p class="ql-align-justify">
                Personal Data excludes Business Contact Information which means an individual's name, position name or
                title, business telephone number, business address, business electronic mail address or business fax
                number and any other similar information about the individual, not provided by the individual solely for
                his personal purposes.&nbsp;
              </p>
              <p class="ql-align-justify">
                <strong>
                  2.&nbsp;When and what kind of Personal Data do we collect?
                </strong>
              </p>
              <p class="ql-align-justify">
                We may collect, use and disclose the following data about you:
              </p>
              <p class="ql-align-justify">
                1.&nbsp;When you provide Personal Data by filling in online or hardcopy forms when
                applying for our products and services or feedback (i.e. surveys), asking queries, requests and other
                submissions;&nbsp;
              </p>
              <p class="ql-align-justify">
                2.&nbsp;If and when you contact us (i.e. telephone calls, website, emails, mobile
                apps, face to face meetings, etc.), we may keep a record of that correspondence;
              </p>
              <p class="ql-align-justify">
                3.&nbsp;Details of your visits to our website including, but not limited to, cookies,
                location data and other communication data, that you access;
              </p>
              <p class="ql-align-justify">
                4.&nbsp;When you fill up online and/or hardcopy job or internship application forms to
                apply for a job or internship at Mind Master;
              </p>
              <p class="ql-align-justify">
                5.&nbsp;If you make contact with any of our authorized representatives, brokers,
                agents, partners, and other third party service providers and vendors, we may keep a record of that
                correspondence;
              </p>
              <p class="ql-align-justify">
                6.&nbsp;If you enter into any contract with Mind Master or purchase any
                products/services from us;
              </p>
              <p class="ql-align-justify">
                7.&nbsp;If you participate in any exhibition or event organised by us or where we are
                a participant;
              </p>
              <p class="ql-align-justify">
                8.&nbsp;If and when you respond to any of our marketing and publicity emails,
                newsletters and other materials;
              </p>
              <p class="ql-align-justify">
                9.&nbsp;When you register to use our services or update your profile on our services,
                we may collect your Personal Data;
              </p>
              <p class="ql-align-justify">
                10.&nbsp;In using Sell &amp; Chill, when you connect our services to your marketplace accounts on third
                party websites and services, we collect the login and authentication information necessary to maintain
                the connection, as well as any information downloaded from these third party websites and services,
                including but not limited to order and inventory information.
              </p>
              <p class="ql-align-justify">
                
                  11.&nbsp;In using Sell &amp; Chill, when you connect our services to your marketplace accounts on
                  third party websites and services, we collect the Personal Data of your customers purely in our role
                  as a Data Intermediary. Because you decide how the Personal Data of your customers will be used, you
                  need to make sure your customers understand how you collect, use and process their Personal Data
                  through your own privacy policy in accordance with the
                
                Personal Data Protection Act 2012 (“
                <strong>
                  PDPA
                </strong>
                ”)
                
                  . &nbsp;
                
              </p>
              <p class="ql-align-justify">
                <strong>
                  3.&nbsp;For what purposes do we Collect, Use and Disclose Personal Data?
                </strong>
              </p>
              <p class="ql-align-justify">
                We may use the information we collect from you for any of the following purposes:
              </p>
              <p class="ql-align-justify">
                1.&nbsp;To perform or carry out our obligations arising from any contracts entered
                into between you and us;
              </p>
              <p class="ql-align-justify">
                2.&nbsp;To enable our subcontractors, third-party agents, service providers and
                partners, to fulfil obligations/services as stipulated in your contract with us (e.g. logistics, hosting
                services, other digital service providers, etc.);
              </p>
              <p class="ql-align-justify">
                3.&nbsp;To handle products and services requests and enquiries from customers;
              </p>
              <p class="ql-align-justify">
                4.&nbsp;For payment administration purposes;
              </p>
              <p class="ql-align-justify">
                5.&nbsp;To administer and update your records in our databases, including storage of
                our clients’ third party personal data.
              </p>
              <p class="ql-align-justify">
                6.&nbsp;For monitoring and maintaining a copy of your record of previous transactions;
              </p>
              <p class="ql-align-justify">
                7.&nbsp;To send and receive order and inventory information from third party websites
                and services in which you have an account for the purposes of providing our services;
              </p>
              <p class="ql-align-justify">
                8.&nbsp;To facilitate the delivery, maintenance and enhancement of our products and
                services for customers;
              </p>
              <p class="ql-align-justify">
                9.&nbsp;To plan, monitor, and enhance the provision of new products, services,
                projects, and planning of events for customers;
              </p>
              <p class="ql-align-justify">
                10.&nbsp;To improve our customer service through your feedback;
              </p>
              <p class="ql-align-justify">
                11.&nbsp;To facilitate data analysis and business planning purposes using anonymized data;
              </p>
              <p class="ql-align-justify">
                12.&nbsp;To process your enquiries and any and all other ancillary administrative purposes;
              </p>
              <p class="ql-align-justify">
                13.&nbsp;For communications, sales and marketing efforts and leads generation and publicity purposes;
              </p>
              <p class="ql-align-justify">
                14.&nbsp;For vendor and service provider management and communications purposes;
              </p>
              <p class="ql-align-justify">
                15.&nbsp;For legal and litigation matters, if required;
              </p>
              <p class="ql-align-justify">
                16.&nbsp;For government, audit and other regulatory purposes;
              </p>
              <p class="ql-align-justify">
                17.&nbsp;For recruitment and evaluation purposes if you apply for a job with us, i.e. to determine job
                suitability;
              </p>
              <p class="ql-align-justify">
                18.&nbsp;To apply for employee work visas at the Ministry of Manpower;
              </p>
              <p class="ql-align-justify">
                19.&nbsp;For security and access controls, safety surveillance, monitoring, reporting and communication
                purposes, especially in emergency situations;
              </p>
              <p class="ql-align-justify">
                20.&nbsp;For internal approval and reporting and/or accounting purposes; and
              </p>
              <p class="ql-align-justify">
                21.&nbsp;Purposes incidental to each or all of the above. &nbsp;
              </p>
              <p class="ql-align-justify">
                We may also contact you by any means of communication for which you have given us contact details,
                including but not limited to via email, telephone numbers, and post, for the purpose of getting your
                feedback or for providing you with information which we believe could be of interest to you or your
                organisation.
              </p>
              <p class="ql-align-justify">
                
                  By your submission of your Personal Data to us, you consent to the onward disclosure of your Personal
                  Data to our agents or service providers, if any, and the processing of your Personal data by these
                  agents or service providers.
                
              </p>
              <p class="ql-align-justify">
                We only collect, process, use or disclose such Personal Data, in accordance with this Policy. If you are
                acting as an intermediary, or otherwise on behalf of a third party, or supply us with information
                regarding a third party, you undertake that you are an authorised representative or agent of such third
                party and that you have obtained consent from such third party to our collection, processing, use and
                disclosure of their Personal Data. As we are collecting the third party’s data from you, you undertake
                to make the third party aware of all matters listed in this Policy by referring them to our website.
              </p>
              <p class="ql-align-justify">
                <strong>
                  4.&nbsp;Consent for the collection and use of your Personal Data
                </strong>
              </p>
              <p class="ql-align-justify">
                You consent to the collection, use and disclosure of your Personal Data for the above mentioned purposes
                and agree to be bound by the obligations it imposes on you, when you accept this Privacy Policy. You
                accept this Privacy Policy when you continue to browse on our website, use our platforms including but
                not limited to Sell &amp; Chill, or continue to engage with us.
              </p>
              <p class="ql-align-justify">
                In this regard, please note that it is on your part to ensure that all personal data submitted to us is
                complete, accurate, true and correct at the time of submission. Failure on your part to do so may result
                in our inability to provide you with products and services you have requested.
              </p>
              <p class="ql-align-justify">
                Please note that if you do not consent to any of the above business purposes, we may also be unable to
                meet the purposes for which the information was collected.
              </p>
              <p class="ql-align-justify">
                <strong>
                  5.&nbsp;Do we disclose Personal Data to third parties?
                </strong>
              </p>
              <p class="ql-align-justify">
                We may disclose your Personal Data to any member of our group, which means our subsidiaries, our
                ultimate holding company and its subsidiaries, as defined in the Companies Act (Cap. 50).
              </p>
              <p class="ql-align-justify">
                On occasion, we may use third party agents and service providers to assist us in the use of your
                Personal Data. You consent to such use of your Personal Data by continuing to browse on our website,
                using our platforms including but not limited to Sell &amp; Chill, or continuing to engage with us.
              </p>
              <p class="ql-align-justify">
                We will not transfer Personal Data within or outside Singapore unless it is ensured that the Personal
                Data will be accorded a level of protection which is comparable to the protection under the PDPA.
              </p>
              <p class="ql-align-justify">
                <strong>
                  6.&nbsp;For how long do we retain your Personal Data?
                </strong>
              </p>
              <p class="ql-align-justify">
                  Personal Data
              </p>
              <p class="ql-align-justify">
                  We will retain Personal Data for no longer than 30 days after order delivery and only for the purpose
                  of, and as long as is necessary to (i) fulfill orders, (ii) calculate and remit taxes, (iii) produce
                  tax invoices and other legally required documents, and (iv) meet legal requirements, including tax or
                  regulatory requirements.
              </p>
              <p class="ql-align-justify">
                
                  We may retain data for over 30 days after order delivery only if required by law and only for the
                  purposes of complying with that law.
                
              </p>
              <p class="ql-align-justify">
                
                  If we are required by law to retain archival copies of the Personal Data for tax or other regulatory
                  purposes, the Personal Data will be stored as a "cold" or offline encrypted backup (not available for
                  immediate or interactive use).
                
              </p>
              <p class="ql-align-justify">
                
                  Order Data (not containing Personal Data)
                
              </p>
              <p class="ql-align-justify">
                
                  We will retain order data (not containing Personal Data) for the past 12 months online on Sell &amp;
                  Chill’s platform. Any older order data (not containing Personal Data) up to 7 years will be available
                  via offline reports.
                
              </p>
              <p class="ql-align-justify">
                
                  In the event you need to have access to such older order data (not containing Personal Data) prior to
                  twelve (12) months of current usage, a report can be exported from the Sell &amp; Chill platform that
                  will generate historical order data (not containing Personal Data). The report shall be limited to the
                  following details:
                
              </p>
              <p class="ql-align-justify">
                
                  •&nbsp;Inventory
                
              </p>
              <p class="ql-align-justify">
                
                  •&nbsp;Catalogues
                
              </p>
              <p class="ql-align-justify">
                
                  •&nbsp;Product Information
                
              </p>
              <p class="ql-align-justify">
                
                  •&nbsp;Order Information (Order Documents and
                  Picking Lists, with no Personal Data)
                
              </p>
              <p class="ql-align-justify">
                <strong style="color: black;">
                  Data Destruction
                </strong>
              </p>
              <p class="ql-align-justify">
                
                  Data destruction ensures that we manage the data we control and process in an efficient and
                  responsible manner.
                
              </p>
              <p class="ql-align-justify">
                
                  In general, we will automatically delete your Sell &amp; Chill account information within 24 hours
                  after you no longer have any active subscription with us.
                
              </p>
              <p class="ql-align-justify">
                
                  When the retention period for all other data as outlined above expires, we will actively destroy the
                  data covered by this policy. Secure deletion shall occur in accordance with industry-standard
                  sanitization processes.
                
              </p>
              <p class="ql-align-justify">
                
                  If an individual believes that there exists a legitimate business reason why certain data should not
                  be destroyed at the end of a retention period, he or she should identify this data to us by writing to
                  our Data Protection Officer and provide information as to why the data should not be destroyed.
                
              </p>
              <p class="ql-align-justify">
                <strong>
                  7.&nbsp;Do we use cookies to collect and use Personal Data?
                </strong>
              </p>
              <p class="ql-align-justify">
                When you visit any of our websites, we may collect or analyse anonymized information from which
                individual information will not be identified. The information collected may include the number of users
                and the amount of time they stay on our website, which countries they are from, and what mode of device
                they are currently using to view our website, as well as domain information that helps us to learn our
                client’s profile, the frequency of viewing. We use this information to improve our website’s content and
                navigation.
              </p>
              <p class="ql-align-justify">
                Some parts of this website may use cookies, which enable us to track usage patterns, enforce security,
                and offer you a customized content. A cookie is a small text file that our server places on your
                computer hard drive as a unique identifier.
              </p>
              <p class="ql-align-justify">
                Note that our cookies do not have an expiration date and do not collect personally identifiable
                information.
              </p>
              <p class="ql-align-justify">
                You may disable the use of cookies by selecting the appropriate settings on your browser. This may
                however result in you being unable to experience the full functionality of the websites.
              </p>
              <p class="ql-align-justify">
                <strong>
                  8.&nbsp;How do we protect your Personal Data?
                </strong>
              </p>
              <p class="ql-align-justify">
                The Personal Data that we hold about you is stored on servers hosted by Mind Master at our datacentre
                and office in Singapore. We implement a variety of security measures to maintain the safety of your
                submitted information. All electronic storage and transmission of personal data are secured and stored
                on managed servers with controlled access and appropriate security technologies.
              </p>
              <p class="ql-align-justify">
                Although every reasonable effort has been made to ensure that all personal data will be so protected, we
                cannot be responsible for any unauthorised use or misuse of such information and from risks which are
                inherent in all internet communications.
              </p>
              <p class="ql-align-justify">
                Your Personal Data will only be disclosed for the express purpose of delivering the product or service
                requested and shall not be sold or disclosed to any other company for any other reason whatsoever
                without your consent.
              </p>
              <p class="ql-align-justify">
                <strong>
                  9.&nbsp;Links to third party websites
                </strong>
              </p>
              <p class="ql-align-justify">
                Our websites and services may contain links to other external websites, such as our business partners
                and other third parties. For example, when you use Sell &amp; Chill, we provide you with the opportunity
                to connect with other third party services. When you connect with these third party services, they can
                see your order/ inventory information as well as other related information. your use of such third party
                services is not governed by this Policy. Mind Master does not control the services of those third
                parties and how they use your information and Personal Data.
              </p>
              <p class="ql-align-justify">
                We are not responsible for the privacy policies and practices of these websites taken care of by third
                parties. We strongly encourage you to review the privacy policy of each website/ third party service
                that you visit before using their services. Some of these third party websites/ services may have our
                logo or trademark acknowledged on their website. However, these websites are not operated and maintained
                by us. Please contact the owner of the respective websites should you have any questions on their
                privacy policies.
              </p>
              <p class="ql-align-justify">
                <strong>
                  10.&nbsp;Access and Correction of Personal Data
                </strong>
              </p>
              <p class="ql-align-justify">
                Please contact us via
                <a href="mailto:dpo@mindmastersg.com" rel="noopener noreferrer" target="_blank">
                  dpo@mindmastersg.com
                </a>
                should you wish to have access to or seek to update, correct or withdraw the consent to collect and use
                your Personal Data. &nbsp;Your email should identify yourself and state which Personal Data and
                information about its use and/or disclosure is requested.
              </p>
              <p class="ql-align-justify">
                We will respond to your request as soon as reasonably possible. Should we not be able to respond to your
                request within thirty (30) days after receiving your request, we will inform you in writing within
                thirty (30) days of the time by which we will be able to respond to your request. If we are unable to
                provide you with any personal data or to make a correction requested by you, we shall generally inform
                you of the reasons why we are unable to do so (except where we are not required to do so under the
                PDPA).
              </p>
              <p class="ql-align-justify">
                <strong>
                  11.&nbsp;Further Information
                </strong>
              </p>
              <p class="ql-align-justify">
                If you are concerned about the handling of your Personal Data, wish to be removed from our email
                subscription or contact lists, or if you have any complaints or queries related to your Personal Data or
                our Privacy Policy, please contact our Data Protection Officer (“DPO”) at
                <a href="mailto:dpo@mindmastersg.com" rel="noopener noreferrer" target="_blank">
                  dpo@mindmastersg.com
                </a>
                <strong>
                  .
                </strong>
                Please clearly identify yourself and the purpose of your query.
              </p>
              <p class="ql-align-justify">
                We reserve the right to change this Policy with or without notice from time to time.
              </p>
              <p class="ql-align-justify">
                &nbsp;
              </p>
              <p class="ql-align-justify">
                Last Updated: 3 August 2023
              </p>
              <p>
              </p>
            </div>
          </div>
        </div>
      </div>


    </section>





    <!-- ======= Footer ======= -->

    <footer class="footer" id="footer" style="padding: 0px;">
      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12  col-sm-12   footer-info">
              <a class="logo d-flex align-items-center scrollto" href="#hero">
                <img alt="" style="height:70px; width:auto;margin-left: 26px;" src="assets/img/logoWhite.png"
                  class="md mx-auto d-block" /> </a>



              <div class="social-links mt-3"></div>
            </div>

            <div class="col-lg-4 col-12  col-sm-12   footer-links">
              <h4>Useful Links</h4>

              <ul class="footer-menu">
                <li> <a class="nav-link scrollto" (click)="onClick('hero')">Home</a></li>
                <li><a href="https://mindmaster.com.sg/" target="_blank">About us</a></li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('key-feature')">Features</a></li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('testimonials')">Testimonials</a>
                </li>
                <li class="nav-item"><a class="nav-link scrollto" (click)="onClick('integration')">Integration</a></li>
                <li class="nav-item"><a class="nav-link scrollto" routerLink="/privacy-policy">Privacy Policy</a></li>
                <li class="nav-item"><a class="nav-link scrollto" routerLink="/terms-of-service">Terms of Service</a>
                </li>
                <li class="nav-item"><a class="nav-link" (click)="onClick('LoginFormIconContent')">Login</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12   footer-contact ">
              <h4>Contact Us</h4>

              <p>Foo Wah Industrial Building<br /> 45 Jln Pemimpin,<br /> Singapore 577197<br />
                <br />
                <span class="Green">+65 6250 5623</span><br /> support@sellnchill.com
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">&copy; Copyright <strong><span>Sell & Chill</span></strong>. All Rights Reserved</div>

      </div>
    </footer>
  </div>
  <!-- ======= End Footer ======= -->


</body>

</html>