import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GlobalConstants } from '../global-constant';
@Injectable()
export class AmazonOrderService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient) { }
  getOrders(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/GetAmazonOrdersList`, reqObj);
  }
  getOrdersSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/SyncAmazonOrders`, reqObj);
  }
  getShippingProviders() {
    return this.http.get(`${this.apiBaseURL}/Lazada/GetShippingProviderTypes`);
  }
  getPrintAmazonLabels(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/PrintShippingLabel`, reqObj);
  }
  getPrintLazadaDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/PrintLazadaDocs`, reqObj);
  }
  getDBSLazadaDocs(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/GetLazDBSShippingLabels`, reqObj);
  }
  getLazadaCarrier(reqObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/PrintCarrierManifest`, reqObj);
  }
  getSlotsInfo(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/GetTimeSlots`, reqObj);
  }
  markAsPrinted(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/MarkAsPrinted`, reqObj);
  }
  markDBSStatus(type, reqObj) {
    if (type === 'delivered') {
      return this.http.post(`${this.apiBaseURL}/Lazada/MarkDBSOrderAsDelivered`, reqObj);
    } else {
      return this.http.post(`${this.apiBaseURL}/Lazada/MarkDBSOrderAsFailed`, reqObj);
    }
  }
  getOrderItems(reqObj) {
    return this.http.get(`${this.apiBaseURL}/Amazon/GetAmazonOrderItems?CompanyID=${reqObj.CompanyID}&SellerID=${reqObj.SellerID}&Platform=${reqObj.Platform}&OrderID=${reqObj.OrderID}`);
  }
  getMassOrderItems(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/GetLazadaMassOrderItems`, reqObj);
  }
  cancelOrder(reqObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/MarkAsCancel`, reqObj);
  }
  getLazadaFailureReasons() {
    return this.http.get(`${this.apiBaseURL}/Lazada/GetLazadaFailureReasons`);
  }
  readyToShip(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/AZEasyShip`, reqObj);
  }
  checkout(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/MarkAsCheckout`, reqObj);
  }
  getOrderDetails(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/GetInvoiceDetail`,reqObj);
  }

  returnOrder(reqObj){
    return this.http.post(`${this.apiBaseURL}/OfflineSales/UpdateOnlineReturnDetails`, reqObj);
  }

  getOrderLogistics(){
    return this.http.get(`${this.apiBaseURL}/Common/GetLazadaCarriers`);
  }


  //NEW ORDERS SYNC WITH UPDATE, TRANSACTIONS
  getOrdersSyncUpdate(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/SyncAmazonOrderUpdate`, reqObj);
  }

  getOrdersSyncTransactions(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/SyncLazadaTransactions`, reqObj);
  }

   //UPDATED SHIPPING LABELS
   PrintShippingLabel(reqObj) {
    if(reqObj?.IsItemInfo == false){
    return this.http.post(`${this.apiBaseURL}/Lazada/PrintShippingLabel`, reqObj);
    }else{
    return this.http.post(`${this.apiBaseURL}/Lazada/GetShippingLabelHTML`, reqObj);
    }
  }
  //CUSTOMER COPY - START - 15-04-2024
  AmazonCustomerCopy(obj){
    return this.http.post(`${this.apiBaseURL}/Amazon/AmazonCustomerCopy`, obj)
  }
  updateCustomInvoice(obj) {
    return this.http.post(`${this.apiBaseURL}/Common/UpdateCustomInvoice`, obj);
  }
}
