import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(private http: HttpClient) { }
  getCatalogList(obj){ 
    return this.http.post(`${this.apiBaseURL}/Catalogue/GetCatalogueList`, obj);
  }
  getCatalogDetails(companyID, catalogId){
    return this.http.get(`${this.apiBaseURL}/Catalogue/GetCatalogue?CompanyID=${companyID}&CatalogID=${catalogId}`);
  }
  createOrUpdateCatalog(formData, type){
    //const headers: Record<string, string> = {'Content-Type': 'multipart/form-data', 'Accept':'application/json'};
    return this.http.post(`${this.apiBaseURL}/Catalogue/${type}Catalogue
    `,formData);
  }
  uploadImage(formData){
    return this.http.post(`${this.apiBaseURL}/Catalogue/UploadImages`,formData);
  }
  getSellersByPlatform(requestObj){
    return this.http.post(`${this.apiBaseURL}/Catalogue/GetSellersByCatalogue`,requestObj);
  }
  getLazadaBrands(requestObj){
    return this.http.get(`${this.apiBaseURL}/Lazada/GetLazadaBrandsList`);
    //return this.http.post(`${this.apiBaseURL}/Lazada/GetLazadaBrandsList`, requestObj);
  }
  getShopeeBrands(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeeBrands`, requestObj);
  }
  createLazada(requestObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/CreateProduct`,requestObj);
  }
  getShopeeLogistics(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeeLogistics`,requestObj);
  }
  getShopeeDaysToLimit(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeeDayToShipLimit`,requestObj);
  }
  createShopee(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/CreateProduct`,requestObj);
  }
  getQoo10Brands(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetBrands`,requestObj);
  }
  getQoo10Manufacturer(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetManufacturer`,requestObj);
  }
  getQoo10ShippingOptions(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetDeliverCompany`,requestObj);
  }
  createQoo10(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/CreateProduct`,requestObj);
  }
  getLazadaCategory(requestObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/GetCategoryTree`,requestObj);
  }
  getQoo10Category(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetCategory`,requestObj);
  }
  getShopeeCategory(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeePrimaryCategory`,requestObj);
  }
  getShopeeSubCategory(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeeSubCategory`,requestObj);
  }
  getLazadaAttributes(requestObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/GetAttributes`,requestObj);
  }
  getShopeeAttributes(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetShopeeAttributes`,requestObj);
  }
  getLazadaProduct(requestObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/GetProductDeatils`,requestObj);
  }
  getLazadaProductVariations(requestObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/GetProductsDetailsById`,requestObj);
  }
  editOrCloneLazada(requestObj, type){
    return this.http.post(`${this.apiBaseURL}/Lazada/${type}Product`,requestObj);
  }
  getQoo10Product(requestObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetProductDeatils`,requestObj);
  }
  editOrCloneQoo10(requestObj, type){
    return this.http.post(`${this.apiBaseURL}/Qoo10/${type}Product`,requestObj);
  }
  getShopeeProduct(requestObj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetProductDeatils`,requestObj);
  }
  getShopifyProduct(requestObj){
    return this.http.post(`${this.apiBaseURL}/Shopify/GetProductDetailById`,requestObj);
  }
  editOrCloneShopee(requestObj, type){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/${type}Product`,requestObj);
  }
  createshopify(requestObj, url){
    return this.http.post(`${this.apiBaseURL}/Shopify/${url}`,requestObj);
  }

  createWooCommerce(requestObj, url){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/${url}`,requestObj);
  }
  editOrCloneWoo(requestObj, url){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/${url}`,requestObj);
  }

  getwooCategories(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooCategoryList`,requestObj);
  }
  getwooAttributes(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooAttributeList`,requestObj);
  }
  getwooAttributeTerms(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooAttributeTerms`,requestObj);
  }

  getwooProduct(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooProductDetailLive`,requestObj);
  }

  createOffline(requestObj){
    return this.http.post(`${this.apiBaseURL}/OfflineSales/CreateOfflineProduct`,requestObj);
  }
  editOrCloneOffline(requestObj, type){
    if(type === 'Edit') {
      return this.http.post(`${this.apiBaseURL}/OfflineSales/UpdateOfflineProduct`,requestObj);
    }else {
      return this.http.post(`${this.apiBaseURL}/OfflineSales/CreateOfflineProduct`,requestObj);
    }
  }


  //WOO ATTRIBUTES
  editWooAttribute(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooAttribute`,requestObj);
  }

  editWooAttributeTerms(requestObj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooAttributeTerm`,requestObj);
  }
}
