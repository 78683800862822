import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from './signup.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  blockLoader: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public signupService: SignupService,

) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $(window).scroll(function () {
        var sticky = $('#header'),
          scroll = $(window).scrollTop();

        if (scroll >= 20) {
          sticky.addClass('fixed');
          $('.back-to-top').addClass('active');
        } else {
          sticky.removeClass('fixed');
          $('.back-to-top').removeClass('active');
        }
      });
      $("a.nav-bar").click(function () {
        $(".inner-nav").addClass('active-menu');
      });

      $("p.close-menu").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });

      $("ul.nav.nav-pills a").click(function () {
        $(".inner-nav").removeClass('active-menu');
      });
      $(window).resize(function () {
        $("#log").append("<div>Handler for .resize() called.</div>");
      });
      const links: any = document.querySelectorAll(".scrollto");
      for (const link of links) {
        link.addEventListener("click", this.clickHandler);
      }

    });
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: [''],
      number: [''],
      email: ['', Validators.required],
      agreeTerms: [false, Validators.requiredTrue]
    });
  }

    // convenience getter for easy access to form fields
    get f() {
      return this.signupForm.controls;
    }
  

  onClick(id: string): void {
   this.router.navigate(['/']);
  }

  trySignup() {
    this.submitted = true;
    if(this.signupForm.value.agreeTerms==false){
      Swal.fire({
        text: 'Must need to Agree Our Terms & Policy',
       // title: 'Accept Terms',
        icon: 'error',
      })

    }
    // stop here if form is invalid
    if (this.signupForm.invalid || this.signupForm.value.agreeTerms==false) {
      return;
    }
    this.startBlockUI();
    
    this.signupService.doSignup(this.signupForm.value).subscribe(
      (res:any) => {
        this.stopBlockUI();
       if(res.result==1){
        Swal.fire({
          title: 'Success',
          text: 'Great! We shall contact you Soon',
          icon: 'success',
        }).then((willDelete) => {
          if (willDelete) {
            this.onClick('');
          }else{
            this.onClick('');
          }
        });
       }

       if(res.result==0){
        Swal.fire({
          title: 'Error',
          text: 'Some thing went Wrong!, Please try again',
          icon: 'error',
        })
       }

      },
      err => {
        this.stopBlockUI();
        this.submitted = false;
        this.loading = false;
        //this.alertService.error(err.error.error);

        let errorDetails = err.error;
        Swal.fire({
          title: 'Signup failed!, Please try again',
          text: errorDetails,
          icon: 'error',
        })

      }
    );
  }

  public stopBlockUI(): void {
    this.blockLoader = false;
  }

  public startBlockUI(): void {
    this.blockLoader = true;
  }


}
