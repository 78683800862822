import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public apiBaseURL = GlobalConstants.apiURL;
  constructor(private http: HttpClient) { }
  getProductsList(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/GetLazadaProducts`, reqObj);
  }
  getLazadaProductsCount(reqObj){
    return this.http.post(`${this.apiBaseURL}/Lazada/GetLazadaProductsCount`, reqObj);
  }
  getSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Lazada/ProductSync`, reqObj);
  }
  getQoo10Sync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/ProductSync`, reqObj);
  }
  getQoo10ProductsCount(reqObj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetQoo10ProductsCount`, reqObj);
  }
  getShopeeSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/ProductSync`, reqObj);
  }
  getShopeeProductsCount(reqObj){
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeProductsCount`, reqObj);
  }
  getShopifySync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Shopify/ProductSync`, reqObj);
  }
  getQoo10Products(obj) {
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetQoo10Products`, obj);
  }
  getShopifyProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/Shopify/GetShopifyProductsV2`, obj);
  }
  getShopeeProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/Shopee/GetShopeeV2Products`, obj);
  }
  getQoo10SKUTemplate(obj) {
    let params = new HttpParams();
    // params = params.append("companyID", obj.companyId);
    // params = params.append("sellerID", obj.sellerId)
    return this.http.post(`${this.apiBaseURL}/Qoo10/GetSKUTemplate`, obj);
  }
  getShopeeSKUTemplate(obj) {
    let params = new HttpParams();
    // params = params.append("companyID", obj.companyId);
    // params = params.append("sellerID", obj.sellerId)
    return this.http.post(`${this.apiBaseURL}/Shopee/GetSKUTemplate`, obj);
  }
  updateShopeeSKU(id, obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    return this.http.post(`${this.apiBaseURL}/Shopee/UpdateSKUs`, obj, { params: params });
  }
  updateQoo10SKU(id, obj) {
    let params = new HttpParams();
    params = params.append("CompanyID", id);
    return this.http.post(`${this.apiBaseURL}/Qoo10/UpdateSKUs`, obj, { params: params });
  }
  removeLazadaProduct(obj){
    return this.http.post(`${this.apiBaseURL}/Lazada/DeleteProductByID`, obj);
  }
  removeQoo10Product(obj){
    return this.http.post(`${this.apiBaseURL}/Qoo10/DeleteProduct`, obj);
  }
  removeShopeeProduct(obj){
    return this.http.post(`${this.apiBaseURL}/Shopee/DeleteProduct`, obj);
  }
  removeShopifyProduct(obj){
    return this.http.post(`${this.apiBaseURL}/Shopify/DeleteProduct`, obj);
  }
   

  getWooSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/SyncWooProduct`, reqObj);
  }
  getWooProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/WooCommerce/WooProductList`, obj);
  }

  removeWooProduct(obj){
    return this.http.post(`${this.apiBaseURL}/WooCommerce/DeleteProduct`, obj);
  }

  getVendSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Vend/SyncVendProduct`, reqObj);
  }
  getVendProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/Vend/GetVendProducts`, obj);
  }

  getPromotions(obj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetDiscountsList`, obj);
  }
  addPromotion(obj, isUpdate?){
    if (isUpdate){
      return this.http.post(`${this.apiBaseURL}/ShopeeV2/UpdateDiscount`, obj);
    }else {
      return this.http.post(`${this.apiBaseURL}/ShopeeV2/AddDiscount`, obj);
    }
  }
  addProductToPromotion(obj, isUpdate?){
    if (isUpdate){
      return this.http.post(`${this.apiBaseURL}/ShopeeV2/UpdateDiscountItem`, obj);
    }else {
      return this.http.post(`${this.apiBaseURL}/ShopeeV2/AddDiscountItem`, obj);
    }
  }
  getDiscountItemDetails(obj){
    return this.http.post(`${this.apiBaseURL}/ShopeeV2/GetDiscountsItemList`, obj);
  }

  updateSpecialPriceForLazada(obj){
    return this.http.post(`${this.apiBaseURL}/Lazada/UpdateProductPrice`, obj);
  }

  getAmazonProductsCount(reqObj){
    return this.http.post(`${this.apiBaseURL}/Amazon/GetAmazonProductsCount`, reqObj);
  }

  getAmazonSync(reqObj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/ProductSync`, reqObj);
  }

  getAmazonProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/Amazon/GetAmazonProducts`, obj);
  }
  getTiktokProducts(obj) {
    return this.http.post(`${this.apiBaseURL}/Tiktok/GetTiktokProducts`, obj);
  }

  getTiktokProductsCount(reqObj){
    return this.http.post(`${this.apiBaseURL}/Tiktok/GetTiktokProductsCount`, reqObj);
  }

  getTiktokSync(reqObj){
    return this.http.post(`${this.apiBaseURL}/Tiktok/ProductSync`, reqObj);
  }
  removeAmazonProduct(obj){
    return this.http.post(`${this.apiBaseURL}/Amazon/DeleteProductByID`, obj);
  }
}
